import { faCopy, faRefresh, faTimer } from '@fortawesome/pro-solid-svg-icons'
import { useAPIKeys } from 'features/settings'
import moment from 'moment'
import { Button, IconButton, TextInput, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { cn, enqueueNotification } from 'Utils/Helpers'
import { addSpaces } from 'Utils/PureHelperFuctions'
import { FullScreenAlertModal, LabelContent } from 'V2Components'
import { ExtendExpiryModal } from '../ExtendExpiryModal'

const ApiKeys = () => {
  const [showRegenApiSecretModal, setShowRegenApiSecretModal] = useState(false)
  const [deleteConfirmText, setDeleteConfirmText] = useState('')
  const [showExtendExpiryModal, setShowExtendExpiryModal] = useState(false)

  const {
    apiKeys,
    regenerateApiSecret,
    status,
    tenantApiKeyObj,
    updateApiKeysObj,
    isApiKeyExpired
  } = useAPIKeys()

  const handleCopyKey = (keyName) => {
    const k = apiKeys[keyName] || ''
    navigator.clipboard.writeText(k)
    enqueueNotification(`${addSpaces(keyName)} Copied to clipboard!`, 'info')
  }

  const handleRegenClick = async () => {
    try {
      await regenerateApiSecret()
      enqueueNotification('A new API Secret has been successfully generated.', 'info')
    } catch (error) {
      enqueueNotification('Error regenerating API Secret.', 'error')
    } finally {
      setShowRegenApiSecretModal(false)
      setDeleteConfirmText('')
    }
  }

  const isLoaded = status === 'IDLE'

  return (
    <div>
      <LabelContent
        content={
          isLoaded ? (
            <div className='flex justify-between'>
              <Typography variant='body-regular'>{apiKeys.ApiKey}</Typography>
              <IconButton onClick={() => handleCopyKey('ApiKey')} icon={faCopy} variant='clear' />
            </div>
          ) : (
            `${status}..`
          )
        }
        title='API Key'
      />
      <LabelContent
        content={
          isLoaded ? (
            <div className='flex justify-between'>
              <Typography variant='body-regular'>
                *********************************************************************************
              </Typography>
              <IconButton
                onClick={() => handleCopyKey('ApiSecret')}
                icon={faCopy}
                variant='clear'
              />
            </div>
          ) : (
            `${status}..`
          )
        }
        title='API Secret'
      />
      <LabelContent
        content={
          isLoaded ? (
            <div className='flex justify-between items-center'>
              {isApiKeyExpired ? (
                <div>
                  <Typography
                    className={cn('flex items-center gap-2', {
                      '!text-[#e02a47]': isApiKeyExpired
                    })}
                    variant='body-regular'
                  >
                    {moment(apiKeys.NotAfter).format('MMMM Do YYYY, h:mm A')}
                  </Typography>
                </div>
              ) : (
                <Typography className={'flex items-center gap-2'} variant='body-regular'>
                  {moment(apiKeys.NotAfter).format('MMMM Do YYYY, h:mm A')}
                  {moment(apiKeys.NotAfter).diff(new Date(), 'days') <= 90 && (
                    <span className='p-[2px] rounded-full px-3 bg-yellow-400 text-white'>
                      Expiring in {moment(apiKeys.NotAfter).diff(new Date(), 'days')} Days
                    </span>
                  )}
                </Typography>
              )}
              <Button
                onClick={() => setShowExtendExpiryModal(true)}
                icon={faTimer}
                className='ml-6'
                variant={isApiKeyExpired ? 'primary' : 'gray'}
              >
                Extend Expiry
              </Button>
            </div>
          ) : (
            `${status}..`
          )
        }
        title='Expires'
      />
      <LabelContent content={isLoaded ? apiKeys.CntrlEP : `${status}..`} title='Endpoint' />

      <div className='mt-4 flex gap-2'>
        <Button onClick={() => setShowRegenApiSecretModal(true)} variant='gray' icon={faRefresh}>
          Regenerate API Secret
        </Button>
        {/*  //!TODO Removed temporary */}
        {/* <Button onClick={() => setShowRDPConfigModal(true)} icon={faGear} variant='gray'>
          Configure RDP Storage
        </Button> */}
      </div>

      <FullScreenAlertModal
        actionButtonText='Regen'
        actionButtonVariant='danger'
        alertMessage={`Regenerate API Secret`}
        loadingMessage={`Regenerating API Secret`}
        onActionClick={handleRegenClick}
        onCancel={() => {
          setShowRegenApiSecretModal(false)
          setDeleteConfirmText('')
        }}
        disableAction={deleteConfirmText !== 'regen'}
        Content={
          <div>
            <div className='mt-4'>
              <Typography className='my-2' variant='buttonLabel-regular'>
                The current API Secret will be permanently
                <span className='text-red-500 mx-1'>deleted</span>
                and a new one will be created.
              </Typography>
              <TextInput
                value={deleteConfirmText}
                onChange={(e) => setDeleteConfirmText(e.target.value)}
                sx={{ width: '100%' }}
                label={`Enter 'regen' to confirm`}
              />
            </div>
          </div>
        }
        showModal={showRegenApiSecretModal}
      />
      {showExtendExpiryModal && (
        <ExtendExpiryModal
          onCancel={() => setShowExtendExpiryModal(false)}
          onSuccess={(data) => {
            setShowExtendExpiryModal(false)
            updateApiKeysObj(data)
          }}
          tenantApiKeyObj={tenantApiKeyObj}
        />
      )}
    </div>
  )
}

export { ApiKeys }
