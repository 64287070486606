import _ from 'lodash'

/**
 * Returns the source of how the user got created/added
 * @param {*} user
 * @returns {'SCIM' | 'Procyon'}
 */
export const getUserSource = (user) => (user.Status.Source === 'SCIM' ? 'SCIM' : 'Procyon')

/**
 * Returns true, if the user is active else false.
 * @param {*} user
 * @returns {'Active'|'InActive'}
 */
export const getUserStatus = (user) => (!!user.Spec.Active ? 'Active' : 'InActive')

/**
 * Check if a user is admin or not
 * @param {*} user The user to check for
 * @param {*} adminGroupRef The Ref Object of admin group
 * @returns If the user is admin i.e. belongs to admin group then returns `true`, else returns `false`
 */
export const isAdminUser = (user, adminGroupRef) => {
  const groupsRefs = user.Spec.Groups?.ObjectRef || []
  if (_.find(groupsRefs, adminGroupRef)) return true
  return false
}

/**
 * Get the refs of the groups in which the user is.
 * @param {*} user
 * @returns Array of ref objects of all groups in which the `user` is member of.
 */
export const getUserGroupsRefs = (user) => user.Spec.Groups?.ObjectRef || []

/**
 * Get all the devices owned by the user
 * @param {*} devices All the devices
 * @param {*} user The user to filter the devices on
 * @returns
 */
export const getUserOwnedDevices = (devices = [], user) => {
  return _.filter(devices, { UserName: user?.ObjectMeta.Name })
}

/**
 * Get the display name for user.
 * @param {*} user
 * @param {boolean} useMeta If true, return ObjectMeta.Name when no naming fields are present
 * @returns {string} Display name of the user.
 */
export const getUserDisplayName = (user, useMeta = true) => {
  if (!user) return ''
  const { FirstName, LastName, FullName } = user.Spec
  if (FullName) return FullName
  else if (FirstName && LastName) return `${FirstName.trim()} ${LastName.trim()}`
  else if (useMeta) return user.ObjectMeta.Name
  return ''
}

/**
 * Get all the groups in which the user is present.
 * @param {object} user 
 * @param {any[]} groups 
 * @returns Groups in which the user is in
 */
export const getUserGroups = (user, groups = []) => {
  const userID = user.ObjectMeta.ID
  return groups.filter((g) => _.find(g.Spec.Users?.ObjectRef || [], { RefID: userID }))
}
