import { createAccount, isValidAccountCredentials, updateAccount } from 'features/clouds'

export function useGCPForm() {
  const checkGCPAccountCredentials = async ({ jsonCredentials }) => {
    const payload = {
      ObjectMeta: {
        Name: 'gcp_check'
      },
      Spec: {
        GcpSpec: {
          GcpJsonCreds: jsonCredentials,
          CloudIDType: 'intermediate'
        },
        Discovery: true,
        AccountType: 'GCP'
      }
    }
    return await isValidAccountCredentials(payload)
  }

  const handleGCPAccountCreate = async ({ jsonCredentials, name, description, org }) => {
    const payload = {
      ObjectMeta: {
        Kind: 'Account',
        Name: name.trim(),
        Namespace: org
      },
      Spec: {
        Description: description.trim(),
        GcpSpec: {
          GcpJsonCreds: jsonCredentials,
          CloudIDType: 'intermediate'
        },
        Discovery: true,
        Type: 'GCP'
      }
    }
    return await createAccount(payload)
  }

  const handleGCPAccountUpdate = async ({ jsonCredentials, description, account }) => {
    const acc = structuredClone(account)

    acc.Spec.Description = description.trim()
    acc.Spec.GcpSpec.GcpJsonCreds = jsonCredentials

    return await updateAccount(acc)
  }

  return { checkGCPAccountCredentials, handleGCPAccountCreate, handleGCPAccountUpdate }
}
