import React, { useEffect, useMemo, useState } from 'react'
import { FullScreenModal, LoadingFeedback, SearchInput } from 'V2Components'
import { createDataSelectorHook } from 'infra/redux'
import { Button, IamRolesCard, Label, TextInput, Typography } from 'procyon-ui'
import { useAppDetailsContext } from 'features/snowflake'
import { createRef } from 'features/resources'
import { useUser } from 'Core/Hooks/useUser'
import { reduxApiClient } from 'infra'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'
import _ from 'lodash'
import { getFriendlyName } from 'Utils/FriendlyName'
const useSlices = createDataSelectorHook(['snowFlakeAccounts', 'snowFlakeRoles'])

const CreateRoleModal = ({ showCreateRoleModal, setShowCreateRoleModal }) => {
  const [roleName, setRoleName] = useState('')
  const { app } = useAppDetailsContext()
  const { org, tenant } = useUser()
  const { slices } = useSlices()
  const [RoleDescription, setRoleDescription] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const currentAccount = _.find(slices.snowFlakeAccounts, {
    Application: { RefID: app?.ObjectMeta?.ID }
  })

  const handleCreateRole = async () => {
    setIsLoading(true)
    const payloadObj = {
      ObjectMeta: {
        Name: btoa(getFriendlyName()),
        Namespace: org,
        Tenant: tenant
      },
      Spec: {
        Name: roleName,
        Description: RoleDescription,
        SnowflakeAccount: {
          RefKind: currentAccount.ObjectMeta.Kind,
          RefID: currentAccount.ObjectMeta.ID
        }
      }
    }

    try {
      const response = await reduxApiClient('snowflakeroles').create(payloadObj)
      console.log(response, '==== response')
      setTimeout(async () => {
        await reduxApiClient('snowflakeroles').getAll({})
        const roleStatus = slices.snowFlakeRoles.find(
          (role) => role.Spec.Name === response.Spec.Name
        )
        console.log(roleStatus, '====== role status', slices.snowFlakeRoles)
        if (roleStatus?.Status?.Status?.Status === 'Fail') {
          setError(roleStatus?.Status?.Status?.Error)
        } else {
          setShowCreateRoleModal(false)
          pushToSlice(response)
          enqueueNotification('SuccessFully Role is created!', 'info')
        }
        setIsLoading(false)
      }, 4000)
    } catch (error) {
      console.log(error)
    }
  }

  const handleNameInput = (value) => {
    const filteredValue = value.toUpperCase().replace(/[^A-Z0-9_]/g, '')
    setRoleName(filteredValue)
  }

  return (
    <>
      <FullScreenModal showModal={showCreateRoleModal}>
        <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
          <div className='w-[626px] rounded-lg p-8 bg-white'>
            <div className='flex justify-between items-center mb-[30px]'>
              <Typography variant='h3' className='text-[20px] m-0'>
                Create Permission Bundle
              </Typography>
            </div>

            <div>
              <TextInput
                label='Role Name*'
                style={{ width: '560px', marginBottom: '20px' }}
                onChange={(e) => handleNameInput(e.target.value)}
                value={roleName}
              />

              <TextInput
                label='Role Description'
                style={{ width: '560px' }}
                onChange={(e) => setRoleDescription(e.target.value)}
              />
            </div>
            <div className='mt-3'>
              <p className='text-[red]'>{error}</p>
            </div>
            <div className='flex mt-5 flex-row-reverse gap-5'>
              <Button variant='primary' size='md' onClick={() => handleCreateRole()}>
                Create
              </Button>
              <Button variant='gray' size='md' onClick={() => setShowCreateRoleModal(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </FullScreenModal>
      <LoadingFeedback caption='Please wait..' loading={isLoading} message='Creating Role!' />
    </>
  )
}

export { CreateRoleModal }
