import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { pushToSlice } from 'infra/redux/sliceHandlers'
import { enqueueNotification, getUserInfo } from 'Utils/Helpers'
import { FullScreenContentModal, LabelContent, LoadingFeedback } from 'V2Components'
import { createApproverPolicy, createTagPolicy } from 'features/settings'
import { Button, CloudIcon, Label, SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React, { useEffect, useRef, useState } from 'react'
import { SelectEntity } from '../SelectEntity'
import { createRef } from 'features/resources'
import { ResourceKinds, customTagKeys } from 'features/settings'

const ApproverPolicyCreateModal = ({ onCancel, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState('0')
  const { slices, selectDispatch, getObjectRef } = useMultiSlice([
    'accountList',
    'userList',
    'groupList'
  ])
  const [isAccountSelectVisible, setAccountSelectVisible] = useState(false)
  const [selectedAccountRef, setSelectedAccountRef] = useState({})
  const [selectedAccountValue, setSelectedAccountValue] = useState('0')
  const [selectedResourceValue, setSelectedResourceValue] = useState([])
  const [showEntityModal, SetShowEntityModal] = useState(false)
  const [selectedEntities, setSelectedEntities] = useState([])
  const [keyTag, setKeyTag] = useState('')
  const [valueTag, setValueTag] = useState('')
  const [policyName, setPolicyName] = useState('')
  const [selectedEntity, setSelectedEntity] = useState([])
  const [isTagInputVisible, setIsTagInputVisible] = useState(false)
  const [selectedEntitiesList, setSelectedEntitiesList] = useState([])
  const user = getUserInfo()

  useEffect(() => {
    selectDispatch(['accountList', 'userList', 'groupList'])
  }, [])

  const userAndGroupEntity = () => {
    return [...slices.userList, ...slices.groupList]
  }

  const handleCustomTagKeyValueUpdate = (label, value) => {
    if (label === 'key') {
      setKeyTag(value)
    } else {
      setValueTag(value)
    }
  }

  const getErrors = () => {
    try {
      if (policyName == '')
        return 'Approver Name is required and special characters are not allowed.'
      if (selectedValue === '0') return 'Select Account Type'
      if (selectedValue !== '1' && keyTag === '') return 'Please Enter tags'
      if (selectedValue === '1' && Object.keys(selectedAccountRef).length === 0)
        return 'Please Select any one Account'
      if (selectedResourceValue.length === 0) return 'Please Select any one Resources'
      if (selectedEntity.length === 0) return 'Please Select Approver'
    } catch (error) {
      return 'error'
    }
  }

  const errors = getErrors()

  const disableButton = () => {
    if (errors) return true
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const paylodObject = {
        ObjectMeta: {
          Name: policyName,
          NameSpace: user.org,
          Tenant: user.tenant
        },

        SearchType:
          selectedValue === '1' ? 'account' : selectedValue === '2' ? 'cloudtag' : 'poltag',
        SearchString: keyTag + ':' + valueTag,
        SearchRef: selectedAccountRef,
        KindMap: getResourcesMapObject(),
        Approvers: { ObjectRef: selectedEntity }
      }

      if (selectedValue === '1') {
        delete paylodObject.SearchString
      } else {
        delete paylodObject.SearchRef
      }

      const data = await createApproverPolicy(paylodObject)
      console.log(data, 'approver create success data')
      selectDispatch(['approvers'])
      pushToSlice(data)
      enqueueNotification('Tag created successfully!', 'info')
      onSuccess()
    } catch (error) {
      if (error.response) {
        console.log(error.response)
      }
      console.log(error)
      enqueueNotification('Failed to create tag!', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleDropdownChange = (label, value) => {
    if (label === 'type') {
      if (value === '1') {
        setAccountSelectVisible(true)
      } else {
        setAccountSelectVisible(false)
      }
      if (value === '2' || value === '3') {
        setIsTagInputVisible(true)
      } else {
        setIsTagInputVisible(false)
      }
      setSelectedValue(value)
    } else {
      if (label === 'resources') {
        setSelectedResourceValue(value)
      }
    }
  }

  const handleAccountDropdownChange = (selectedValue) => {
    const selectedIndex = parseInt(selectedValue, 10)
    if (!isNaN(selectedIndex) && selectedIndex > 0) {
      const selectedAccount = slices.accountList[selectedIndex - 1]

      if (selectedAccount) {
        setSelectedAccountRef(createRef(selectedAccount))
      }
    } else {
      setSelectedAccountRef(null)
    }
    setSelectedAccountValue(selectedValue)
  }

  const accountDropdownOptions = [
    {
      label: 'Please Select',
      value: '0',
      cloudType: ''
    },
    ...(slices?.accountList?.map((account, index) => ({
      label: (
        <>
          <CloudIcon
            type={account.Spec.Type?.toLowerCase()}
            style={{ marginRight: '10px', display: 'inline' }}
          />{' '}
          {account.ObjectMeta.Name}
        </>
      ),
      value: (index + 1).toString(), // Adjust the value to start from 1
      cloudType: account.Spec.Type
    })) || [])
  ]

  //Sort the menu items by cloud type in ascending order
  accountDropdownOptions.sort((a, b) => a.cloudType.localeCompare(b.cloudType))

  const getResourcesMapObject = () => {
    const updatedObject = {}
    for (const item of selectedResourceValue) {
      updatedObject[item] = 'true'
    }
    const finalUpdatedObject = {
      Map: updatedObject
    }
    return finalUpdatedObject
  }

  const handleEntityModalContinue = async () => {
    SetShowEntityModal(false)
    const ref_info_array = selectedEntities.map(createRef)
    const selectedEntitesList = getObjectRef(ref_info_array)
    setSelectedEntity(ref_info_array)
    setSelectedEntitiesList(selectedEntitesList)
  }

  const getFilterResources = () => {
    let filteredList = ResourceKinds.filter(
      (item) => item.kind !== 'MedusaNode' && item.kind !== 'Project'
    )
    let filteredListWithAccount
    if (selectedValue === '1') {
      filteredListWithAccount = filteredList.filter((item) => item.kind !== 'Application')
      return filteredListWithAccount.map((e) => ({ label: e.label, value: e.kind }))
    } else {
      return filteredList.map((e) => ({ label: e.label, value: e.kind }))
    }
  }

  const handleNameInput = (value) => {
    // Use a regular expression to replace any character that is not a letter or number with an empty string
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '')
    // Update the form state with the sanitized value
    setPolicyName(sanitizedValue)
  }

  return (
    <>
      <FullScreenContentModal>
        <div>
          <Typography className='mb-6' variant='h4-regular'>
            Create Policy
          </Typography>

          <TextInput
            value={policyName}
            onChange={(e) => handleNameInput(e.target.value)}
            sx={{ width: '100%', marginTop: '24px' }}
            label='Approver Policy Name'
          />

          <SelectDropDown
            label='Please Select Type'
            menuItems={[
              {
                label: 'Select Account',
                selected: true,
                value: '0'
              },
              {
                label: 'Account',
                value: '1'
              },
              {
                label: 'CloudTag',
                value: '2'
              },
              {
                label: 'PolicyTag',
                value: '3'
              }
            ]}
            onChange={(e) => handleDropdownChange('type', e.target.value)}
            value={selectedValue}
            style={{
              width: '100%',
              margin: '20px 0 20px 0'
            }}
          />

          {isAccountSelectVisible && (
            <SelectDropDown
              label='Please Select Account'
              placeholder='Select Resources Type'
              menuItems={accountDropdownOptions}
              onChange={(e) => handleAccountDropdownChange(e.target.value)}
              value={selectedAccountValue}
              style={{
                width: '100%',
                margin: '20px 0 20px 0'
              }}
            />
          )}

          {isTagInputVisible && (
            <div className=''>
              <div className='flex gap-4 mt-4 items-center'>
                <div>
                  <Typography variant='body-regular' className='mb-1'>
                    Please Select Key
                  </Typography>
                  <SelectDropDown
                    sx={{ minWidth: '150px' }}
                    menuItems={customTagKeys.map((e) => ({ label: e.label, value: e.kind }))}
                    onChange={(e) => handleCustomTagKeyValueUpdate('key', e.target.value)}
                    value={keyTag}
                  />
                </div>

                <TextInput
                  label='Please Enter Value'
                  value={valueTag}
                  onChange={(e) => handleCustomTagKeyValueUpdate('value', e.target.value)}
                  placeholder='Value'
                  sx={{ width: '65%' }}
                />
              </div>
            </div>
          )}

          <SelectDropDown
            label='Please Select Resources Type'
            placeholder='Select Resources Type'
            menuItems={getFilterResources()}
            onChange={(e) => handleDropdownChange('resources', e.target.value)}
            value={selectedResourceValue}
            multiple={true}
            style={{
              width: '100%',
              margin: '20px 0 20px 0'
            }}
          />

          <div className='flex gap-2 items-center mt-5 flex-wrap'>
            <Button
              icon={{
                icon: [
                  640,
                  512,
                  [],
                  'f0c0',
                  'M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z'
                ],
                iconName: 'users',
                prefix: 'fas'
              }}
              iconColor='#A6ABB6'
              iconPosition='end'
              onClick={() => SetShowEntityModal(true)}
              size='sm'
              variant='grayBlue'
            >
              Add Approver
            </Button>

            {selectedEntitiesList?.map((user) => (
              <Label key={user.ObjectMeta.Name} text={user.ObjectMeta.Name} />
            ))}
          </div>

          <div className='flex justify-between items-center gap-4 mt-6'>
            {errors ? <Label variant='warning' text={errors} /> : <div></div>}
            <div className='flex gap-4'>
              <Button onClick={onCancel} variant='gray'>
                Cancel
              </Button>
              <Button onClick={handleSubmit} disabled={disableButton()} variant='primary'>
                Create
              </Button>
            </div>
          </div>
          <LoadingFeedback message='Creating Tag' loading={isLoading} caption='Please wait..' />
        </div>
      </FullScreenContentModal>

      {showEntityModal && (
        <FullScreenContentModal width='auto'>
          <SelectEntity
            showPermission={''}
            title={`Select Approver`}
            errorMessage={selectedEntities.length < 1 && 'Select At least one entity.'}
            entities={userAndGroupEntity()}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onCancel={() => {
              SetShowEntityModal(false)
            }}
            onContinue={handleEntityModalContinue}
          />
        </FullScreenContentModal>
      )}
    </>
  )
}

export { ApproverPolicyCreateModal }
