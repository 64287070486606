import { cn } from 'Utils/Helpers'
import React from 'react'

function PrefixSuffixInput({ prefix = '', suffix = '', value, onTextChange }) {
  return (
    <div>
      <div
        className={cn('border border-[#c2c6cd] h-[46px] rounded flex items-center', {
          'p-4': prefix.length
        })}
      >
        {prefix && <div>{prefix}</div>}
        <div
          className={cn({
            'w-[100%]': !suffix
          })}
        >
          <input
            value={value}
            onChange={(e) => onTextChange?.(e.target.value)}
            className={cn('h-[44px] outline-none bg-[#F9FBFC]', {
              'px-3 border-x mx-3': prefix.length,
              'pr-3 border-r mr-3 pl-3 border-top rounded-s': !prefix.length,
              'border-r-0 w-[100%] mr-4': !suffix
            })}
          />
        </div>
        <div>{suffix}</div>
      </div>
    </div>
  )
}

export { PrefixSuffixInput }
