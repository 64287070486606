import { LabelContent } from 'V2Components'
import { useAppDetailsContext } from 'features/snowflake'
import { createDataSelectorHook } from 'infra/redux'
import { Label, TargetIcon, Typography } from 'procyon-ui'
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { reduxApiClient } from 'infra'
import { getPolicyActionMap } from 'features/policy'

const useSlices = createDataSelectorHook([
  'snowFlakeAccounts',
  'snowFlakeUsers',
  'snowFlakeRoles',
  'policyList',
  'userList'
])

const UsersTab = () => {
  const { app } = useAppDetailsContext()
  const { slices } = useSlices()
  const [usersList, setUsersList] = useState([])
  const [selectedUser, setSelectedUser] = useState({})
  const [puserList, setPUserList] = useState([])
  const [activePolicy, setActivePolicy] = useState({})

  useEffect(() => {
    reduxApiClient('snowflakeusers').getAll({})
    reduxApiClient('pacpolicys').getAll({})
  }, [slices])

  useMemo(() => {
    let procyonUserMap = []
    const filterCurrentAccount = slices.snowFlakeAccounts.find(
      (account) => account.Application.RefID === app.ObjectMeta.ID
    )

    const filterUsers = slices.snowFlakeUsers.filter(
      (role) => role.Spec.SnowflakeAccount.RefID === filterCurrentAccount?.ObjectMeta?.ID
    )

    filterUsers.filter((user) => {
      procyonUserMap.push(slices.userList.find((users) => users.Spec.EmailID === user.Spec.Email))
    })

    setPUserList(procyonUserMap)
    setUsersList(filterUsers)
    setSelectedUser(filterUsers[0])
  }, [])

  const handleUserClick = (user) => {
    setSelectedUser(user)
  }

  const getSnowFlakePolicyList = () =>
    slices.policyList.filter((policy) => {
      return policy?.Spec?.ActionMap?.Snowflake?.PolicyRule
    })

  const getExpiryFormatedTime = (time) => {
    const date = new Date(time)

    const options = { timeZone: 'Asia/Kolkata', hour12: false }
    const istDate = date.toLocaleString('en-US', options).replace(',', '')

    // Split the date and time for custom formatting
    const [datePart, timePart] = istDate.split(' ')
    const formattedDate = datePart.split('/').reverse().join('-') + '-' + timePart

    return formattedDate
  }

  const getGrantedRoles = (grantedObj) => {
    const grantedRolesSet = new Set()
    const grantedRolesArray = []

    grantedObj?.ObjectRef?.forEach((objref) => {
      const filteredRoles = slices.snowFlakeRoles.filter(
        (role) => role.ObjectMeta.ID === objref.RefID
      )

      filteredRoles.forEach((role) => {
        if (!grantedRolesSet.has(role.ObjectMeta.ID)) {
          grantedRolesSet.add(role.ObjectMeta.ID)
          grantedRolesArray.push(role)
        }
      })
    })

    // check if granted roles refid is exist in pacpolicy
    const snowflakePolicy = getSnowFlakePolicyList()

    const roleLabels = grantedRolesArray.map((role) => {
      const rolePolicies = snowflakePolicy.filter((policy) => {
        const actionMap = policy?.Spec?.ActionMap?.Snowflake?.PolicyRule
        const policyIssuedTo = policy?.IssuedTo?.ObjectRef

        return actionMap.some((pobj) => {
          const ispolicyIssuedTouser = policyIssuedTo.some(
            (issuedto) => issuedto.RefID === puserList[0]?.ObjectMeta.ID
          )
          return ispolicyIssuedTouser && pobj.ObjectRef.RefID === role.ObjectMeta.ID
        })
      })

      if (rolePolicies.length > 0) {
        // Get the expiry dates of the policies for the role
        const expiryDates = rolePolicies.map((policy) => policy.NotAfter).join(', ')
        return (
          <Label
            key={role.ObjectMeta.ID}
            variant='grayBlue'
            style={{ marginBottom: '5px' }}
            text={
              <div className='flex items-center'>
                {role.Spec.Name} -{' '}
                {expiryDates && (
                  <span className='text-[red] font-medium ml-1'>
                    Expiry - {getExpiryFormatedTime(expiryDates)}
                  </span>
                )}
              </div>
            }
          />
        )
      }

      return (
        <Label
          key={role.ObjectMeta.ID}
          variant='grayBlue'
          style={{ marginBottom: '5px' }}
          text={<div className='flex items-center'>{role.Spec.Name}</div>}
        />
      )
    })

    return roleLabels
  }

  return (
    <>
      <div className='flex'>
        <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
          {usersList &&
            usersList.map((user) => (
              <div className='my-5'>
                <div
                  className='flex justify-between items-center border p-2 cursor-pointer'
                  onClick={() => handleUserClick(user)}
                >
                  <div className='flex gap-2'>
                    <TargetIcon type={'USER'} />
                    <span className='' style={{ width: '250px', wordBreak: 'break-word' }}>
                      <Typography variant='body-regular'>{user.Spec.Email}</Typography>
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {usersList.length > 0 && (
          <div className='w-1/2 pr-8'>
            <Typography variant='h3' style={{ padding: '20px 30px' }}>
              Access Details of <span className='font-semibold'>{selectedUser?.Spec?.Email}</span>
            </Typography>
            <div>
              <LabelContent
                title='Default Roles'
                className='pl-7'
                content={
                  <Label
                    variant='grayBlue'
                    text={
                      <div className='flex items-center'>{selectedUser?.Spec?.DefaultRole}</div>
                    }
                  />
                }
              />
              <LabelContent
                title='Default Warehouse'
                className='pl-7'
                content={
                  <Label
                    variant='grayBlue'
                    text={
                      <div className='flex items-center'>
                        {selectedUser?.Spec?.DefaultWarehouse}
                      </div>
                    }
                  />
                }
              />

              <LabelContent
                className='pl-7'
                title='Granted Roles'
                content={getGrantedRoles(selectedUser?.Spec?.GrantedRoles)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export { UsersTab }
