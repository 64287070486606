import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { ResourcesListModal } from 'V2Components'
import {
  getPolicyDisplayName,
  getPolicyIssuedToEntities,
  getPolicyResourcesRefs
} from 'features/policy'
import { createDataSelectorHook } from 'infra/redux'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router'
import _ from 'lodash'

const useSlices = createDataSelectorHook(['salesForceUsersList', 'userList'])

function AccessSummaryModal({ policy, showModal, onClose }) {
  const { appView } = useAppView()
  const history = useHistory()
  const { slices } = useSlices()
  const { getObjectRef: getEntitiesObjects } = useMultiSlice([
    'userList',
    'groupList',
    'serviceAccounts',
    'workloads',
    'salesForceUsersList'
  ])
  const { getObjectRef: getRsrcsObjects } = useMultiSlice([
    'gcpResources',
    'appRolesList',
    'serverList',
    'databases',
    'applicationList',
    'azureResources',
    'awsResources',
    'kubeNamespaces',
    'githubResources',
    'githubAccount',
    'kubeClusters',
    'rdpServers'
  ])

  const getResources = () => {
    const rsrcRefs = getPolicyResourcesRefs(policy)
    return getRsrcsObjects(rsrcRefs)
  }

  const getEntities = () => {
    const entitiesRefs = getPolicyIssuedToEntities(policy)

    // check if user is belong to salesforce
    const salesforceUserObj = _.find(slices.salesForceUsersList, {
      ObjectMeta: { ID: entitiesRefs[0]?.RefID }
    })

    const filterSFUSerObj = _.find(slices.userList, {
      Spec: { EmailID: salesforceUserObj?.UserName }
    })

    if (filterSFUSerObj) {
      const userRefs = [
        {
          RefKind: filterSFUSerObj.ObjectMeta.Kind,
          RefID: filterSFUSerObj.ObjectMeta.ID
        }
      ]
      return getEntitiesObjects(userRefs)
    }

    return getEntitiesObjects(entitiesRefs)
  }

  return (
    <ResourcesListModal
      hideEntitiesTab={appView === 'user'}
      resources={getResources()}
      entities={getEntities()}
      caption={
        <>
          Created On{' '}
          <strong>{moment(policy.ObjectMeta.CreatedAt).format('MMMM Do YYYY, h:mm A')}</strong>
        </>
      }
      onClose={onClose}
      showModal={showModal}
      onViewDetailsClick={() =>
        history.push(`/${appView}/policys/${encodeURIComponent(policy.ObjectMeta.Name)}`)
      }
      title={getPolicyDisplayName(policy)}
    />
  )
}

export { AccessSummaryModal }
