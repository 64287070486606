import { _ } from 'Core'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { getObjectRefFlat } from 'Core/Hooks/useObjectRelations'
import { createRef } from 'features/resources'
import { getAppRoleSignInURL } from 'features/targets'
import { useCallback } from 'react'

const accountTypeResourceMap = {
  AwsResource: 'AWS',
  GcpResource: 'GCP',
  AzureResource: 'AZURE'
}

export const useIAMRsrcAccess = () => {
  const { slices, getObjectRef } = useMultiSlice(['policyList'])
  // TODO! Refactor code to return only the policy/s as part of the object's value
  const getPolicySignInURLS = useCallback(
    (user, skipIssuedCheck = false) => {
      const urlsMap = {}
      const groupsRefs = user.Spec.Groups.ObjectRef
      slices.policyList.forEach((policy) => {
        const IssuedTo = policy.IssuedTo.ObjectRef
        // Sometimes backend fails to convert to AssumeRole
        if (!policy.Spec.ActionMap.AssumeRole) return
        /**
         * Check if the policy is issued to the current user - regardless of the user as an admin or non-admin
         * Even admins don;t have access to IAM Resources until it is issued to the admin
         */
        if (
          !skipIssuedCheck &&
          !_.find(IssuedTo, createRef(user)) &&
          !groupsRefs.some((ref) => _.find(IssuedTo, ref))
        )
          return

        policy.Spec.ActionMap.AssumeRole?.PolicyRule.forEach((e) => {
          /**
           * Services are the AwsResources/GcpResources included in the policy
           * */
          const servicesRef = e.Services.ObjectRef
          // Target can be either AppRole for AwsResources or IamAction for GcpResources
          const targetRef = e.ObjectRef
          if (targetRef.RefKind === 'IamAction') {
            /**
             * This policy is for GcpResource
             * */
            const serviceRsrcs = getObjectRef(servicesRef)
            serviceRsrcs.map((r) => {
              const key = `${r.ObjectMeta.Kind}+${r.ObjectMeta.ID}`
              let url = r?.Status?.AccessLink
              let accountType = accountTypeResourceMap[r.ObjectMeta.Kind]
              if (r.ObjectMeta.Kind === 'KubeNamespace') {
                // Kubenamespaces dont have any access links to sign in, instead we show "use cli"
                url = 'null'
                accountType = r.Spec.Type
              }
              // Don't add access link with same policy name
              if (urlsMap[key] && !_.find(urlsMap[key].policys, { name: policy.GivenName })) {
                urlsMap[key].policys = [
                  { url, name: policy.GivenName, AppRoleID: null, ref: createRef(policy) },
                  ...urlsMap[key].policys
                ]
              } else {
                urlsMap[key] = {
                  accountType,
                  policys: [
                    { url, name: policy.GivenName, AppRoleID: null, ref: createRef(policy) }
                  ]
                }
              }
            })
          } else {
            /**
             * This policy is for AwsResource, because targetRef.RefKind is AppRole
             */
            const createdAppRole = getObjectRef(targetRef)
            if (!createdAppRole) return
            servicesRef.forEach((ref) => {
              // Services are the actual aws resources
              const key = `${ref.RefKind}+${ref.RefID}`
              const url = getAppRoleSignInURL(createdAppRole)
              const accountType = 'AWS'
              if (urlsMap[key] && !_.find(urlsMap[key], { name: policy.GivenName })) {
                urlsMap[key].policys = [
                  {
                    url,
                    name: policy.GivenName,
                    from: 'POLICY',
                    AppRoleID: createdAppRole.ObjectMeta.ID,
                    accountType,
                    ref: createRef(policy)
                  },
                  ...urlsMap[key].policys
                ]
              } else {
                urlsMap[key] = {
                  accountType,
                  policys: [
                    {
                      url,
                      name: policy.GivenName,
                      from: 'POLICY',
                      AppRoleID: createdAppRole.ObjectMeta.ID,
                      accountType,
                      ref: createRef(policy)
                    }
                  ]
                }
              }
            })
          }
        })
      })
      return urlsMap
    },
    [slices]
  )

  return { getPolicySignInURLS }
}
