import { Breadcrumbs } from 'V2Components'
import { Button, Typography } from 'procyon-ui'
import React, { useState } from 'react'

/**
 * @typedef {{
 * 	label: string
 * 	content: any
 * }} Step
 *
 * @typedef {{
 * activeStep: number
 * setActiveStep:any
 * totalSteps: number
 * }} FooterProps
 *
 * @param {{
 * steps: Step[]
 * FooterComponent:((props:FooterProps) => any)
 * header:any
 * onCancel: (() => void)
 * }} param0
 * @returns
 */
const Wizard = ({ steps, FooterComponent, header, onCancel }) => {
  const [activeStep, setActiveStep] = useState(0)

  const getBreadCrumbs = () => {
    return steps.map((step, index) => ({
      label: step.label,
      isActive: activeStep === index,
      onClick() {
        setActiveStep(index)
      }
    }))
  }

  return (
    <div>
      <Typography className='mb-2 flex justify-between items-center' variant='h4-regular'>
        {header}
        <Button onClick={onCancel} variant='gray'>
          Cancel
        </Button>
      </Typography>
      <Breadcrumbs maxItems={100} enableLastItemClick breadCrumbs={getBreadCrumbs()} />
      <div className='my-2'>{steps[activeStep]?.content && steps[activeStep]?.content}</div>
      <FooterComponent
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        totalSteps={steps.length}
      />
    </div>
  )
}

export { Wizard }
