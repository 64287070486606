import { faCircleMinus } from '@fortawesome/free-solid-svg-icons'
import { Grid } from '@material-ui/core'
import { _ } from 'Core'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import {
  IAMResourcesSliceNames,
  isCloudTypeSupportedIAMAction,
  useIAMRolesJSONMap
} from 'features/iamResources'
import { isCustomIAMRole } from 'features/iamRoles'
import { getResourceCloudType, getResourceName, useAccessCartProvider } from 'features/resources'
import { Button, IamRolesCard, Label, SimpleDropDown, Typography } from 'procyon-ui'
import React, { useCallback, useEffect, useState } from 'react'
import { FloatingDrawer, SearchInput } from 'V2Components'

/**
 *
 * selectedRsrc = {
 *  RefKind,
 *  RefID,
 *  type: CloudType
 * }
 * @returns
 */
function SimpleIAMRolesSelectionDrawer({
  showDrawer,
  onCloseDrawer,
  selectedRsrc,
  onCreateIAMRoleClick = null,
  initialIamRoles = [],
  onSaveClick
}) {
  const classes = {}
  const [searchKey, setSearchKey] = useState('')
  const [selectedIamRoles, setSelectedIamRoles] = useState(initialIamRoles)

  const [isSelectedIamRolesPopulated, setIsSelectedIamRolesPopulated] = useState(false)
  const { appView } = useAppView()
  const [sortRolesBy, setSortRolesBy] = useState('all')
  const { dispatchThunks, getObjectRef, slices } = useMultiSlice([
    'iamActions',
    ...IAMResourcesSliceNames
  ])

  const rsrcCloudType = getResourceCloudType(getObjectRef(selectedRsrc))
  const isCloudTypeSupportedIAMMap = isCloudTypeSupportedIAMAction(rsrcCloudType)

  const { getSupportedIAMActions } = useIAMRolesJSONMap()

  const handleDrawerClose = () => {
    setSelectedIamRoles([])
    setIsSelectedIamRolesPopulated(false)
    onCloseDrawer && onCloseDrawer()
  }

  const handleRoleActionClick = (role) => {
    setSelectedIamRoles((s) => {
      if (s.find((r) => r.title === role.title)) return s.filter((e) => e.title !== role.title)
      return [...s, role]
    })
  }

  const handleRemoveRoleClick = (role) =>
    setSelectedIamRoles((s) => s.filter((e) => e.title !== role.title))

  const handleAddToQueue = () => {
    onSaveClick?.(
      selectedRsrc,
      selectedIamRoles.map((e) => ({
        RefKind: 'IamAction',
        RefID: e.RefID
      }))
    )
    setSelectedIamRoles([])
    handleDrawerClose()
  }

  const isSearchMatch = useCallback(
    (e) => {
      const str = typeof e === 'object' ? JSON.stringify(e) : e
      return str.toLowerCase().includes(searchKey.toLowerCase())
    },
    [searchKey]
  )

  const getIAMActionTypeForResource = (rsrc) => {
    if (rsrc.ObjectMeta.Kind === 'AzureResource') {
      if (rsrc.Spec.DisplayType === 'Microsoft Entra ID') return IAMActionTypeMap.AzureAD
      return IAMActionTypeMap.AzureResource
    }
    if (rsrc.ObjectMeta.Kind === 'KubeNamespace') return rsrc.Spec.Type
    return IAMActionTypeMap[rsrc.ObjectMeta.Kind]
  }

  const getIamActionsList = (rsrcRefObject) => {
    if (!rsrcRefObject) return []
    const supportedIamActions = getSupportedIAMActions(rsrcRefObject.Spec.Type, rsrcCloudType)

    if (sortRolesBy === 'supported') {
      return supportedIamActions
    }

    if (sortRolesBy === 'all') {
      const rsrcType = getIAMActionTypeForResource(rsrcRefObject)
      if (isCloudTypeSupportedIAMAction(rsrcType)) {
        return [...supportedIamActions, ...slices.iamActions.filter(isCustomIAMRole)]
      } else return slices.iamActions
    }
    if (sortRolesBy === 'custom') return slices.iamActions.filter(isCustomIAMRole)
    if (sortRolesBy === 'predefined') return slices.iamActions.filter((e) => !isCustomIAMRole(e))
  }

  const getIamRolesToDisplay = () => {
    const items = []
    const rsrcRefObject = getObjectRef(selectedRsrc)
    if (!rsrcRefObject) return []
    const rsrcType = getIAMActionTypeForResource(rsrcRefObject)

    const iamList = getIamActionsList(rsrcRefObject)

    iamList.forEach((e) => {
      if (rsrcType === e.Spec.CloudType) {
        const data = {
          description: e.Spec.Description,
          title: getResourceName(e),
          actions: e.Spec.Actions.Elems,
          RefID: e.ObjectMeta.ID,
          selected: !!selectedIamRoles.find((s) => s.RefID === e.ObjectMeta.ID)
        }
        if (isSearchMatch([e.Spec.RoleName, e.Spec.Actions.Elems, e.ObjectMeta.Name]))
          items.push(data)
      }
    })
    return items.slice(0, 80)
  }

  const iamRoles = getIamRolesToDisplay()

  if (selectedRsrc && !isSelectedIamRolesPopulated) {
    setIsSelectedIamRolesPopulated(true)
  }

  const getSortDropdownOptions = () => {
    const customSupportedFilters = [
      {
        label: 'Sort: All',
        value: 'all'
      },
      {
        label: 'Sort: Supported By Resource',
        value: 'supported'
      },
      {
        label: 'Sort: Custom',
        value: 'custom'
      }
    ]

    const options = [
      {
        label: 'Sort: All',
        value: 'all'
      },
      {
        label: 'Sort: Predefined',
        value: 'predefined'
      },
      {
        label: 'Sort: Custom',
        value: 'custom'
      }
    ]

    return isCloudTypeSupportedIAMMap ? customSupportedFilters : options
  }

  useEffect(() => {
    dispatchThunks({ skipWhenLoaded: true })
  }, [])

  return (
    <div>
      <FloatingDrawer onOverlayClick={handleDrawerClose} showDrawer={showDrawer}>
        <div className='mx-4 relative'>
          <Typography variant='body-regular'>Select IAM Roles</Typography>
          <div className='flex justify-between my-4'>
            <SearchInput
              searchKey={searchKey}
              sx={{ width: '272px' }}
              className={classes.searchInput}
              onChange={setSearchKey}
            />
            <SimpleDropDown
              menuItems={getSortDropdownOptions()}
              onChange={(e) => setSortRolesBy(e.target.value)}
              value={sortRolesBy}
            />
          </div>
          <div className='flex gap-2 mb-4 flex-wrap'>
            {selectedIamRoles.map((e) => (
              <Label
                text={e.title}
                iconButton={faCircleMinus}
                variant='grayBlue'
                onClick={() => handleRemoveRoleClick(e)}
              />
            ))}
          </div>
          {appView === 'admin' && onCreateIAMRoleClick && (
            <div
              onClick={onCreateIAMRoleClick}
              className='px-6 py-4 w-[605px] border-solid border rounded-lg border-[#D8DDE4] cursor-pointer hover:opacity-80 mb-4'
            >
              <p className='font-bold text-[#8D94A1]'>Create Custom Role</p>
            </div>
          )}
          <div>
            {iamRoles.map((e) => (
              <IamRolesCard
                className='mb-2'
                key={e.title}
                description={e.description}
                selected={e.selected}
                assigned={e.selected}
                onClickActionButton={() => handleRoleActionClick(e)}
                title={e.title}
              >
                <div className={classes.roleDetailsContainer}>
                  <Grid spacing={1} container>
                    <Grid xs={4} item>
                      <Typography className={classes.roleCaption} variant='caption-regular'>
                        {e.title}:
                      </Typography>
                    </Grid>
                    <Grid xs={8} item>
                      {e.actions.map((action) => (
                        <Typography variant='caption-regular'>{action}</Typography>
                      ))}
                    </Grid>
                  </Grid>
                </div>
              </IamRolesCard>
            ))}
          </div>
          <div className='fixed bottom-0 flex !bg-white w-[671px] justify-end  gap-2 right-0 z-[501] border-t p-4'>
            <Button onClick={handleDrawerClose} variant='gray'>
              Cancel
            </Button>
            <Button
              disabled={!selectedIamRoles.length}
              onClick={handleAddToQueue}
              variant='primary'
            >
              Save
            </Button>
          </div>
        </div>
      </FloatingDrawer>
    </div>
  )
}

const IAMActionTypeMap = {
  AwsResource: 'AWS',
  GcpResource: 'GCP',
  AzureResource: 'AZURE',
  AzureAD: 'AZURE_AD'
}

export { SimpleIAMRolesSelectionDrawer }
