import { enqueueNotification } from 'Utils/Helpers'
import { openURLExternally, openURLSafely } from 'Utils/PureHelperFuctions'
import { getApplicationSignInURL } from 'features/applications'
import { isPolicyIssuedToUser, isWorkloadIdentityPolicy } from 'features/policy'
import { createRsrcKey, getResourceName } from 'features/resources'
import { getAppRoleSignInURL } from 'features/targets'

export const GraphResourceNodeActionsFunctionMap = {
  Application({ rsrc, errorInPolicy }) {
    if (errorInPolicy) return []
    return [
      {
        id: createRsrcKey(rsrc),
        label: 'Sign In',
        onClick: () => openURLSafely(getApplicationSignInURL(rsrc))
      }
    ]
  },
  AppRole({ rsrc, errorInPolicy }) {
    if (errorInPolicy) return []
    return [
      {
        id: createRsrcKey(rsrc) + 'signin',
        label: 'Sign In',
        onClick: () => openURLExternally(getAppRoleSignInURL(rsrc))
      },
      {
        id: createRsrcKey(rsrc) + 'profile',
        label: 'Copy Profile Name',
        onClick: () => {
          navigator.clipboard.writeText(rsrc.ObjectMeta.Name)
          enqueueNotification('Profile Name  copied : ' + rsrc.ObjectMeta.Name, 'info')
        }
      }
    ]
  },
  AwsResource({
    rsrc,
    isIssuedToLoggedInUser,
    approle,
    errorInPolicy,
    hideSignIn,
    policy,
    parentRsrc,
    isAgentlessMode
  }) {
    const actions = []
    if (isIssuedToLoggedInUser && approle && !errorInPolicy) {
      !hideSignIn &&
        actions.push({
          id: createRsrcKey(rsrc) + 'signin',
          label: 'Sign In',
          onClick: () => {
            openURLExternally(getAppRoleSignInURL(approle))
          }
        })
      // Hide the copy profile option for workload policies
      !isWorkloadIdentityPolicy(policy) &&
        actions.push({
          id: createRsrcKey(rsrc) + 'profile',
          label: 'Copy Profile Name',
          onClick: () => {
            navigator.clipboard.writeText(approle.ObjectMeta.Name)
            enqueueNotification('Profile Name  copied : ' + approle.ObjectMeta.Name, 'info')
          }
        })

      if (rsrc.Spec.Type === 'AWS_ECS_Service') {
        actions.push({
          id: createRsrcKey(rsrc) + 'copycomd',
          label: 'Copy Command',
          onClick: () => {
            navigator.clipboard.writeText(
              `${isAgentlessMode ? 'pcli' : 'pcloud'} exec ecs ${approle.ObjectMeta.Name} ${
                parentRsrc?.Spec.Name
              } "${rsrc.Spec.Arn}" "${rsrc.Spec.Region}"`
            )
            enqueueNotification('Profile Name  copied : ' + approle.ObjectMeta.Name, 'info')
          }
        })
      }
    }
    return actions
  },
  GcpResource({ rsrc, isIssuedToLoggedInUser, errorInPolicy, hideSignIn }) {
    const actions = []
    if (isIssuedToLoggedInUser && !errorInPolicy && !hideSignIn) {
      actions.push({
        id: createRsrcKey(rsrc) + 'signin',
        label: 'Sign In',
        onClick: () => openURLExternally(rsrc.Status.AccessLink)
      })
    }
    return actions
  },
  AzureResource({ rsrc, isIssuedToLoggedInUser, errorInPolicy, hideSignIn }) {
    const actions = []
    if (isIssuedToLoggedInUser && !errorInPolicy && !hideSignIn) {
      actions.push({
        id: createRsrcKey(rsrc) + 'signin',
        label: 'Sign In',
        onClick: () => openURLExternally(rsrc.Status.AccessLink)
      })
    }
    return actions
  },
  KubeNamespace({ rsrc, policy, approle, errorInPolicy, kubeCluster, user }) {
    const actions = []
    if (isPolicyIssuedToUser(policy, user)) {
      actions.push({
        id: createRsrcKey(rsrc),
        label: `Copy Context Name - ${getResourceName(policy)}`,
        onClick: () => {
          navigator.clipboard.writeText(
            `${kubeCluster.Spec.IAMRes}:procyon:` + policy?.ObjectMeta.Name
          )
          enqueueNotification(`Context Name  copied : ${kubeCluster.Spec.IAMRes}`, 'info')
        }
      })
    }

    /**
     * Show the copy profile button for only aws type KubeNamespaces
     */
    if (approle && rsrc.Spec.Type === 'AWS' && !errorInPolicy) {
      actions.push({
        id: createRsrcKey(rsrc) + 'profile',
        label: 'Copy Profile Name',
        onClick: () => {
          navigator.clipboard.writeText(approle.ObjectMeta.Name)
          enqueueNotification('Profile Name  copied : ' + approle.ObjectMeta.Name, 'info')
        }
      })
    }
    return actions
  },
  GithubResource({ rsrc, isIssuedToLoggedInUser, errorInPolicy, appSSOLinkApp }) {
    const actions = []
    if (isIssuedToLoggedInUser && !errorInPolicy) {
      actions.push({
        id: createRsrcKey(rsrc),
        label: 'Sign In',
        onClick: () => openURLSafely(getApplicationSignInURL(appSSOLinkApp))
      })
    }
    return actions
  },
  GithubAccount({ rsrc, isIssuedToLoggedInUser, errorInPolicy, appSSOLinkApp, hideSignIn }) {
    const actions = []
    if (isIssuedToLoggedInUser && !errorInPolicy && !hideSignIn) {
      actions.push({
        id: createRsrcKey(rsrc),
        label: 'Sign In',
        onClick: () => openURLSafely(getApplicationSignInURL(appSSOLinkApp))
      })
    }
    return actions
  },
  RDPServer({ rsrc, isIssuedToLoggedInUser, errorInPolicy, rdpSingInUrl, hideSignIn }) {
    const actions = []
    if (isIssuedToLoggedInUser && !errorInPolicy && !hideSignIn) {
      Object.keys(rdpSingInUrl).forEach((principal) => {
        const link = rdpSingInUrl[principal]
        actions.push({
          id: createRsrcKey(rsrc),
          label: `Sign In - ${principal}`,
          onClick: () => openURLExternally(link)
        })
      })
    }
    return actions
  },
  KubeCluster({ rsrc, policy, user }) {
    const actions = []
    if (isPolicyIssuedToUser(policy, user)) {
      actions.push({
        id: createRsrcKey(rsrc),
        label: `Copy Context Name - ${getResourceName(policy)}`,
        onClick: () => {
          navigator.clipboard.writeText(`${rsrc.Spec.IAMRes}:procyon:` + policy?.ObjectMeta.Name)
          enqueueNotification(`Context Name  copied : ${rsrc.Spec.IAMRes}`, 'info')
        }
      })
    }
    return actions
  },
  CRMEntity({ rsrc, policy, user }) {
    const actions = []
    if (isPolicyIssuedToUser(policy, user)) {
      actions.push({
        id: createRsrcKey(rsrc),
        label: `Copy Context Name - ${getResourceName(policy)}`,
        onClick: () => {
          navigator.clipboard.writeText(`${rsrc.Spec.IAMRes}:procyon:` + policy?.ObjectMeta.Name)
          enqueueNotification(`Context Name  copied : ${rsrc.Spec.IAMRes}`, 'info')
        }
      })
    }
    return actions
  }
}
