import { ApiProvider } from 'Core'
import useAppView from 'Core/Hooks/useAppView'
import { getUserInfo } from 'Utils/Helpers'
import _ from 'lodash'
import { useRef, useState } from 'react'

/**
 * @param {{
 * indexes: string[]
 * startDateTime: Date,
 * endDateTime: Date,
 * deviceIdentifier?: string
 * resourceIdentifier?: string
 * userName?: string
 * }} param0
 * @returns
 */
const useMultiQuery = ({
  indexes,
  userName,
  startDateTime,
  endDateTime,
  deviceIdentifier,
  resourceIdentifier
}) => {
  const prevHitsRef = useRef({})
  const abortControllerRef = useRef(new AbortController())
  const [fetching, setFetching] = useState(false)
  const [hits, setHits] = useState([])
  const { appView } = useAppView()

  const createAbortController = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort() // Abort the previous fetch (if any)
    }
    abortControllerRef.current = new AbortController() // Create a new AbortController
    return abortControllerRef.current.signal // Get the new AbortSignal
  }

  const getQueryLogs = async () => {
    const abortSignal = createAbortController()
    setFetching(true)
    let from = 0
    let allHits = []
    const batchSize = 500
    let totalRecords

    try {
      while (true) {
        const response = await fetchDataWithCancellation(abortSignal, from, batchSize)
        const decodeResponse = response?.data ? JSON.parse(atob(response?.data?.Response)) : ''
        const hits = decodeResponse?.hits?.hits || []
        totalRecords = decodeResponse?.hits?.total?.value

        allHits = [...allHits, ...hits]

        // Update displayed hits in the UI
        if (!_.isEqual(prevHitsRef.current, allHits)) {
          setFetching(false)
          setHits((state) => {
            prevHitsRef.current = allHits
            return allHits
          })
        }

        if (allHits.length < totalRecords) {
          from += batchSize
        } else {
          break
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setFetching(false)
    }
  }

  const fetchDataWithCancellation = async (abortSignal, from, batchSize) => {
    const user = getUserInfo()
    const UserName = appView === 'user' ? user.Spec.EmailID : userName || ''

    try {
      const res = await new ApiProvider('elasticlog')
        .setInstance({
          Indices: {
            Elems: indexes
          },
          Filters: {
            UserName: UserName,
            DeviceName: deviceIdentifier || '',
            ResourceName: resourceIdentifier || ''
          },
          Size: batchSize,
          StartTime: startDateTime,
          EndTime: endDateTime,
          From: from
        })
        .post()

      if (abortSignal && abortSignal.aborted) {
        throw new Error('fetch was cancelled')
      }

      return res
    } catch (error) {
      if (abortSignal && abortSignal.aborted) throw error
    }
  }

  return {
    getQueryLogs,
    fetching,
    hits
  }
}

export { useMultiQuery }
