import { faCaretDown, faCaretUp, faPen } from '@fortawesome/pro-solid-svg-icons'
import { LabelContent, LoadingFeedback, EditableLabelContent } from 'V2Components'
import { getUserStatus, updateUser } from 'features/users'
import moment from 'moment'
import { Button, Label, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { useOverviewDetails } from '../OverviewTab.utils'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import { enqueueNotification } from 'Utils/Helpers'

function UserInfo({ user }) {
  const [viewMore, setViewMore] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const { formFields, handleFormFieldChange, EditableFields, getErrors } = useOverviewDetails(user)

  const status = getUserStatus(user)

  const handleUserInfoUpdate = async () => {
    try {
      setIsUpdating(true)
      const newUser = structuredClone(user)
      // Update the user Spec fields with values from form fields
      EditableFields.forEach((f) => (newUser.Spec[f] = formFields[f]))
      const updtUser = await updateUser(newUser)
      updateSliceData(updtUser)
      enqueueNotification('User information updated successfully!', 'info')
      setEditMode(false)
    } catch (error) {
      enqueueNotification('Error updating user information.', 'error')
    } finally {
      setIsUpdating(false)
    }
  }

  const errors = getErrors()

  return (
    <div>
      <LoadingFeedback
        loading={isUpdating}
        caption='Please wait..'
        message='Updating user information'
      />
      <div className='flex justify-between items-center'>
        <Typography variant='h4-regular'>User Information</Typography>
        <div className='flex gap-4'>
          {editMode && (
            <Button
              onClick={handleUserInfoUpdate}
              disabled={!!Object.keys(errors).length}
              variant='primary'
            >
              Save Changes
            </Button>
          )}
          <Button
            icon={!editMode && faPen}
            onClick={() => setEditMode((s) => !s)}
            variant={editMode ? 'gray' : 'grayBlue'}
          >
            {editMode ? 'Cancel' : 'Edit Info'}
          </Button>
        </div>
      </div>
      <div className='mt-10'>
        <LabelContent
          showEmptyLabel
          title='Status'
          content={<Label text={status} variant={status === 'Active' ? 'grayBlue' : 'gray'} />}
        />
        <EditableLabelContent
          showEmptyLabel
          content={user.Spec.EmailID}
          inputName='EmailID'
          title='Email'
          value={formFields.EmailID}
          onChange={handleFormFieldChange}
          editMode={editMode}
          errorMessage={errors.EmailID}
        />
        <EditableLabelContent
          showEmptyLabel
          content={user.Spec.FullName}
          inputName='FullName'
          title='Full Name'
          value={formFields.FullName}
          onChange={handleFormFieldChange}
          editMode={editMode}
          errorMessage={errors.FullName}
        />
        <EditableLabelContent
          showEmptyLabel
          content={user.Spec.FirstName}
          inputName='FirstName'
          title='First Name'
          value={formFields.FirstName}
          onChange={handleFormFieldChange}
          editMode={editMode}
          errorMessage={errors.FirstName}
        />
        <EditableLabelContent
          showEmptyLabel
          content={user.Spec.LastName}
          inputName='LastName'
          title='Last Name'
          value={formFields.LastName}
          onChange={handleFormFieldChange}
          editMode={editMode}
          errorMessage={errors.LastName}
        />
        <EditableLabelContent
          showEmptyLabel
          content={user.Spec.MiddleName}
          inputName='MiddleName'
          title='Middle Name'
          value={formFields.MiddleName}
          onChange={handleFormFieldChange}
          editMode={editMode}
        />
        <EditableLabelContent
          showEmptyLabel
          content={user.Spec.PrimaryPhone}
          inputName='PrimaryPhone'
          title='Primary Phone'
          value={formFields.PrimaryPhone}
          onChange={handleFormFieldChange}
          editMode={editMode}
        />
        <EditableLabelContent
          showEmptyLabel
          content={user.Spec.MobilePhone}
          inputName='MobilePhone'
          title='Mobile Phone'
          value={formFields.MobilePhone}
          onChange={handleFormFieldChange}
          editMode={editMode}
        />
        <LabelContent
          showEmptyLabel
          title='Created On'
          content={moment(user.ObjectMeta.CreatedAt).format('MMMM Do YYYY, h:mm A')}
        />
        <LabelContent
          showEmptyLabel
          title='Last Updated On'
          content={moment(user.ObjectMeta.UpdatedAt).format('MMMM Do YYYY, h:mm A')}
        />
        <div className='flex justify-center my-4'>
          <Button
            className='!w-[120px]'
            onClick={() => setViewMore((s) => !s)}
            iconPosition='end'
            variant='gray'
            icon={viewMore ? faCaretUp : faCaretDown}
          >
            {viewMore ? 'Hide' : 'View More'}
          </Button>
        </div>
        {viewMore && (
          <div>
            <EditableLabelContent
              showEmptyLabel
              content={user.Spec.NickName}
              inputName='NickName'
              title='Nick Name'
              value={formFields.NickName}
              onChange={handleFormFieldChange}
              editMode={editMode}
            />
            <EditableLabelContent
              showEmptyLabel
              content={user.Spec.City}
              inputName='City'
              title='City'
              value={formFields.City}
              onChange={handleFormFieldChange}
              editMode={editMode}
            />
            <EditableLabelContent
              showEmptyLabel
              content={user.Spec.StreetAddress}
              inputName='StreetAddress'
              title='Street Address'
              value={formFields.StreetAddress}
              onChange={handleFormFieldChange}
              editMode={editMode}
            />
            <EditableLabelContent
              showEmptyLabel
              content={user.Spec.State}
              inputName='State'
              title='State'
              value={formFields.State}
              onChange={handleFormFieldChange}
              editMode={editMode}
            />
            <EditableLabelContent
              showEmptyLabel
              content={user.Spec.ZipCode}
              inputName='ZipCode'
              title='Zip Code'
              value={formFields.ZipCode}
              onChange={handleFormFieldChange}
              editMode={editMode}
            />
            <EditableLabelContent
              showEmptyLabel
              content={user.Spec.CountryCode}
              inputName='CountryCode'
              title='Country Code'
              value={formFields.CountryCode}
              onChange={handleFormFieldChange}
              editMode={editMode}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export { UserInfo }
