//prettier-ignore
export const MapKindToSliceName = {
  ADDomainController: 'adDomainControllers',
  Account         : 'accountList',
  AppRole         : 'appRolesList',
  Application     : 'applicationList',
  ApprovalReq     : 'approvalReqList',
  AwsResource     : 'awsResources',
  AzureResource   : 'azureResources',
  Database        : 'databases',
  GcpResource     : 'gcpResources',
  GithubResource  : 'githubResources',
  GithubAccount   : 'githubAccount',
  Group           : 'groupList',
  IamAction       : 'iamActions',
  KubeNamespace   : 'kubeNamespaces',
  Kafka           : 'kafkas',
  Org             : 'orgs',
  PacPolicy       : 'policyList',
  PaaPolicy       : 'paaPolicyList',
  Project         : 'projects',
  Server          : 'serverList',
  ServiceAccount  : 'serviceAccounts',
  ServiceNowTable : 'servicenowtables',
  TagPolicy       : 'tagPolicys',
  TenantProfile   : 'tenantprofiles',
  RDPServer       : 'rdpServers',
  User            : 'userList',
  SlackChannel    : 'slackChannelList',
  JiraProject     : 'jiraProjectList',
  Approvers       : 'approvers',
  MedusaNode      : 'medusaNodes',
  WorkloadIdentity: 'workloads',
  KubeCluster     : 'kubeClusters',
  NotificationSubscription: 'notificationsubscriptions',
  ReqBundle: 'bundles',
  rdppasswords: 'rdppasswords',
  //!TODO Remove these non-kind keys
  SalesForceUsersList: 'salesForceUsersList',
  SalesForcePermissionList: 'salesForcePermissionList',
  SalesForceAccountList: 'salesForceAccountList',
  SalesForceUserPermissionBinding: 'salesForceUserPermissionBinding',
  SalesforceUser: 'salesForceUsersList',
  CRMEntity: 'salesForcePermissionList',
  SnowflakeRole: 'snowFlakeRoles',
  SalesforceAccount: 'salesForceAccountList'
}
