import useMultiSlice from 'Core/Hooks/useMultiSlice'
import {
  AccessSummaryTab,
  getPolicyAttachedIAMActions,
  getPolicyIssuedToEntities,
  getPolicyResourcesRefs,
  usePolicyDetailsContext
} from 'features/policy'
import { createRsrcKey, getResourceName } from 'features/resources'
import {
  PRINCIPAL_OBJECT_KINDS,
  getK8ClusterAppRoleWithID,
  getTargetsPolicyRules
} from 'features/targets'
import { createDataSelectorHook, useObjectRef } from 'infra/redux'
import React from 'react'
import _ from 'lodash'
const useSlices = createDataSelectorHook([
  'salesForceUsersList',
  'salesForcePermissionList',
  'userList'
])
function AccessSummary() {
  const { policy } = usePolicyDetailsContext()
  const { getObjectRef } = useObjectRef([
    'userList',
    'groupList',
    'serviceAccounts',
    'awsResources',
    'gcpResources',
    'azureResources',
    'appRolesList',
    'serverList',
    'databases',
    'kubeNamespaces',
    'iamActions',
    'applicationList',
    'githubResources',
    'githubAccount',
    'workloads',
    'rdpServers',
    'kubeClusters',
    'kafkas'
  ])
  const { slices } = useSlices()

  const getEntities = () => {
    const refs = getPolicyIssuedToEntities(policy)

    // check if user is belong to salesforce
    const salesforceUserObj = _.find(slices.salesForceUsersList, {
      ObjectMeta: { ID: refs[0]?.RefID }
    })

    const filterSFUSerObj = _.find(slices.userList, {
      Spec: { EmailID: salesforceUserObj?.UserName }
    })

    if (filterSFUSerObj) {
      const userRefs = [
        {
          RefKind: filterSFUSerObj.ObjectMeta.Kind,
          RefID: filterSFUSerObj.ObjectMeta.ID
        }
      ]
      return getObjectRef(userRefs)
    }
    return getObjectRef(refs)
  }

  const getResources = () => {
    const refs = getPolicyResourcesRefs(policy)
    return getObjectRef(refs)
  }

  // Get Policy principal attributes
  const getPrincipalOnResources = () => {
    const map = {}
    const actionKeys = Object.keys(policy.Spec.ActionMap)
    actionKeys.forEach((actionKey) => {
      const rules = policy.Spec.ActionMap[actionKey].PolicyRule
      rules.forEach((rule) => {
        if (PRINCIPAL_OBJECT_KINDS.includes(rule.ObjectRef.RefKind)) {
          map[createRsrcKey(rule.ObjectRef)] = [`Principal: ${rule.Principal}`]
        }
      })
    })
    return map
  }

  const getIAMActionsAttributes = () => {
    const rsrcAttributesObj = {}
    const rsrcAttachedIAMActions = getPolicyAttachedIAMActions(policy)
    for (const rsrcKey in rsrcAttachedIAMActions) {
      const actionRefs = rsrcAttachedIAMActions[rsrcKey]
      const actionsObjects = getObjectRef(actionRefs)
      actionsObjects.forEach((action) => {
        const attrs = rsrcAttributesObj[rsrcKey] || []
        attrs.push(getResourceName(action))
        rsrcAttributesObj[rsrcKey] = attrs
      })
    }
    return rsrcAttributesObj
  }

  const getK8AppRoleAttributes = () => {
    const map = {}
    const { KubeAccess } = getTargetsPolicyRules(policy)

    KubeAccess.forEach((rule) => {
      let cluster = null

      if (rule.ObjectRef.RefKind === 'KubeCluster') cluster = getObjectRef(rule.ObjectRef)
      if (rule.ObjectRef.RefKind === 'KubeNamespace') {
        // Get the cluster
        const namespace = getObjectRef(rule.ObjectRef)
        if (!namespace) return
        cluster = getObjectRef(namespace.Spec.Cluster)
      }

      if (!cluster) return
      const attrs = map[createRsrcKey(rule.ObjectRef)] || []
      const services = rule.Services.ObjectRef
      // Get approle id map
      const approleIDMap = getK8ClusterAppRoleWithID(cluster)
      services.forEach((s) => {
        // Push approle name
        if (approleIDMap[s.RefID]) attrs.push(approleIDMap[s.RefID])
      })
      map[createRsrcKey(rule.ObjectRef)] = attrs
    })
    return map
  }

  const getResourcesAttributes = () => {
    const PrincipalMap = getPrincipalOnResources()
    const IAMActionMap = getIAMActionsAttributes()
    const K8AttributesMap = getK8AppRoleAttributes()
    return { ...IAMActionMap, ...PrincipalMap, ...K8AttributesMap }
  }

  return (
    <div>
      <AccessSummaryTab
        resourceAttibutesObject={getResourcesAttributes()}
        resources={getResources()}
        entities={getEntities()}
      />
    </div>
  )
}

export { AccessSummary }
