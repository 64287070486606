import React, { useContext, useRef } from 'react'
import { createContext } from 'react'
import {
  addToCart,
  clearCart,
  removeResourceFromCart,
  updateCart,
  updateCartQueue,
  updateCartStatus
} from 'infra/redux/reducers/slices/cartSlice'
import { useDispatch, useSelector } from 'react-redux'
import { cartAPIFn } from '../api'
import _ from 'lodash'
import { enqueueNotification } from 'Utils/Helpers'
import { K8_PRINCIPAL_KEY } from '../utils'

const AccessCartContext = createContext(null)

/**
 *
 * @param {{ children:any, cartKey:import('../hooks').key }} param0
 * @returns
 */
export const AccessCartProvider = ({ children, cartKey }) => {
  const cartsAPI = useRef(cartAPIFn()).current
  const dispatch = useDispatch()
  // @ts-ignore
  const [cartObject, disableCloudSync] = useSelector((s) => [
    s.carts[cartKey]?.data,
    s.carts[cartKey]?.disableCloudSync
  ])
  // @ts-ignore
  const cartStatus = useSelector((s) => s.carts[cartKey]?.status)
  const cartItems = cartObject?.CartQueues?.CartQueue || []

  const getResourceFromCart = ({ RefKind, RefID }) => {
    return _.find(cartItems, { Resource: { RefKind, RefID } })
  }

  const isResourceInCart = ({ RefKind, RefID }) => {
    const index = _.findIndex(cartItems, { Resource: { RefKind, RefID } })
    return index !== -1
  }

  const addItemsToCart = async ({ resourceRef, roles, principal }) => {
    const data = {
      Resource: resourceRef,
      Roles: { ObjectRef: roles },
      Principal: principal
    }

    if (resourceRef.RefKind === 'KubeNamespace' && principal) {
      data.Principal = `${K8_PRINCIPAL_KEY}${principal}`
    }

    dispatch(
      addToCart({
        key: cartKey,
        data
      })
    )
    if (disableCloudSync) return
    const newCartObject = structuredClone(cartObject)
    newCartObject.CartQueues.CartQueue.push(data)
    try {
      await cartsAPI.updateCart(newCartObject)
    } catch (error) {
      enqueueNotification(`ERROR adding to cart! ${JSON.stringify(error)}`, 'error')
    }
  }

  /**
   *
   * @param {{ resourceRef:object, roles?:any[], principal?:string }} param0
   */
  const updateCartQueueItem = async ({ resourceRef, roles, principal }) => {
    const data = {
      Resource: resourceRef,
      Roles: { ObjectRef: roles },
      Principal: principal
    }

    if (typeof principal === 'undefined') delete data.Principal
    if (typeof roles === 'undefined') delete data.Roles
    dispatch(updateCartQueue({ key: cartKey, data }))
    if (disableCloudSync) return
    const index = _.findIndex(cartObject.CartQueues.CartQueue, { Resource: data.Resource })
    const newCartObject = structuredClone(cartObject)
    const queue = newCartObject.CartQueues.CartQueue || []

    if (index !== -1) {
      queue[index] = { ...queue[index], ...data }
      newCartObject.CartQueues.CartQueue = queue
    }
    await cartsAPI.updateCart(newCartObject)
  }

  const clearCartItems = async () => {
    dispatch(clearCart({ key: cartKey }))
    if (disableCloudSync) return
    const newCartObject = structuredClone(cartObject)
    newCartObject.CartQueues.CartQueue = []
    await cartsAPI.updateCart(newCartObject)
  }
  /**
   *
   * @param {{ RefKind:string , RefID: string }} resource
   */
  const removeFromCart = async (resource) => {
    dispatch(
      removeResourceFromCart({
        key: cartKey,
        resource
      })
    )
    if (disableCloudSync) return
    const newCartObject = structuredClone(cartObject)
    const queue = newCartObject.CartQueues.CartQueue || []
    const newQueue = queue.filter((e) => !_.isEqual(e.Resource, resource))
    newCartObject.CartQueues.CartQueue = newQueue
    await cartsAPI.updateCart(newCartObject)
  }

  const initiCarts = async () => {
    let data = null
    if (disableCloudSync) return
    try {
      data = await cartsAPI.getCart(cartKey)
    } catch (error) {
      data = await cartsAPI.createCart(cartKey)
    } finally {
      if (data) dispatch(updateCart({ key: cartKey, data }))
      dispatch(updateCartStatus({ key: cartKey, status: 'loaded' }))
    }
  }

  if (cartStatus === 'idle') {
    dispatch(updateCartStatus({ key: cartKey, status: 'loading' }))
    initiCarts()
  }

  return (
    <AccessCartContext.Provider
      value={{
        removeFromCart,
        clearCartItems,
        updateCartQueueItem,
        addItemsToCart,
        getResourceFromCart,
        isResourceInCart,
        cartItems,
        cartKey,
        isCartLoaded: cartStatus === 'loaded',
        isCartEmpty: cartItems.length === 0
      }}
    >
      {children}
    </AccessCartContext.Provider>
  )
}

export const useAccessCartProvider = () => {
  const props = useContext(AccessCartContext)
  if (props === null)
    throw new Error(
      'Context: AccessCartContext, is not accessible. Use provider to wrap the components.'
    )

  return props
}
