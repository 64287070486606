import { faHourglassEnd } from '@fortawesome/pro-solid-svg-icons'
import { ApiProvider } from 'Core'
import _ from 'lodash'
import moment from 'moment'
import { Button, Label, SelectDateTime, Typography } from 'procyon-ui'
import React, { useState } from 'react'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'

const ExtendExpiryModal = ({ onCancel, tenantApiKeyObj, onSuccess }) => {
  const [expiry, setExpiry] = useState(_.get(tenantApiKeyObj, 'Spec.NotAfter'))
  const [isLoading, setIsLoading] = useState(false)

  // If the current time is greater than the expiry time
  const isLessThanCurrentTime = moment().diff(expiry) > 0

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const clone = structuredClone(tenantApiKeyObj)
      clone.Spec.NotAfter = expiry
      const data = await new ApiProvider('tenantapikeys').setInstance(clone).put()
      onSuccess(data)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FullScreenContentModal>
      <div className='flex items-center justify-between'>
        <Typography variant='h4-regular'>Extend Expiry</Typography>
        <Button onClick={onCancel} variant='gray'>
          Cancel
        </Button>
      </div>
      <div className='mt-6'>
        <SelectDateTime value={expiry} onChange={(e) => setExpiry(new Date(e.toISOString()))} />
      </div>
      <div className='border border-[#d8dde4] my-4 inline-block pl-2 rounded-md'>
        <Typography className='flex gap-2 items-center' variant='body-regular'>
          New Expiry
          <Label iconButton={faHourglassEnd} text={moment(expiry).format('MMMM Do YYYY, h:mm A')} />
        </Typography>
      </div>
      <Typography variant='body-regular'>
        {!isLessThanCurrentTime ? (
          <>
            The API Secrets will expire <strong>{moment(expiry).fromNow()}</strong> from now.
          </>
        ) : (
          <>
            The expiry is less than the current date and time.
          </>
        )}
      </Typography>
      <Button
        onClick={handleSave}
        className='ml-auto mt-6'
        variant='primary'
        disabled={isLessThanCurrentTime}
      >
        Save
      </Button>
      <LoadingFeedback loading={isLoading} message='Updating Expiry' caption='Please waut...' />
    </FullScreenContentModal>
  )
}

export { ExtendExpiryModal }
