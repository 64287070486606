import { faRadar, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import { useUser } from 'Core/Hooks/useUser'
import { openURLSafely } from 'Utils/PureHelperFuctions'
import { getApplicationSignInURL, isReadOnlyApp } from 'features/applications'
import { ErrorModal } from 'features/policy'
import {
  SummaryHeader,
  getResourceName,
  getRsrcIcon,
  useAccessCartProvider
} from 'features/resources'
import { Button, IconButton, Label } from 'procyon-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router'

function Header({
  app,
  showSignIn,
  onEdit,
  onDelete,
  onAccessClick,
  disableAccessBtn = false,
  showScanning,
  onSync,
  isSyncDisabled,
  setShowCreateRoleModal
}) {
  const { appView } = useAppView()
  const { rdpurl, isAgentlessMode } = useUser()
  const history = useHistory()
  const { clearCartItems } = useAccessCartProvider()
  const [showErrorModal, setShowErrorModal] = useState(false)

  const handleBreadcrumbClick = (link) => {
    clearCartItems()
    if (link) {
      history.push(link)
    }
  }

  const onErrorClick = () => {
    setShowErrorModal(true)
  }

  const handleSignInClick = () => {
    let url = getApplicationSignInURL(app)
    console.log(url)
    if (isAgentlessMode) {
      url = url.replace('.default', '-app-default').replace('.procyon.ai', `.${rdpurl}`)
      url && openURLSafely(url)
    } else {
      url && openURLSafely(url)
    }
  }

  // hide request button if github and user view
  const getIsReadOnly = (app) => {
    if (['snowflake'].includes(app?.Spec?.AppType)) {
      return false
    } else {
      return !isReadOnlyApp(app)
    }
  }
  const getErrorStatus = (app) => {
    if (app.Spec.AppType === 'snowflake') {
      return app.Spec.SnowflakeAppConfig.Status.Status === 'Fail' ? true : false
    }
    if (app.Spec.AppType === 'salesforce')
      return app.Spec.SalesforceAppConfig.Status.Status == 'Fail' ? true : false
  }

  const getErrorMessage = () => {
    if (app.Spec.AppType === 'salesforce') {
      return app.Spec?.SalesforceAppConfig?.Status.Error
    }

    if (app.Spec.AppType === 'snowflake') {
      return app.Spec?.SnowflakeAppConfig?.Status.Error
    }
  }
  const showErrorIcon = getErrorStatus(app)

  return (
    <div className='bg-[#F9FBFC] -mx-4 -mt-5 p-4'>
      <SummaryHeader
        Icon={getRsrcIcon(app)}
        rsrcName={getResourceName(app)}
        actions={
          <div className='flex gap-4'>
            {showErrorIcon && (
              <IconButton
                icon={faTriangleExclamation}
                onClick={onErrorClick}
                size='sm'
                variant='grayRed'
              />
            )}

            {showScanning && (
              <Label iconButton={faRadar} variant='success' size='lg' text='Sync In Progress' />
            )}

            {!showScanning && appView === 'admin' && (
              <Button size='md' variant='grayBlue' onClick={onSync} disabled={isSyncDisabled}>
                Sync
              </Button>
            )}

            <Button size='md' variant='grayBlue' onClick={() => setShowCreateRoleModal(true)}>
              Create Role
            </Button>

            {getIsReadOnly(app) && (
              <Button
                disabled={disableAccessBtn}
                onClick={onAccessClick}
                size='md'
                variant='grayBlue'
              >
                {appView === 'admin' ? 'Create Policy' : 'Request'}
              </Button>
            )}

            {showSignIn && (
              <Button onClick={handleSignInClick} size='md' variant='grayBlue'>
                Sign In
              </Button>
            )}
            {appView === 'admin' && (
              <>
                <Button onClick={onEdit} size='md' variant='gray'>
                  Edit
                </Button>

                <Button onClick={onDelete} size='md' variant='grayRed'>
                  Delete
                </Button>
              </>
            )}
          </div>
        }
        breadCrumbsItems={[
          {
            label: 'Resource Catalog',
            link: `/${appView}/datacloud`,
            onClick: () => handleBreadcrumbClick(`/${appView}/datacloud`)
          },
          {
            label: getResourceName(app),
            link: null,
            onClick: () => handleBreadcrumbClick(null)
          }
        ]}
      />
      {showErrorModal && (
        <ErrorModal
          message={getErrorMessage()}
          onClose={() => {
            setShowErrorModal(false)
          }}
          hideViewDetails
          onViewDetailsClick={() => {}}
          showModal
          title='Error'
        />
      )}
    </div>
  )
}

export { Header }
