import { Grid, makeStyles } from '@material-ui/core'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { cn } from 'Utils/Helpers'
import { PaginationView } from 'V2Components'
import {
  createRsrcKey,
  getResourceCloudType,
  getRsrcAccountRef,
  useResourcesViewContext
} from 'features/resources'
import { isPrivateServer } from 'features/targets'
import React, { useEffect, useState } from 'react'
import { ResourceFilterHeader } from './components/ResourceFilterHeader'
import {
  DEFAULT_SEARCH_SUGGESTIONS_MAP,
  ResourceQueryFilterMap,
  ResourceTagSuggesstionsMap,
  rsrcSearchDataMatch
} from './utils'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  resource: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  filters: {
    backgroundColor: '#F9FBFC',
    width: '336px',
    position: 'relative',
    overflow: 'wrap',
    minHeight: '100vh'
  },
  items: {
    backgroundColor: 'white',
    padding: theme.spacing(1.5, 2),
    width: 'calc(100% - 336px)'
  },
  catalogText: {
    fontWeight: 500,
    fontSize: '24px'
  },
  filterText: {
    fontSize: '18px'
  },
  filterDropDown: {
    width: '307px',
    marginLeft: '24px'
  },
  groupFilters: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  searchInput: {
    width: '303px'
  },
  viewSelect: {
    marginLeft: '25px',
    display: 'inline-block'
  },
  resourceItemCont: {
    marginTop: theme.spacing()
  }
}))

/**
 * @typedef {{ start: number, end:number }} PaginationIndex
 * @param {{
 * HeaderFilter?: any
 * setRenderedResources?: ((rsrcs: object[]) => void),
 * cloudOptions:string[],
 * activeCloudType:string,
 * setActiveCloudType:((value) => void),
 * disableAccountControls?: boolean,
 * accountType?: string,
 * accountID?: string,
 * FilterComponent: any,
 * resources: any[],
 * children?:any,
 * renderCardComponent:((rsrc) => void),
 * render4Cards?:boolean,
 * filterSaveKey:string
 * }} param0
 * @returns
 */
function ResourcesView({
  disableAccountControls,
  resources,
  children,
  FilterComponent,
  renderCardComponent,
  activeCloudType,
  setActiveCloudType,
  cloudOptions,
  HeaderFilter,
  render4Cards,
  accountID,
  filterSaveKey
}) {
  const classes = useStyles()
  const { dispatchThunks, getObjectRef } = useMultiSlice(['accountList'])
  // Account filter, '' for All accounts
  const [selectedDropdownKey, setSelectedDropdownKey] = useState('')
  // Search filter
  const [searchKey, setSearchKey] = useState('')

  const { applySearchQuery, getSuggestionsData } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (e, k) =>
      rsrcSearchDataMatch(e, k, { account: getObjectRef(getRsrcAccountRef(e)) }),
    queryMapKeyExtarctor: 'ObjectMeta.Kind',
    queryMap: ResourceQueryFilterMap,
    suggesstionsMap: ResourceTagSuggesstionsMap
  })

  const { dropDownItems } = useResourcesViewContext()

  if (accountID && selectedDropdownKey !== accountID) setSelectedDropdownKey(accountID)

  /**
   *
   * @param {any[]} rsrcs
   * @param {'AWS' | 'GCP' | 'AZURE' | 'PRIVATE' | string} accountType
   * @returns {any[]}
   */
  const getResourcesByAccountType = (rsrcs, accountType) => {
    const list = []
    rsrcs.forEach((rsrc) => {
      const privateServer = isPrivateServer(rsrc)
      const rsrcCloudType = getResourceCloudType(rsrc)

      if (accountType.includes('AWS') && rsrcCloudType === 'AWS') list.push(rsrc)
      if (accountType.includes('GCP') && rsrcCloudType === 'GCP') list.push(rsrc)
      if (accountType.includes('AZURE') && rsrcCloudType === 'AZURE') list.push(rsrc)

      if (accountType === 'PRIVATE' && privateServer) list.push(rsrc)
    })
    return list
  }

  const filteredResources = (() => {
    const _rsrcs = []
    const rsrcsList = !activeCloudType
      ? resources
      : getResourcesByAccountType(resources, activeCloudType) // Account brand filtered resources

    const searchFilteredList = applySearchQuery(rsrcsList)
    searchFilteredList.forEach((rsrc) => {
      const account = getObjectRef(getRsrcAccountRef(rsrc))
      // If the resource doesn't have an account, check if it is private server
      if (!account && activeCloudType === 'PRIVATE' && isPrivateServer(rsrc))
        return _rsrcs.push(rsrc)
      // Check if the account id is non-empty and check if the resource belongs to this account
      if (!account) return
      if (selectedDropdownKey && createRsrcKey(account) !== selectedDropdownKey) return
      _rsrcs.push(rsrc)
    })

    return _rsrcs
  })()

  const getDefaultSuggestions = () => {
    const defSuggestions = []

    const pushedTemplates = {}

    filteredResources.forEach((r) => {
      const options = DEFAULT_SEARCH_SUGGESTIONS_MAP[r.ObjectMeta.Kind] || []
      options.forEach((opt) => {
        // Do not push duplicate templates
        if (pushedTemplates[opt.template]) return
        defSuggestions.push(opt)
        pushedTemplates[opt.template] = true
      })
    })

    return defSuggestions
  }

  useEffect(() => {
    dispatchThunks()
  }, [])

  return (
    <div className='flex justify-center -ml-8 -mr-5 -mt-5'>
      <Grid container>
        <Grid className={classes.filters} item>
          {FilterComponent && <FilterComponent rsrcs={filteredResources} />}
        </Grid>
        <Grid className={classes.items} item>
          <div className='flex flex-col'>
            <ResourceFilterHeader
              filterSaveKey={filterSaveKey}
              suggestions={getSuggestionsData(filteredResources)}
              defaultSuggestions={getDefaultSuggestions()}
              activeCloudType={activeCloudType}
              cloudOptions={cloudOptions}
              disableAccountControls={disableAccountControls}
              dropDownItems={dropDownItems}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              selectedDropdownValue={selectedDropdownKey}
              setActiveCloudType={setActiveCloudType}
              setSelectedDropdownValue={setSelectedDropdownKey}
              HeaderFilter={HeaderFilter}
            />
            <PaginationView
              itemsCount={12}
              data={filteredResources}
              renderFunction={renderCardComponent}
              WrapperComponent={({ children }) => (
                <div
                  className={cn(
                    {
                      'grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3': !render4Cards,
                      'grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4': render4Cards
                    },
                    'grid gap-8 mt-4'
                  )}
                >
                  {children}
                </div>
              )}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export * from './utils'
export { ResourcesView }

