import { getCurrentOrg, getUserInfo } from 'Utils/Helpers'
import { axios } from './axios'
import _ from 'lodash'
import {
  DEFAULT_NAMESPACE_ONLY_ENDPOINT_MAP,
  FETCH_ALL_WHEN_ADMIN_ENDPOINTS,
  PUBLIC_ENDPOINTS
} from './constants'
import { getFriendlyName } from 'Utils/FriendlyName'

/**
 *  @typedef {{ noNamespaceFilter?: boolean }} GetAllApiOptions
 */

/**
 *
 * @param {import('types').Endpoints} endpoint
 * @returns
 */
export function apiClient(endpoint) {
  const user = getUserInfo()
  const { tenant, apiKey } = user
  const org = getCurrentOrg()
  // Get api prefix
  const apiPrefix = PUBLIC_ENDPOINTS.includes(endpoint) ? 'pubapi' : 'api'
  if (!endpoint) throw new Error('API Endpoint is null!')
  const config = {
    baseURL: `/${apiPrefix}/${tenant}/v1/${endpoint}`,
    Namespace: DEFAULT_NAMESPACE_ONLY_ENDPOINT_MAP[endpoint] ? 'default' : org,
    tenant
  }

  /**
   * @type {import('axios').AxiosRequestConfig}
   */
  const axiosConfig = {
    headers: { Authorization: `Bearer ${apiKey}` },
    params: {
      'ObjectMeta.Namespace': config.Namespace
    }
  }

  return {
    /**
     * @param {import('axios').AxiosRequestConfig['params']} prms
     * @returns
     */
    async getByParams(prms) {
      try {
        // Merge the params
        _.merge(axiosConfig.params, prms)
        const paramURL = new URLSearchParams(axiosConfig.params).toString()
        const url = `${config.baseURL}${paramURL ? '?' + paramURL : ''}`
        // If fetch is running return
        if (FETCHING_KEYS_MAP[url]) return
        FETCHING_KEYS_MAP[url] = true
        const response = await axios.get(url, {
          ...axiosConfig,
          params: {} // already done in the url
        })
        // Reset fetch key
        FETCHING_KEYS_MAP[url] = false
        return Object.values(response.data)[0] || null
      } catch (error) {
        console.error('[API CLIENT ERROR]()', error)
        return null
      }
    },
    /**
     * @param {string} ID
     * @returns
     */
    async getByID(ID) {
      try {
        const params = {
          'ObjectMeta.ID': ID
        }
        const response = await this.getByParams(params)
        return response?.[0]
      } catch (error) {
        console.error('[API CLIENT ERROR]()', error)
        return null
      }
    },
    /**
     *
     * @param {string} name The Object Name to get
     */
    async getByName(name) {
      try {
        const r = await axios.get(`${config.baseURL}/${org}/${name}`)
        return r.data || null
      } catch (error) {}
    },
    /**
     * @param {GetAllApiOptions} options
     * @returns
     */
    async getAll(options) {
      const { noNamespaceFilter = false } = options || {}
      try {
        /**
         ** Some API Endpoints (FETCH_ALL_WHEN_ADMIN_ENDPOINTS), the Namespace param ?ObjectMeta.Namespace=${config.Namespace}
         ** needs to be removed so that admin can see all the objects when in DEFAULT namespace.
         *
         */
        const isAdmin = getUserInfo().role === 'admin'
        const isAdminRoute = FETCH_ALL_WHEN_ADMIN_ENDPOINTS[endpoint]
        // User must be admin and the endpoint is supported and, org MUST be DEFAULT
        const namespaceParam =
          noNamespaceFilter || (isAdmin && isAdminRoute && org === 'default')
            ? ''
            : `?ObjectMeta.Namespace=${config.Namespace}`

        const url = `${config.baseURL}${namespaceParam}`
        // If fetch is running return
        if (FETCHING_KEYS_MAP[url]) return
        FETCHING_KEYS_MAP[url] = true
        const r = await axios.get(url)
        // Reset fetch key
        FETCHING_KEYS_MAP[url] = false
        return Object.values(r.data)[0] || []
      } catch (error) {
        console.error('[API CLIENT ERROR]()', error)
        return []
      }
    },
    async create(object) {
      try {
        object.ObjectMeta.Name = object.ObjectMeta.Name || getFriendlyName()
        object.ObjectMeta.Namespace = object.ObjectMeta.Namespace || config.Namespace
        object.ObjectMeta.Tenant = config.tenant
        const response = await axios.post(config.baseURL, object)
        return response.data
      } catch (error) {
        console.error('[API CLIENT ERROR]()', error)
        return null
      }
    },
    async update(object) {
      try {
        const objectBaseURL = createObjectBaseURL(object, { endpoint, tenant })
        const response = await axios.put(objectBaseURL, object)
        return response.data
      } catch (error) {
        console.error('[API CLIENT ERROR]()', error)
        return null
      }
    },
    async delete(object) {
      try {
        const objectBaseURL = createObjectBaseURL(object, { endpoint, tenant })
        await axios.delete(objectBaseURL, object)
        /**
         * * Backend response for delete doesn't contain info on which object got deleted.
         * * Hence, we return the `object` as is.
         *  */
        return object
      } catch (error) {
        console.error('[API CLIENT ERROR]()', error)
        return null
      }
    },
    async getAllByOffset(startIndex, endIndex) {
      try {
        const url = `${config.baseURL}?ObjectMeta.Namespace=${config.Namespace}`
        const response = await axios.get(url, {
          params: {
            'ObjectMeta.QueryParam': `offset:${startIndex}+limit:${endIndex}`
          },
          paramsSerializer: function (params) {
            var result = Object.keys(params)
              .map(function (key) {
                return key + '=' + params[key]
              })
              .join('&')
            // Build the query string
            return result
          }
        })
        return Object.values(response.data)[0] || null
      } catch (error) {}
    }
  }
}

/**
 * Map which stores GET url endpoints which is running, to prevent duplicate api calls
 */
const FETCHING_KEYS_MAP = {}

const createObjectBaseURL = (object, { endpoint, tenant }) => {
  const name = object.ObjectMeta.Name
  const org = object.ObjectMeta.Namespace
  const apiPrefix = PUBLIC_ENDPOINTS.includes(endpoint) ? 'pubapi' : 'api'

  return `/${apiPrefix}/${tenant}/v1/${endpoint}/${org}/${name}`
}
