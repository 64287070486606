import { FullScreenContentModal } from 'V2Components'
import { AllApplicationData } from 'features/applications/utils/constants'
import { IntegrationCard } from 'procyon-ui'
import React, { useState } from 'react'
import { AddApplication } from '../AddApplication'

const AllApplications = () => {
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showIntegrationModal, setShowIntegrationModal] = useState(false)
  const [selectedType, setSelectedType] = useState('')
  const [showAddApplication, setShowAddApplication] = useState(false)

  const handleInfoModalClick = (type) => {
    setShowInfoModal(true)
    setSelectedType(type)
  }

  const handleIntegrationModalClick = (type) => {
    setShowAddApplication(true)
    setShowInfoModal(false)
    if (type === 'WEB API (HTTP)') {
      setSelectedType('HTTP')
    } else {
      setSelectedType(type)
    }

    setShowIntegrationModal(true)
  }

  const getAppIcon = (type) => {
    switch (type) {
      case 'Http':
        return 'HTTPS_APPLICATION'
      case 'Github':
        return 'GITHUB_APPLICATION'
      case 'Dropbox':
        return 'DROPBOX_APPLICATION'
      case 'Salesforce':
        return 'SALESFORCE'
    }
  }

  return (
    <>
      <div className='grid gap-6 my-10 grid-cols-3'>
        {AllApplicationData.map((item) => (
          <IntegrationCard
            caption={item.caption}
            iconType={getAppIcon(item.type)}
            onClickAuthorizeButton={function noRefCheck() {}}
            onClickInfoButton={() => handleInfoModalClick(item.type.toLowerCase())}
            onClickIntegrateButton={() => handleIntegrationModalClick(item.type.toUpperCase())}
            onClickManageIntegrationButton={function noRefCheck() {}}
            onClickRemoveButton={function noRefCheck() {}}
            showInfoButton={false}
            showIntegrateButton
            subtitle={item.category}
            title={item.type}
            width='100%'
            className='mr-10'
          />
        ))}

        {showAddApplication && (
          <FullScreenContentModal>
            <AddApplication
              type={selectedType}
              onComplete={() => setShowAddApplication(false)}
              onClose={() => setShowAddApplication(false)}
            />
          </FullScreenContentModal>
        )}
      </div>
    </>
  )
}

export { AllApplications }
