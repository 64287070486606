import { useEffect, useState } from 'react'
import useMultiSlice from './useMultiSlice'
import { apiClient } from 'infra/api'

let CLOUDACTIONS_MAP = {
  AWS: [],
  GCP: [],
  AZURE: [],
  SNOWFLAKE: []
}

let isFetched = false

export default function useCloudActions() {
  const { initiallyLoaded, slices, dispatchThunks } = useMultiSlice(['iamActions'])
  const [aws, setAws] = useState(CLOUDACTIONS_MAP.AWS)
  const [gcp, setGcp] = useState(CLOUDACTIONS_MAP.GCP)
  const [azure, setAzure] = useState(CLOUDACTIONS_MAP.AZURE)
  const [snowflake, setSnowflake] = useState(CLOUDACTIONS_MAP.SNOWFLAKE)

  useEffect(dispatchThunks, [])
  useEffect(() => {
    if (!isFetched) {
      const names = ['aws', 'gcp', 'azure', 'snowflake']
      isFetched = true
      Promise.all(
        names.map(async (name) => {
          console.log('[fire]():')
          const d = await apiClient('cloudactions').getByName(name)
          CLOUDACTIONS_MAP[name.toUpperCase()] = d.ServiceActions.ServiceActions
          if (name === 'aws') setAws(d.ServiceActions.ServiceActions)
          if (name === 'gcp') setGcp(d.ServiceActions.ServiceActions)
          if (name === 'azure') setAzure(d.ServiceActions.ServiceActions)
          if (name === 'snowflake') setSnowflake(d.ServiceActions.ServiceActions)
        })
      )
    }
  }, [])

  return {
    awsCloudActions: aws,
    initiallyLoaded,
    iamActions: slices.iamActions,
    gcpCloudActions: gcp,
    azureCloudActions: azure,
    snowFlakeCloudActions: snowflake
  }
}
