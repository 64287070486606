import { createDataSelectorHook } from 'infra/redux'
import {
  Button,
  LoadingModal,
  SelectDropDown,
  TargetIcon,
  TreeItem,
  TreeView,
  Typography
} from 'procyon-ui'
import React, { useState } from 'react'
import _ from 'lodash'
import { useAppDetailsContext } from 'features/snowflake'
import { FullScreenModal, LoadingFeedback } from 'V2Components'
import useCloudActions from 'Core/Hooks/useCloudActions'
import {
  faDatabase,
  faProjectDiagram,
  faTable,
  faWarehouse
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { reduxApiClient } from 'infra'
import { enqueueNotification } from 'Utils/Helpers'
const useSlices = createDataSelectorHook([
  'snowFlakeResources',
  'snowFlakeAccounts',
  'snowFlakeRoles'
])
const ResourcesTab = () => {
  const { slices } = useSlices()
  const { app } = useAppDetailsContext()
  const { snowFlakeCloudActions } = useCloudActions()
  const [showGrantModal, setShowGrantModal] = useState(false)
  const [selectedPermission, setSelectedPermission] = useState([])
  const [actionsList, setActionsList] = useState([])
  const [selectedRsrc, setSelectedRsrc] = useState({})
  const [selectedRole, setSelectedRole] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const currentAccount = _.find(slices.snowFlakeAccounts, {
    Application: { RefID: app?.ObjectMeta?.ID }
  })
  const currentAccountResources = slices.snowFlakeResources.filter(
    (rsrc) => rsrc.Spec.SnowflakeAccount.RefID === currentAccount.ObjectMeta.ID
  )

  const filterRoles = slices.snowFlakeRoles.filter(
    (role) => role.Spec.SnowflakeAccount.RefID === currentAccount?.ObjectMeta?.ID
  )

  const rolesDropDownList = filterRoles.map((e) => ({ label: e.Spec.Name, value: e.Spec.Name }))
  const dataBaseObject = currentAccountResources.filter((rsrc) => rsrc.Spec.Type === 'DATABASE')
  const wareHouseObject = currentAccountResources.filter((rsrc) => rsrc.Spec.Type === 'WAREHOUSE')
  const schemaObject = currentAccountResources.filter((rsrc) => rsrc.Spec.Type === 'SCHEMA')
  const tableObject = currentAccountResources.filter((rsrc) => rsrc.Spec.Type === 'TABLE')

  const warehouseList = (rsrc) => {
    return rsrc.map((item) => (
      <TreeItem
        labelText={item.Spec.DisplayName}
        nodeId={`warehouse+${item.ObjectMeta.ID}`}
        showActionButton={true}
        onClickActionButton={() => handleGrantPermission(item)}
        caption='Warehouse'
        actionButtonVariant='grayBlue'
        actionButtonText='Grant Privileges'
      />
    ))
  }

  const databaseList = (rsrc) => {
    return rsrc.map((item) => (
      <TreeItem
        showActionButton={true}
        onClickActionButton={() => handleGrantPermission(item)}
        caption='Database'
        actionButtonVariant='grayBlue'
        actionButtonText='Grant Privileges'
        labelText={item.Spec.DisplayName}
        nodeId={`database+${item.ObjectMeta.ID}`}
      >
        {renderSchema(item)}
      </TreeItem>
    ))
  }

  const renderSchema = (rsrc) => {
    const filterParentObj = schemaObject.filter(
      (item) => item.Spec.Parent.RefID === rsrc.ObjectMeta.ID
    )
    return filterParentObj.map((item) => (
      <TreeItem
        labelIcon={<FontAwesomeIcon icon={faProjectDiagram} />}
        labelText={item.Spec.DisplayName}
        nodeId={`schema+${item.ObjectMeta.ID}`}
        showActionButton={true}
        onClickActionButton={() => handleGrantPermission(item)}
        caption='Schema'
        actionButtonVariant='grayBlue'
        actionButtonText='Grant Privileges'
      >
        {renderTable(item)}
      </TreeItem>
    ))
  }

  const renderTable = (rsrc) => {
    const filterParentObj = tableObject.filter(
      (item) => item.Spec.Parent.RefID === rsrc.ObjectMeta.ID
    )

    return filterParentObj.map((item) => (
      <TreeItem
        labelIcon={<FontAwesomeIcon icon={faTable} />}
        labelText={item.Spec.DisplayName}
        nodeId={`table+${item.ObjectMeta.ID}`}
        showActionButton={true}
        onClickActionButton={() => handleGrantPermission(item)}
        caption='Table'
        actionButtonVariant='grayBlue'
        actionButtonText='Grant Privileges'
      />
    ))
  }

  const handleGrantPermission = (item) => {
    setShowGrantModal(true)
    setSelectedRsrc(item)
    const actionList = snowFlakeCloudActions.find((action) => action.Service === item.Spec.Type)
    const menuList = actionList?.Actions?.Elems?.map((e) => ({ label: e, value: e }))
    setActionsList(menuList)
  }

  const handleRoleChange = (name) => {
    setSelectedRole(name)
  }

  const handleRolePermission = async () => {
    setIsLoading(true)
    const selectedRoleObj = filterRoles.find((role) => role.Spec.Name === selectedRole)
    const selectedRsrcId = selectedRsrc?.ObjectMeta?.ID

    if (selectedRoleObj && selectedRsrcId) {
      const updatedPrivilegeMap = { ...selectedRoleObj.Spec.GrantPrivMap.PrivilegeMap }

      updatedPrivilegeMap[selectedRsrcId] = {
        Elems: selectedPermission
      }

      const updatedRoleObj = {
        ...selectedRoleObj,
        Spec: {
          ...selectedRoleObj.Spec,
          GrantPrivMap: {
            ...selectedRoleObj.Spec.GrantPrivMap,
            PrivilegeMap: updatedPrivilegeMap
          }
        }
      }

      try {
        const response = await reduxApiClient('snowflakeroles').update(updatedRoleObj)
        setTimeout(async () => {
          await reduxApiClient('snowflakeroles').getAll({})
          const roleStatus = slices.snowFlakeRoles.find(
            (role) => role.Spec.Name === response.Spec.Name
          )

          if (roleStatus.Status.Status.Status === 'Fail') {
            setError(roleStatus.Status.Status.Error)
          } else {
            setShowGrantModal(false)
            setSelectedRole('')
            setSelectedPermission([])
            enqueueNotification('Successfully privileges are assinged to roles!', 'info')
          }
          setIsLoading(false)
        }, 3000)
      } catch (error) {
        enqueueNotification(error, 'error')
        console.log(error)
      }
    }
  }

  const handleRolePermissionCancel = () => {
    setShowGrantModal(false)
    setSelectedRole('')
    setSelectedPermission([])
  }

  return (
    <>
      <TreeView
        defaultExpanded={['1']}
        height={'auto'}
        maxWidth={800}
        onNodeFocus={function noRefCheck() {}}
        onNodeSelect={function noRefCheck() {}}
        onNodeToggle={function noRefCheck() {}}
        sx={{
          flexGrow: 1,
          overflowY: 'auto'
        }}
      >
        <TreeItem
          labelIcon={<FontAwesomeIcon icon={faWarehouse} />}
          labelText='WAREHOUSE'
          nodeId='1'
        >
          {warehouseList(wareHouseObject)}
        </TreeItem>
      </TreeView>

      <TreeView
        defaultExpanded={['1']}
        height={'100%'}
        maxWidth={800}
        onNodeFocus={function noRefCheck() {}}
        onNodeSelect={function noRefCheck() {}}
        onNodeToggle={function noRefCheck() {}}
        sx={{
          flexGrow: 1,
          overflowY: 'auto'
        }}
      >
        <TreeItem labelIcon={<FontAwesomeIcon icon={faDatabase} />} labelText='DATABASE' nodeId='2'>
          {databaseList(dataBaseObject)}
        </TreeItem>
      </TreeView>

      <FullScreenModal showModal={showGrantModal}>
        <div className='flex justify-center items-center h-[100%] bg-[#2229455a]'>
          <div className='w-[626px] rounded-lg p-8 bg-white'>
            <div className='flex justify-between items-center mb-[30px]'>
              <Typography variant='h3' className='text-[20px] m-0'>
                Grant Privileges To Roles
              </Typography>
            </div>

            <div>
              <SelectDropDown
                label='Please Select Roles'
                placeholder='Select Role'
                menuItems={rolesDropDownList}
                onChange={(e) => handleRoleChange(e.target.value)}
                value={selectedRole}
                style={{
                  width: '100%',
                  margin: '20px 0 20px 0'
                }}
              />

              <SelectDropDown
                label='Please Select permission'
                placeholder='Select Grant Type'
                menuItems={actionsList}
                onChange={(e) => setSelectedPermission(e.target.value)}
                value={selectedPermission}
                multiple={true}
                style={{
                  width: '100%',
                  margin: '20px 0 20px 0'
                }}
              />
            </div>

            <div className='mt-3'>
              <p className='text-[red]'>{error}</p>
            </div>
            <div className='flex mt-5 flex-row-reverse gap-5'>
              <Button variant='primary' size='md' onClick={() => handleRolePermission()}>
                Create
              </Button>
              <Button variant='gray' size='md' onClick={() => handleRolePermissionCancel()}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </FullScreenModal>
      <LoadingFeedback
        caption='Please wait..'
        loading={isLoading}
        message='Assigning Privileges To Role'
      />
    </>
  )
}

export { ResourcesTab }
