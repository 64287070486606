import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faHelmetSafety } from '@fortawesome/pro-light-svg-icons'
import {
  faChartNetwork,
  faCircleNodes,
  faCloud,
  faCodePullRequestClosed,
  faDatabase,
  faFileCertificate,
  faGauge,
  faKey,
  faLaptopMobile,
  faLayerGroup,
  faListCheck,
  faServer,
  faUsers
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBrowser,
  faCubes,
  faGearComplexCode,
  faGrid,
  faIdCard,
  faSignalStrong,
  faWavePulse
} from '@fortawesome/pro-solid-svg-icons'

export const getAdminViewSidebarItems = ({ history }) => [
  {
    icon: faListCheck,
    label: 'Getting started',
    variant: 'primary',
    onClick() {
      history.push('/admin/getting-started')
    },
    _meta: {
      itemActiveRoutes: '/admin/getting-started'
    }
  },
  {
    icon: faGauge,
    label: 'dashboard',
    selected: true,
    variant: 'primary',
    onClick() {
      history.push('/admin/dashboard')
    },
    _meta: {
      itemActiveRoutes: '/admin/dashboard'
    }
  },
  {
    itemType: 'section',
    label: 'directory'
  },
  {
    icon: faUser,
    label: 'Users',
    variant: 'primary',
    onClick() {
      history.push('/admin/users')
    },
    _meta: {
      itemActiveRoutes: '/admin/users'
    }
  },
  {
    icon: faUsers,
    label: 'Groups',
    variant: 'primary',
    onClick() {
      history.push('/admin/groups')
    },
    _meta: {
      itemActiveRoutes: '/admin/groups'
    }
  },
  {
    icon: faKey,
    label: 'Service Accounts',
    variant: 'primary',
    onClick() {
      history.push('/admin/service-accounts/aws')
    },
    _meta: {
      itemActiveRoutes: '/admin/service-accounts/aws'
    }
  },
  {
    icon: faGearComplexCode,
    label: 'Workloads',
    variant: 'primary',
    onClick() {
      history.push('/admin/workloads')
    },
    _meta: {
      itemActiveRoutes: '/admin/workloads'
    }
  },
  {
    icon: faLaptopMobile,
    label: 'devices',
    variant: 'primary',
    onClick() {
      history.push('/admin/devices/approved')
    },
    _meta: {
      itemActiveRoutes: '/admin/devices'
    }
  },
  {
    icon: faIdCard,
    label: 'identity providers',
    variant: 'primary',
    onClick() {
      history.push('/admin/id-provider')
    },
    _meta: {
      itemActiveRoutes: '/admin/id-provider'
    }
  },
  {
    itemType: 'section',
    label: 'cloud'
  },
  {
    icon: faCloud,
    label: 'clouds',
    variant: 'primary',
    onClick() {
      history.push('/admin/clouds')
    },
    _meta: {
      itemActiveRoutes: '/admin/clouds'
    }
  },
  {
    icon: faCircleNodes,
    label: 'gateways',
    variant: 'primary',
    onClick() {
      history.push('/admin/gateways')
    },
    _meta: {
      itemActiveRoutes: '/admin/gateways'
    }
  },
  {
    icon: faLayerGroup,
    label: 'Targets',
    variant: 'primary',
    onClick() {
      history.push('/admin/targets')
    },
    _meta: {
      itemActiveRoutes: '/admin/targets'
    }
  },
  {
    icon: faBrowser,
    label: 'Resources',
    variant: 'primary',
    onClick() {
      history.push('/admin/resources')
    },
    _meta: {
      itemActiveRoutes: '/admin/resources'
    }
  },
  {
    icon: faGrid,
    label: 'applications',
    variant: 'primary',
    onClick() {
      history.push('/admin/applications')
    },
    _meta: {
      itemActiveRoutes: '/admin/applications'
    }
  },
  {
    icon: faDatabase,
    label: 'Data Cloud',
    variant: 'primary',
    onClick() {
      history.push('/admin/datacloud')
    },
    _meta: {
      itemActiveRoutes: '/admin/datacloud'
    }
  },
  {
    itemType: 'section',
    label: 'Access Management'
  },
  {
    icon: faHelmetSafety,
    label: 'IAM Roles',
    variant: 'primary',
    onClick() {
      history.push('/admin/iam-roles/aws')
    },
    _meta: {
      itemActiveRoutes: '/admin/iam-roles'
    }
  },
  {
    icon: faCodePullRequestClosed,
    label: 'Access Requests',
    variant: 'primary',
    onClick() {
      history.push('/admin/access-requests')
    },
    _meta: {
      itemActiveRoutes: '/admin/access-requests'
    }
  },
  {
    icon: faFileCertificate,
    label: 'Policies',
    variant: 'primary',
    onClick() {
      history.push('/admin/policys')
    },
    _meta: {
      itemActiveRoutes: '/admin/policys'
    }
  },
  {
    icon: faCubes,
    label: 'Bundles',
    variant: 'primary',
    onClick() {
      history.push('/admin/bundles')
    },
    _meta: {
      itemActiveRoutes: '/admin/bundles'
    }
  },
  {
    itemType: 'section',
    label: 'Activity'
  },
  {
    icon: faWavePulse,
    label: 'activity logs',
    variant: 'primary',
    onClick() {
      history.push('/admin/activity-logs')
    },
    _meta: {
      itemActiveRoutes: '/admin/activity-logs'
    }
  },
  {
    icon: faSignalStrong,
    label: 'Session Logs',
    variant: 'primary',
    onClick() {
      history.push('/admin/session-logs')
    },
    _meta: {
      itemActiveRoutes: '/admin/session-logs'
    }
  },
  {
    itemType: 'section',
    label: 'Settings'
  },
  {
    icon: faChartNetwork,
    label: 'integrations',
    variant: 'primary',
    onClick() {
      history.push('/admin/integrations')
    },
    _meta: {
      itemActiveRoutes: '/admin/integrations'
    }
  },
  {
    icon: faServer,
    label: 'AD Domain Controllers',
    variant: 'primary',
    onClick() {
      history.push('/admin/addomaincontrollers')
    },
    _meta: {
      itemActiveRoutes: '/admin/addomaincontrollers'
    }
  }
]
