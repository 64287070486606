import {
  faComputerSpeaker,
  faDesktop,
  faMobile,
  faSquareInfo
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSearchQuery } from 'Core/Hooks/useSearchQuery'
import { getDeviceType, useActivityLogParser } from 'features/activityLogs'
import { getResourceName, getRsrcIcon } from 'features/resources'
import moment from 'moment'
import { Label, TargetIcon, Typography } from 'procyon-ui'
import React from 'react'

const DeviceTypeToIconMap = {
  DESKTOP: faDesktop,
  MOBILE: faMobile,
  OTHERS: faComputerSpeaker
}

const IDTYPE_MAP = {
  SAML: 'SAML_APPLICATION',
  OKTA: 'OKTA',
  OIDC: 'OIDC',
  ENTRA: 'Entra',
  AUTH0: 'Auth0',
  ONELOGIN: 'OneLogin',
  PING: 'Ping'
}

export const useActivityLogs = ({ hits = [], searchKey = '' }) => {
  const { getParsedLogData } = useActivityLogParser()

  const { applySearchQuery } = useSearchQuery({
    queryKey: searchKey,
    defaultQueryFunction: (row, queryKey) => {
      //rowdata
      if (!queryKey) return true
      const { userName, device, eventMessage, resources } = row
      const rsrcNameCombined = resources.reduce((prev, curr) => {
        return `${curr.Name}${prev}`
      }, '')
      const all =
        `${userName}${rsrcNameCombined}${device.name}${device.type}${eventMessage}`.toLowerCase()
      return all.includes(queryKey)
    }
  })

  const getRowData = () => {
    const parsedHits = getParsedLogData(hits)
    const rowdata = parsedHits.map(
      ({
        _id,
        _index,
        eventTime,
        userName,
        user,
        device,
        deviceName,
        message,
        resources,
        resourceKind
      }) => {
        const rsrcs = []

        resources.forEach((rsrc) => {
          let Icon = null
          if (rsrc.rsrc) {
            Icon = getRsrcIcon(rsrc.rsrc)
          } else Icon = getRsrcIcon(rsrc.Kind)
          rsrcs.push({
            Icon,
            name: rsrc.Name,
            key: rsrc.key
          })
        })

        return {
          id: _id,
          _index,
          eventTime,
          userName: user ? getResourceName(user) : userName,
          device: device
            ? {
                name: getResourceName(device),
                type: getDeviceType(device)
              }
            : {
                name: deviceName,
                type: 'OTHERS'
              },
          eventMessage: message,
          resources: rsrcs,
          resourceKind
        }
      }
    )
    return applySearchQuery(rowdata)
  }

  return { getRowData }
}

/**
 @returns {import('procyon-ui').ResponsiveTableProps['columns']}
 */
export const columns = () => [
  {
    dataIndex: 'eventTime',
    title: 'Event Time',
    width: 250,
    align: 'left',
    fixed: 'left',
    sorter: (v1, v2) => new Date(v1.eventTime).getTime() - new Date(v2.eventTime).getTime(),
    render(value) {
      return (
        <div className='flex gap-2 items-center'>
          <FontAwesomeIcon color='#3267D6' icon={faSquareInfo} />
          <Typography className='!text-[#3267D6]' variant='body-regular'>
            {moment(value).format('MMMM Do YYYY, h:mm A')}
          </Typography>
        </div>
      )
    }
  },
  {
    dataIndex: 'userName',
    title: 'User',
    width: 200,
    align: 'left',
    sorter: (v1, v2) => v1.userName.toLowerCase().localeCompare(v2.userName.toLowerCase())
  },
  {
    dataIndex: 'device',
    title: 'Device',
    width: 300,
    sorter: (v1, v2) => {
      if (typeof v1.device.name !== 'string' || typeof v2.device.name !== 'string') return 0
      return v1.device.name.localeCompare(v2.device.name)
    },
    render(value) {
      let Icon = null

      if (!value.name) return <Label text='No Device' />
      if (value.type === 'OTHERS') {
        const idType = value.name.split(' ')[0]
        if (idType && IDTYPE_MAP[idType.toUpperCase()]) {
          Icon = <TargetIcon type={IDTYPE_MAP[idType.toUpperCase()]} />
        }
      }

      return (
        <div className='flex gap-2 items-center'>
          {Icon ? Icon : <FontAwesomeIcon color='#A6ABB6' icon={DeviceTypeToIconMap[value.type]} />}
          <Typography variant='body-regular'>{value.name}</Typography>
        </div>
      )
    }
  },
  {
    dataIndex: 'eventMessage',
    title: 'Event Message',
    width: 400,
    align: 'left',
  },
  {
    dataIndex: 'resources',
    title: 'Resource',
    width: 400,
    sorter: (v1, v2) => {
      const r1 = v1.resources[0]
      const r2 = v2.resources[0]
      if (!r1) return -1
      if (!r2) return 1
      return r1.name.toLowerCase().localeCompare(r2.name.toLowerCase())
    },
    render(value) {
      const rsrc = value[0]
      if (!rsrc) return <Label text='No Resources' />
      const { name, Icon } = rsrc
      return (
        <div className='flex gap-2 items-center'>
          {Icon && <Icon />}
          <Typography variant='body-regular'>
            {name.length > 40 ? `${name.substring(0, 40)}...` : name}
          </Typography>
        </div>
      )
    }
  }
]

/**
 *
 * @returns {import("procyon-ui").DataTableProps['initialState']}
 */
export const getTableInitialState = () => ({
  sorting: {
    sortModel: [{ field: 'eventTime', sort: 'desc' }]
  },
  pagination: {
    paginationModel: {
      pageSize: 20
    }
  }
})
