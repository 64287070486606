import { faTag } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { createRsrcKey, getResourceName } from 'features/resources'
import { Button, SimpleDropDown, TagRow, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { ApproverInfo } from './components/ApproverInfo'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { ApproverPolicyCreateModal } from './components/ApproverPolicyCreateModal'
import { DeleteApproverModal } from './components/DeleteApproverPolicyModal'
import _ from 'lodash'

function ApproverPolicy() {
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false)
  const [selectedTag, setSelectedTag] = useState(null)
  const {slices, selectDispatch} = useMultiSlice(['approvers'])
  const [tagRowData, setTagRowData] = useState([]);
  const [policyLength, setPolicyLength] = useState(0);

  useEffect(() => {
    selectDispatch(['approvers', 'accountList',])
    generatePolicyTagRow()
  }, [])

  const {
    slices: { approvers, accountList }
  } = useMultiSlice(['approvers', 'accountList'])

  const handleDeleteSuccess = () => {
    setSelectedTag(null)
    setShowDeleteTagModal(false)
  }

  const generatePolicyTagRow = () => {
    let tagRowData = []
    let accountID = accountList.map(acc => acc.ObjectMeta.ID);

    approvers.forEach(item => {
      if (item.SearchType !== 'account') {
        tagRowData.push(item);
      } else if (accountID.includes(item?.SearchRef?.RefID)) {
        tagRowData.push(item);
      }
    });  

    if(tagRowData.length === 0) {
      setSelectedTag(null)
    } else {
      setSelectedTag(tagRowData[0])
    }

    setTagRowData(tagRowData);
    setPolicyLength(tagRowData.length);
  }


  return (
    <div className='flex gap-4'>
      <div className='w-1/2 border-r border[#D8DDE4] pr-8'>
        <div className='flex justify-between'>
          <Typography variant='h4-regular'>Select Policy</Typography>
          <Button onClick={() => setShowCreateModal(true)} icon={faPlus} variant='primary'>
            Add New
          </Button>
        </div>
        <div className='flex justify-between mt-7 mb-5'>
        <Typography variant='body-regular'>{policyLength} Tags</Typography>
          
        </div>

        <div style={{overflow:'auto', height: '100vh'}}>
          {tagRowData?.map((e) => {
            const tags = Object.keys(e.KindMap.Map)
            return (
              <div className='mb-4'>
                <TagRow
                  key={createRsrcKey(e)}
                  customTagsAmount={0}
                  icon={<FontAwesomeIcon color='#545B71' icon={faTag} size='lg' />}
                  onClick={() => setSelectedTag(e)}
                  tags={tags}
                  title={getResourceName(e)}
                />
              </div>
            )
          })}
        </div>
      </div>
      <div className='w-1/2 h-[600px]'>
        {!selectedTag && (
          <div className='bg-[#F9FBFC] h-[100%] flex justify-center items-center flex-col'>
            <FontAwesomeIcon color='#545B71' icon={faTag} size='5x' />
            <Typography className='mt-6' variant='body-regular'>
              Select Policy to display information
            </Typography>
          </div>
        )}
        {selectedTag && (
          <ApproverInfo
            onDelete={() => {
              setShowDeleteTagModal(true)
            }}
            tag={selectedTag}
          />
        )}
        {showCreateModal && (
          <ApproverPolicyCreateModal
            onCancel={() => setShowCreateModal(false)}
            onSuccess={() => setShowCreateModal(false)}
          />
        )}
        {showDeleteTagModal && (
          <DeleteApproverModal
            tag={selectedTag}
            onCancel={() => setShowDeleteTagModal(false)}
            onSuccess={() => handleDeleteSuccess()}
          />
        )}
      </div>
    </div>
  )
}

export { ApproverPolicy }
