import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useURLQuery } from 'Core/Hooks/useURLQuery'
import { useUser } from 'Core/Hooks/useUser'
import { enqueueNotification } from 'Utils/Helpers'
import { openURLExternally } from 'Utils/PureHelperFuctions'
import { FullScreenContentModal } from 'V2Components'
import {
  CreatePolicyFlow,
  IAMResourceDetails,
  NamespacePrompt,
  ResourceKindWithAccessLink,
  ResourcesList,
  useIAMRsrcAccess
} from 'features/iamResources'
import {
  getPolicyIssuedToEntities,
  getPolicyResourcesRefs,
  isPolicyIssuedToUser,
  isWorkloadIdentityPolicy
} from 'features/policy'

import {
  AccessCartProvider,
  ResourceDetailsProvider,
  ResourceDetailsView,
  RsrcAccessSummaryView,
  TagsAndLabelsView,
  createRef,
  createRsrcKey,
  reverseRsrcKey,
  useAccessCartProvider,
  useResourceDetails
} from 'features/resources'
import { getAppRoleSignInURL } from 'features/targets'
import { Button, DropDownButton } from 'procyon-ui'
import { DataDetailsProvider } from 'providers/DataDetailsProvider'
import React, { useEffect, useMemo, useState } from 'react'

const CART_KEY = 'adminResources'
const WORKLOAD_CART_KEY = 'workloadPolicy'

function AdminIAMResourceDetails() {
  const [namespacePrompt, setNamespacePrompt] = useState(false)
  const [selectedRsrc, setSelectedRsrc] = useState(null)
  const { rsrc, account } = useResourceDetails()
  const { isResourceInCart } = useAccessCartProvider()
  const [activeCloudType, setActiveCloudType] = useState('')
  const { user } = useUser()
  const [views, setViews] = useState({
    showAccessRequestSubmission: false,
    showCreateIAMRole: false,
    showIAMRolesSelection: false,
    showUserGroupSelection: false
  })
  const { getPolicySignInURLS } = useIAMRsrcAccess()
  const signInUrlsMap = getPolicySignInURLS(user)
  const { getObjectRef, slices } = useMultiSlice([
    'policyList',
    'userList',
    'groupList',
    'serviceAccounts',
    'appRolesList',
    'kafkas',
    'workloads',
    'awsResources',
    'approvalReqList'
  ])
  const entityKey = useURLQuery().get('entity')
  const map = getPolicySignInURLS(user, true)

  const getWorkloadPolicys = () => {
    const validPolicys = []
    const policys = slices.policyList.filter(isWorkloadIdentityPolicy)
    policys.map((p) => {
      const rsrcs = getPolicyResourcesRefs(p).map(createRsrcKey)
      if (rsrcs.includes(createRsrcKey(rsrc))) validPolicys.push(p)
    })

    return validPolicys
  }

  const getWorkloadPolicyEntities = () => {
    const map = {}
    const policys = getWorkloadPolicys()
    policys.map((p) => {
      const entities = getPolicyIssuedToEntities(p)
      entities.forEach((e) => {
        map[createRsrcKey(e)] = true
      })
    })

    return getObjectRef(Object.keys(map).map(reverseRsrcKey))
  }

  const getResourceAttachedPolicys = () => {
    const policyKeysMap = {}
    if (!rsrc) return []

    const signInObj = map[createRsrcKey(rsrc)]
    signInObj?.policys?.map((e) => {
      policyKeysMap[createRsrcKey(e.ref)] = true
    })

    return getObjectRef(Object.keys(policyKeysMap).map(reverseRsrcKey))
  }

  const getAttachedPolicys = () => {
    const policyKeysMap = {}
    if (!rsrc) return []
    const rsrcPolicys = map[createRsrcKey(rsrc)]

    rsrcPolicys?.policys.map((e) => {
      policyKeysMap[createRsrcKey(e.ref)] = true
    })

    return getObjectRef(Object.keys(policyKeysMap).map(reverseRsrcKey))
  }

  /**
   * get ecs subtype resources
   * @returns any[]
   */
  const getECSSubTypesRsrc = () => {
    const filterECSSubType = slices?.awsResources?.filter(
      (item) => item.Spec.Parent.RefID === rsrc.ObjectMeta.ID
    )
    return filterECSSubType
  }

  /**
   *
   * @returns { any[]} List of entities who have access to this resource
   */
  const getRsrcAccessEntities = () => {
    const pList = getAttachedPolicys()
    if (!rsrc) return []
    const tempMap = {}
    const refObjects = []
    pList.forEach((p) => {
      p.IssuedTo.ObjectRef.forEach((ref) => {
        const key = `${ref.RefKind}+${ref.RefID}`
        if (!tempMap[key]) {
          const refObject = getObjectRef(ref)
          if (refObject) {
            refObjects.push(refObject)
            tempMap[key] = true
          }
        }
      })
    })
    return refObjects
  }

  const rsrcAccessEntities = getRsrcAccessEntities()
  const rsrcAttachedPolicys = getResourceAttachedPolicys()

  const copyProfileName = (ProfileName) => {
    try {
      if (!ProfileName)
        return enqueueNotification('Unable to find ProfileName for this role.', 'error')
      navigator.clipboard.writeText(ProfileName)
      enqueueNotification('Profile Name  copied : ' + ProfileName, 'info')
    } catch (error) {
      console.error(error)
    }
  }

  const Actions = () => {
    const isRsrcInCart = isResourceInCart(createRef(rsrc))
    const handleAppRoleSignIn = (approle) => {
      const signInUrl = getAppRoleSignInURL(approle)
      openURLExternally(signInUrl)
    }

    const userPolicys = rsrcAttachedPolicys.filter((p) => isPolicyIssuedToUser(p, user))

    const getSignInSubMenu = () => {
      const items = []
      userPolicys.map((policy) => {
        // Add menu item for signing in
        items.push({
          action: () => {
            const approle = getObjectRef(policy.Spec.ActionMap.AssumeRole.PolicyRule[0]?.ObjectRef)
            handleAppRoleSignIn(approle)
          },
          title: policy.GivenName
        })

        // Add menu items for copying profile names
        if (rsrc.ObjectMeta.Kind === 'AwsResource') {
          const rsrcKey = createRsrcKey(createRef(rsrc))
          const policyData = signInUrlsMap[rsrcKey].policys.find(
            (e) => createRsrcKey(e.ref) === createRsrcKey(policy)
          )
          if (!policyData) return
          const approle = getObjectRef({ RefKind: 'AppRole', RefID: policyData.AppRoleID })
          if (approle) {
            items.push({
              action: () => copyProfileName(approle.ObjectMeta.Name),
              title: `${policyData.name} - Copy Profile Name`
            })
          }
        }
      })

      return items
    }

    const isNamespace = rsrc?.ObjectMeta?.Kind === 'KubeNamespace'

    return (
      <>
        {userPolicys.length > 0 && !ResourceKindWithAccessLink[rsrc.ObjectMeta.Kind] && (
          <DropDownButton
            style={{
              width: 'max-content'
            }}
            menuItems={[
              {
                action: null,
                title: 'Sign In',
                submenu: getSignInSubMenu()
              }
            ]}
            variant='grayBlue'
          />
        )}
        {(ResourceKindWithAccessLink[rsrc.ObjectMeta.Kind] || isNamespace) &&
          userPolicys.length > 0 && (
            <Button
              size='md'
              onClick={() => {
                if (isNamespace) {
                  setNamespacePrompt(rsrc.Spec.Type)
                  return
                }
                openURLExternally(rsrc.Status.AccessLink)
              }}
              key='signin'
              variant='grayBlue'
            >
              Sign In
            </Button>
          )}
        <Button
          onClick={() => {
            setSelectedRsrc({ ...createRef(rsrc), type: account.Spec?.Type })
            setViews((s) => ({ ...s, showIAMRolesSelection: true }))
          }}
          size='md'
          key='request'
          variant='grayBlue'
        >
          {isRsrcInCart ? 'Edit Selected Roles' : 'Create Policy'}
        </Button>
      </>
    )
  }

  if (account && !activeCloudType) setActiveCloudType(account.Spec.Type)

  return (
    <div>
      <ResourceDetailsView catalogPage='resources' HeaderActions={<Actions />}>
        <IAMResourceDetails
          tabs={[
            ...(rsrc.Spec.Type === 'AWS_ECS'
              ? [
                  {
                    label: 'Resources',
                    tabContent: <ResourcesList rsrc={rsrc} ECSSubTypesRsrc={getECSSubTypesRsrc()} />
                  }
                ]
              : []),
            {
              label: 'Access Summary',
              tabContent: (
                <RsrcAccessSummaryView
                  attachedPolicys={[...rsrcAttachedPolicys, ...getWorkloadPolicys()]}
                  rsrcAccessEntities={[...rsrcAccessEntities, ...getWorkloadPolicyEntities()]}
                />
              )
            },
            {
              label: 'Tags and Labels',
              tabContent: <TagsAndLabelsView rsrc={rsrc} />
            }
          ]}
        />
      </ResourceDetailsView>

      <DataDetailsProvider sliceName='workloads' routeKey='entity' skip={!entityKey} urlSearchQuery>
        <CreatePolicyFlow
          enableUserGroupSelection={entityKey ? false : true}
          serviceAccountType={activeCloudType}
          selectedRsrc={selectedRsrc}
          setSelectedRsrc={setSelectedRsrc}
          views={views}
          setViews={setViews}
        />
      </DataDetailsProvider>
      {namespacePrompt && (
        <FullScreenContentModal>
          <NamespacePrompt cloudType={namespacePrompt} onClose={() => setNamespacePrompt(false)} />
        </FullScreenContentModal>
      )}
    </div>
  )
}

const WrappedIAMAdminDetails = () => {
  const { slices, selectDispatch } = useMultiSlice([
    'awsResources',
    'azureResources',
    'gcpResources',
    'kubeNamespaces'
  ])
  const entityKey = useURLQuery().get('entity')
  const rsrcList = useMemo(
    () => [
      ...slices.awsResources,
      ...slices.azureResources,
      ...slices.gcpResources,
      ...slices.kubeNamespaces
    ],
    [slices]
  )

  useEffect(() => {
    selectDispatch([
      'awsResources',
      'gcpResources',
      'azureResources',
      'policyList',
      'userList',
      'groupList',
      'serviceAccounts',
      'kubeNamespaces',
      'appRolesList'
    ])
  }, [])

  return (
    <AccessCartProvider cartKey={entityKey ? WORKLOAD_CART_KEY : CART_KEY}>
      <ResourceDetailsProvider rsrcList={rsrcList} rsrcKindKey='kind' rsrcNameKey='resourceName'>
        <AdminIAMResourceDetails />
      </ResourceDetailsProvider>
    </AccessCartProvider>
  )
}

export { WrappedIAMAdminDetails as AdminIAMResourceDetails }
