import { faUsers } from '@fortawesome/free-solid-svg-icons'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { enqueueNotification, getUserInfo } from 'Utils/Helpers'
import { FullScreenContentModal, LoadingFeedback } from 'V2Components'
import { createRef } from 'features/resources'
import { ResourceKinds, customTagKeys } from 'features/settings'
import { pushToSlice, updateSliceData } from 'infra/redux/sliceHandlers'
import { Button, CloudIcon, Label, SelectDropDown, TextInput, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { SelectEntity } from '../SelectEntity'
import { createDataSelectorHook } from 'infra/redux'
import { reduxApiClient } from 'infra'

const useSlices = createDataSelectorHook(['accountList', 'userList', 'groupList'])

const ApproverPolicyCreateModal = ({ onCancel, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState('0')
  const { selectDispatch, getObjectRef } = useMultiSlice(['accountList', 'userList', 'groupList'])
  const { slices } = useSlices()

  const [selectedAccountRef, setSelectedAccountRef] = useState({})
  const [selectedAccountValue, setSelectedAccountValue] = useState('0')
  const [selectedResourceValue, setSelectedResourceValue] = useState([])
  const [showEntityModal, SetShowEntityModal] = useState(false)
  const [selectedEntities, setSelectedEntities] = useState([])
  const [keyTag, setKeyTag] = useState('')
  const [valueTag, setValueTag] = useState('')
  const [policyName, setPolicyName] = useState('')
  const [selectedEntity, setSelectedEntity] = useState([])
  const [isTagInputVisible, setIsTagInputVisible] = useState(false)
  const [selectedEntitiesList, setSelectedEntitiesList] = useState([])
  const user = getUserInfo()

  // useEffect(() => {
  //   selectDispatch(['accountList', 'userList', 'groupList'])
  // }, [])

  const userAndGroupEntity = () => {
    return [...slices.userList, ...slices.groupList]
  }

  const handleCustomTagKeyValueUpdate = (label, value) => {
    if (label === 'key') {
      setKeyTag(value)
    } else {
      setValueTag(value)
    }
  }

  const getErrors = () => {
    try {
      if (policyName == '') return 'Reader Name is required and special characters are not allowed.'
      if (selectedValue !== '1' && keyTag === '') return 'Please Enter tags'
      if (selectedValue === '1' && Object.keys(selectedAccountRef).length === 0)
        return 'Please Select any one Account'
      if (selectedResourceValue.length === 0) return 'Please Select any one Resources'
      if (selectedEntity.length === 0) return 'Please Select Reader'
    } catch (error) {
      return 'error'
    }
  }

  const errors = getErrors()

  const disableButton = () => {
    if (errors) return true
  }

  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const paylodObject = {
        ObjectMeta: {
          Name: policyName,
          NameSpace: user.org,
          Tenant: user.tenant
        },
        SearchString: keyTag + ':' + valueTag,
        KindMap: getResourcesMapObject(),
        Readers: { ObjectRef: selectedEntity }
      }

      if (selectedValue === '1') {
        delete paylodObject.SearchString
      } else {
        delete paylodObject.SearchRef
      }

      const data = await reduxApiClient('visibilitys').create(paylodObject)
      pushToSlice(data)
      enqueueNotification('Read created successfully!', 'info')
      onSuccess()
    } catch (error) {
      if (error.response) {
        console.log(error.response)
      }
      console.log(error)
      enqueueNotification('Failed to create Read!', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const accountDropdownOptions = [
    {
      label: 'Please Select',
      value: '0',
      cloudType: ''
    },
    ...(slices?.accountList?.map((account, index) => ({
      label: (
        <>
          <CloudIcon
            type={account.Spec.Type?.toLowerCase()}
            style={{ marginRight: '10px', display: 'inline' }}
          />{' '}
          {account.ObjectMeta.Name}
        </>
      ),
      value: (index + 1).toString(), // Adjust the value to start from 1
      cloudType: account.Spec.Type
    })) || [])
  ]

  //Sort the menu items by cloud type in ascending order
  accountDropdownOptions.sort((a, b) => a.cloudType.localeCompare(b.cloudType))

  const getResourcesMapObject = () => {
    const updatedObject = {}
    for (const item of selectedResourceValue) {
      updatedObject[item] = 'true'
    }
    const finalUpdatedObject = {
      Map: updatedObject
    }
    return finalUpdatedObject
  }

  const handleEntityModalContinue = async () => {
    SetShowEntityModal(false)
    const ref_info_array = selectedEntities.map(createRef)
    const selectedEntitesList = getObjectRef(ref_info_array)
    setSelectedEntity(ref_info_array)
    setSelectedEntitiesList(selectedEntitesList)
  }

  const getFilterResources = () => {
    let filteredList = ResourceKinds.filter(
      (item) => item.kind !== 'MedusaNode' && item.kind !== 'Project'
    )
    let filteredListWithAccount
    if (selectedValue === '1') {
      filteredListWithAccount = filteredList.filter((item) => item.kind !== 'Application')
      return filteredListWithAccount.map((e) => ({ label: e.label, value: e.kind }))
    } else {
      return filteredList.map((e) => ({ label: e.label, value: e.kind }))
    }
  }

  const handleNameInput = (value) => {
    // Use a regular expression to replace any character that is not a letter or number with an empty string
    const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '')
    // Update the form state with the sanitized value
    setPolicyName(sanitizedValue)
  }

  return (
    <>
      <FullScreenContentModal>
        <div>
          <Typography className='mb-6' variant='h4-regular'>
            Create Policy
          </Typography>

          <TextInput
            value={policyName}
            onChange={(e) => handleNameInput(e.target.value)}
            sx={{ width: '100%', marginTop: '24px' }}
            label='Read Policy Name'
          />

          <div className=''>
            <div className='flex gap-4 mt-4 items-center'>
              <div>
                <Typography variant='body-regular' className='mb-1'>
                  Please Select Key
                </Typography>
                <SelectDropDown
                  sx={{ minWidth: '150px' }}
                  menuItems={customTagKeys.map((e) => ({ label: e.label, value: e.kind }))}
                  onChange={(e) => handleCustomTagKeyValueUpdate('key', e.target.value)}
                  value={keyTag}
                />
              </div>

              <TextInput
                label='Please Enter Value'
                value={valueTag}
                onChange={(e) => handleCustomTagKeyValueUpdate('value', e.target.value)}
                placeholder='Value'
                sx={{ width: '65%' }}
              />
            </div>
          </div>

          <SelectDropDown
            label='Please Select Resources Type'
            placeholder='Select Resources Type'
            menuItems={getFilterResources()}
            onChange={(e) => setSelectedResourceValue(e.target.value)}
            value={selectedResourceValue}
            multiple={true}
            style={{
              width: '100%',
              margin: '20px 0 20px 0'
            }}
          />

          <div className='flex gap-2 items-center mt-5 flex-wrap'>
            <Button
              icon={faUsers}
              iconColor='#A6ABB6'
              iconPosition='end'
              onClick={() => SetShowEntityModal(true)}
              size='sm'
              variant='grayBlue'
            >
              Add Reader
            </Button>

            {selectedEntitiesList?.map((user) => (
              <Label key={user.ObjectMeta.Name} text={user.ObjectMeta.Name} />
            ))}
          </div>

          <div className='flex justify-between items-center gap-4 mt-6'>
            {errors ? <Label variant='warning' text={errors} /> : <div></div>}
            <div className='flex gap-4'>
              <Button onClick={onCancel} variant='gray'>
                Cancel
              </Button>
              <Button onClick={handleSubmit} disabled={disableButton()} variant='primary'>
                Create
              </Button>
            </div>
          </div>
          <LoadingFeedback message='Creating Tag' loading={isLoading} caption='Please wait..' />
        </div>
      </FullScreenContentModal>

      {showEntityModal && (
        <FullScreenContentModal width='auto'>
          <SelectEntity
            showPermission={''}
            title={`Select Reader`}
            errorMessage={selectedEntities.length < 1 && 'Select At least one entity.'}
            entities={userAndGroupEntity()}
            selectedEntities={selectedEntities}
            setSelectedEntities={setSelectedEntities}
            onCancel={() => {
              SetShowEntityModal(false)
            }}
            onContinue={handleEntityModalContinue}
          />
        </FullScreenContentModal>
      )}
    </>
  )
}

export { ApproverPolicyCreateModal }
