import { faBroomWide, faPaste, faUpload } from '@fortawesome/pro-solid-svg-icons'
import { enqueueNotification } from 'Utils/Helpers'
import { JSONPrettyView, LabelContent } from 'V2Components'
import {
  FileUpload,
  GCP_API_REQUIRED_PERMS,
  GCP_CLOUD_ACCOUNT_FOLDER_PERMISSIONS,
  GCP_CLOUD_ACCOUNT_ORG_PERMISSIONS,
  GCP_CLOUD_ACCOUNT_PROJECT_PERMISSIONS,
  useAddAccountWizardContext
} from 'features/clouds'
import { IconButton, RadioInput, TargetIcon, TextInput, Typography } from 'procyon-ui'
import React from 'react'

/**
 * @returns
 */
const GCPAccountTypeStep = () => {
  const { gcpSpec, setGcpSpec } = useAddAccountWizardContext()

  return (
    <div>
      <Typography variant='body-regular'>
        Select the type of GCP Account you are going to add.
      </Typography>
      <div className='flex flex-col gap-2 mt-2'>
        <RadioInput
          checked={gcpSpec.accountType === 'ORG'}
          // @ts-ignore
          label={
            <div className='flex gap-1 items-center'>
              <TargetIcon type='GCP_ORG' />
              <Typography variant='body-regular'>Organization</Typography>
            </div>
          }
          onChange={() => setGcpSpec({ accountType: 'ORG' })}
          value='ORG'
        />
        <RadioInput
          checked={gcpSpec.accountType === 'FOLDER'}
          // @ts-ignore
          label={
            <div className='flex gap-1 items-center'>
              <TargetIcon type='GCP_GOOGLE_FOLDER' />
              <Typography variant='body-regular'>Folder</Typography>
            </div>
          }
          onChange={() => setGcpSpec({ accountType: 'FOLDER' })}
          value='folder'
        />
        <RadioInput
          checked={gcpSpec.accountType === 'PROJECT'}
          // @ts-ignore
          label={
            <div className='flex gap-1 items-center'>
              <TargetIcon type='GCP_GOOGLE_PROJECT' />
              <Typography variant='body-regular'>Project</Typography>
            </div>
          }
          onChange={() => setGcpSpec({ accountType: 'PROJECT' })}
          value='PROJECT'
        />
      </div>
    </div>
  )
}

const DomainRestrictionsStep = () => {
  return (
    <div>
      <Typography variant='body-regular'>
        Do you have any domain restrictions on your GCP Account? Confirm domain pgcp.cloud is
        allowed.
        <br />
        <br />
        Here is how to check it:
      </Typography>
      <Typography className='mt-2' variant='body-regular'>
        1. ...
        <br />
        2. ...
      </Typography>
    </div>
  )
}

const SAPermissionsStep = () => {
  const { gcpSpec } = useAddAccountWizardContext()

  const map = {
    ORG: GCP_CLOUD_ACCOUNT_ORG_PERMISSIONS,
    FOLDER: GCP_CLOUD_ACCOUNT_FOLDER_PERMISSIONS,
    PROJECT: GCP_CLOUD_ACCOUNT_PROJECT_PERMISSIONS
  }

  const permissions = map[gcpSpec.accountType]

  return (
    <div>
      <Typography variant='body-regular'>
        Use the following permission to create a new service account:
      </Typography>
      <div className='p-1 bg-gray-200 rounded my-2'>
        <Typography variant='bodySmall-regular'>
          {permissions.map((e) => (
            <>
              {e}
              <br />
            </>
          ))}
        </Typography>
      </div>
      {/* HIDDEN FOR NOW can be removed later */}
      {/* <Typography variant='body-regular'>
        Additionally, following API Permissions are also required at each project level you want to
        be scanned:
        <div className='p-1 bg-gray-200 rounded my-2'>
          <Typography variant='bodySmall-regular'>
            {GCP_API_REQUIRED_PERMS.map((e) => (
              <>
                {e}
                <br />
              </>
            ))}
          </Typography>
        </div>
      </Typography> */}
    </div>
  )
}

const AccountCredentialStep = () => {
  const {
    gcpSpec: { jsonCredentials },
    setGcpSpec
  } = useAddAccountWizardContext()

  const setJsonCredentials = (creds) => {
    setGcpSpec({ jsonCredentials: creds })
  }

  const handlePaste = async () => {
    try {
      const clipText = await navigator.clipboard.readText()
      setJsonCredentials(clipText)
    } catch (error) {
      enqueueNotification('Failed to paste from clipboard!', error)
    }
  }

  const safeCreds = (() => {
    if (!jsonCredentials) return {}
    try {
      return JSON.parse(jsonCredentials)
    } catch (error) {
      return { error: 'Invalid JSON:' + jsonCredentials }
    }
  })()

  return (
    <div>
      <div className='mb-4 relative'>
        <TextInput
          label='Upload the service account key file (.json) or enter it directly in below section (*required)'
          className='mt-3'
          multiline
          rows={10}
          sx={{ width: '100%' }}
          value={jsonCredentials}
          onChange={(e) => setJsonCredentials(e.target.value)}
        />
        <div className='absolute top-10 right-2 flex gap-2'>
          <FileUpload
            onFileRead={setJsonCredentials}
            Component={(props) => (
              <IconButton title='Upload' icon={faUpload} variant='gray' {...props} />
            )}
          />
          <IconButton title='Paste' onClick={handlePaste} icon={faPaste} variant='gray' />
          <IconButton
            disabled={!jsonCredentials}
            title='Clear'
            onClick={() => setJsonCredentials('')}
            icon={faBroomWide}
            variant='gray'
          />
        </div>
      </div>
      <JSONPrettyView data={safeCreds} />
    </div>
  )
}

const AccountSummary = ({}) => {
  const { org, name, description, gcpSpec, isEditMode } = useAddAccountWizardContext()

  const targetIconTypeMap = {
    FOLDER: 'GCP_GOOGLE_FOLDER',
    ORG: 'GCP_ORG',
    PROJECT: 'GCP_GOOGLE_PROJECT'
  }

  const labelMap = {
    FOLDER: 'Folder',
    ORG: 'Organization',
    PROJECT: 'Project'
  }

  const safeCreds = (() => {
    if (!gcpSpec.jsonCredentials) return {}
    try {
      return JSON.parse(gcpSpec.jsonCredentials)
    } catch (error) {
      return { error: 'Invalid JSON:' + gcpSpec.jsonCredentials }
    }
  })()

  return (
    <div className='w-[100%]'>
      <Typography variant='body-regular'>Summary of Account</Typography>
      <LabelContent title='Account Name' content={name} />
      <LabelContent title='Description' content={description} />
      <LabelContent
        title='Org'
        content={
          <div>
            <Typography variant='body-regular'>
              <strong>{org}</strong>
            </Typography>
            {!isEditMode && (
              <Typography variant='caption-regular'>
                The account will be added on this org.
              </Typography>
            )}
          </div>
        }
      />
      <LabelContent
        title='Account Type'
        content={
          <div className='flex gap-1 items-center'>
            {/* @ts-ignore */}
            <TargetIcon type={targetIconTypeMap[gcpSpec.accountType]} />
            <Typography variant='body-regular'>{labelMap[gcpSpec.accountType]}</Typography>
          </div>
        }
      />
      <Typography
        className='!text-[#A6ABB6] min-w-[193px] !font-semibold my-4'
        variant='body-semiBold'
      >
        SA Credentials
      </Typography>
      <JSONPrettyView data={safeCreds} autoHeight />
    </div>
  )
}

export const useGcpSteps = () => {
  const { isEditMode } = useAddAccountWizardContext()
  return [
    !isEditMode
      ? {
          label: 'Select Account Type',
          content: <GCPAccountTypeStep />
        }
      : null,
    // {
    //   label: 'Check Domain Restrictions',
    //   content: <DomainRestrictionsStep />
    // },
    {
      label: 'SA Permissions',
      content: <SAPermissionsStep />
    },
    {
      label: 'Credentials Setup',
      content: <AccountCredentialStep />
    },
    {
      label: 'Summary',
      content: <AccountSummary />
    }
  ].filter((e) => e)
}
