import { Category } from "@material-ui/icons"

export const AppNameKey                   =   'ObjectMeta.Name'
export const AppSSOURLKey                 =   'Spec.SamlServiceProvider.ServiceProviderACSURL'
export const AppBackendURLKey             =   'Spec.HttpAppConfig.Backends.Elems[0]'
export const AppFrontendURLKey            =   'Spec.HttpAppConfig.FrontEndName.Elems[0]'
export const AppMeshClusterKey            =   'Spec.HttpAppConfig.MeshCluster'
export const AppHTTPEntryPathKey          =   'Spec.HttpAppConfig.EntryPath'
export const AppServiceProviderMetadatKey =   'Spec.SamlServiceProvider.ServiceProviderMetadata'
export const AppAuthToken                 =   'Spec.GithubAppConfig.PersonalAccessToken'
export const AppInstanceUrlKey            =   'Spec.SalesforceAppConfig.InstanceURL'
export const AppClientIdKey               =   'Spec.SalesforceAppConfig.ClientID'
export const AppAuthorizedUserKey         =   'Spec.SalesforceAppConfig.AuthorizedUser'
export const AppPrivateKey                =   'Spec.SalesforceAppConfig.PrivateKey'
export const AppAccountID                 =   'Spec.SnowflakeAppConfig.AccountID'
export const AppUserName                  =   'Spec.SnowflakeAppConfig.UserName'
export const AppSnowFlakePrivateKey       =   'Spec.SnowflakeAppConfig.PrivateKey'

export const UIApplicationTypeIconMap = {
  AWS     :      'AWS_APPLICATION'        ,
  GCP     :      'GCP_APPLICATION'        ,
  AZURE   :      'AZURE_APPLICATION'      ,
  DROPBOX :      'DROPBOX_APPLICATION'    ,
  Genric  :      'GENERIC_APPLICATION'    ,
  HTTP    :      'REST_API'               ,
  GITHUB  :      'GITHUB_APPLICATION'     ,
  SALESFORCE:     'SALESFORCE'            ,
  SNOWFLAKE:      'SNOWFLAKE'             ,
}

/**
 * These application types cannot be edited or deleted
*/
//@prettier-ignore 
export const ReadOnlyAppTypes      =     {
  AWS             : true                   ,
  GCP             : true                   ,
  AZURE           : true                   ,
  'AWS-FEDERATION': true                   ,
}

// Port numbers for which the application frontend url port suffix is disabled
// 3001 - dev port
// When app is running in these port application frontend url port number will be ignored.
export const HIDDEN_APPLICATION_FRONTEND_URL_PORTS = ['80', '443', '3001']


export const AllApplicationData = [
{ 
  type: 'Web API (HTTP)',
  category: 'Network Protocal',
  caption: 'HTTP is the protocol for transmitting hypermedia documents on the web, operating on a client-server model over TCP/IP.',
},
{ 
  type: 'Dropbox',
  category: 'Cloud storage service',
  caption: 'GitHub is a web-based platform for version control and collaboration on software development projects using Git.',
},
{ 
  type: 'Github',
  category: 'Version control platform, Code hosting platform',
  caption: 'ServiceNow is a cloud-based platform that provides IT service management (ITSM) and business process automation.',
},
{ 
  type: 'Salesforce',
  category: 'Cloud-based CRM platform',
  caption: 'Salesforce offers cloud-based solutions for CRM, sales, service, marketing, commerce, analytics, community, and app development.',
},
{
  type: 'Snowflake',
  Category: 'cloud-based data warehousing solutions.',
  caption: 'Snowflake is a cloud data platform providing scalable data warehousing, integration, sharing, and analytics solutions across multiple clouds.',
}
]