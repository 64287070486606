import { Redirect, Route, Switch } from 'Core'
import React, { Suspense } from 'react'
import CheckFeatureFlag from 'Views/CheckFeatureFlag'

import FallBack from 'Components/Fallback'
import { IAMResourcesRoutes } from 'features/iamResources'
import DeviceTabs from 'Views/DeviceTabs'
import IAMActionDetailsWithContext from 'Views/IamActionsAndResources/IAMActionList/IAMActionDetails'
// Do no lazy load Dashboard because this is the landing page for admins
import { ApplicationRoutes } from 'features/applications'
import { ApprovalListRoutes } from 'features/approvals'
import { CloudsRoutes } from 'features/clouds'
import { TeamDetails } from 'features/github'
import { GroupsRoutes } from 'features/groups'
import {
  RedirectJiraSuccess,
  RedirectSlackSuccess,
  RedirectServiceNowSuccess,
  RedirectMSTeamSuccess
} from 'features/layout'
import { PolicyRoutes } from 'features/policy'
import { SessionRoutes } from 'features/sessions'
import { SettingsRoute } from 'features/settings'
import { TargetsRoutes } from 'features/targets'
import { UserRoutes } from 'features/users'
import DashBoard from 'Views/DashBoard'
import { WorkloadsRoutes } from 'features/workloads'
import { ActivityLogsRoute } from 'features/activityLogs'
import { IAMRolesRoutes } from 'features/iamRoles'
import { IntegrationsRoutes } from 'features/integrations'
import { ADDomainControllers } from 'features/targets'
import { BundlesRoutes } from 'features/bundles'
import { DataStoreRoutes } from 'features/snowflake'
import { CreateADD } from 'features/targets'

const SettingsPage = React.lazy(() => import('Views/SettingsPage'))
const IAMActionAndResources = React.lazy(() => import('Views/IamActionsAndResources'))
const Gateways = React.lazy(() => import('Views/Accounts/Gateways'))

const IdpDetails = React.lazy(() => import('Views/IdProvider/Components/IdpDetails'))
const DeviceDetails = React.lazy(() => import('Views/DeviceList/DeviceDetails'))
const IdProvider = React.lazy(() => import('Views/IdProvider'))
const ActivityLogist = React.lazy(() => import('Views/ActivityLogs/ActivityLogList'))
const NotFound = React.lazy(() => import('Views/NotFound'))
const CheckList = React.lazy(() => import('Views/GettingStarted'))
const Integrations = React.lazy(() => import('Views/Integrations'))
const ServiceAccounts = React.lazy(() => import('Views/ServiceAccounts'))
const SlackLogin = React.lazy(() => import('features/slackLogin'))

const AdminviewRoutes = () => {
  const view = 'admin'
  return (
    <Suspense fallback={<FallBack />}>
      <Switch>
        <Route exact path={[`/${view}/bundles`, `/${view}/bundles/*`]} component={BundlesRoutes} />

        <Route
          exact
          path={[`/${view}/iam-roles`, `/${view}/iam-roles/*`]}
          component={IAMRolesRoutes}
        />
        <Route
          exact
          path={[`/${view}/activity-logs`, `/${view}/activity-logs/*`]}
          component={ActivityLogsRoute}
        />
        <Route exact path={[`/${view}/targets`, `/${view}/targets/*`]} component={TargetsRoutes} />
        <Route
          exact
          path={[`/${view}/resources`, `/${view}/resources/*`]}
          component={IAMResourcesRoutes}
        />
        <Route exact path={[`/${view}/policys`, `/${view}/policys/*`]} component={PolicyRoutes} />
        <Route
          exact
          path={[`/${view}/applications`, `/${view}/applications/*`]}
          component={ApplicationRoutes}
        />
        <Route
          exact
          path={[`/${view}/datacloud`, `/${view}/datacloud/*`]}
          component={DataStoreRoutes}
        />
        <Route exact path={[`/${view}/users`, `/${view}/users/*`]} component={UserRoutes} />
        <Route exact path={[`/${view}/groups`, `/${view}/groups/*`]} component={GroupsRoutes} />
        <Route
          exact
          path={[`/${view}/access-requests`, `/${view}/access-requests/*`]}
          component={ApprovalListRoutes}
        />
        <Route
          exact
          path={[`/${view}/settings`, `/${view}/settings/*`, `/${view}/settings/:tabName`]}
          component={SettingsRoute}
        />
        <Route exact path={[`/${view}/clouds`, `/${view}/clouds/*`]} component={CloudsRoutes} />
        <Route
          exact
          path={[`/${view}/session-logs`, `/${view}/session-logs/*`]}
          component={SessionRoutes}
        />
        <Route
          exact
          path={[`/${view}/workloads`, `/${view}/workloads/*`]}
          component={WorkloadsRoutes}
        />

        <Route exact path={[`/${view}/addomaincontrollers`]} component={ADDomainControllers} />
        <Route
          exact
          path={[`/${view}/addomainControllers/:controllername`]}
          component={CreateADD}
        />
        <Route exact path={[`/${view}/iam-actions/:accountType`]}>
          <CheckFeatureFlag isRoute permission='showIamActions'>
            <IAMActionAndResources tab='iam-actions' />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/iam-policy/:accountType`]}>
          <CheckFeatureFlag isRoute permission='showIamActions'>
            <IAMActionAndResources tab='iam-policy' />
          </CheckFeatureFlag>
        </Route>

        <Route exact path={[`/${view}/account-resources/:accountType`]}>
          <CheckFeatureFlag isRoute permission='showIamActions'>
            <IAMActionAndResources tab='account-resources' />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/iam-action/:name`, `/${view}/iam-action/:name/:action`]}>
          <CheckFeatureFlag isRoute permission='showIamActions'>
            <IAMActionDetailsWithContext />
          </CheckFeatureFlag>
        </Route>

        <Route exact path={`/${view}/`}>
          <Redirect to={`/${view}/dashboard`} />
        </Route>

        <Route exact path={[`/${view}/`, `/${view}/dashboard`]}>
          <CheckFeatureFlag isRoute permission='showDashboardPage'>
            <DashBoard />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/getting-started`]}>
          <CheckFeatureFlag isRoute permission='showGettingStartedPage'>
            <CheckList />
          </CheckFeatureFlag>
        </Route>
        <Route
          exact
          path={[`/${view}/service-accounts`, `/${view}/service-accounts/:accountType/:action`]}
        >
          <CheckFeatureFlag isRoute permission='showServiceAccountsPage'>
            <ServiceAccounts />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/service-accounts`, `/${view}/service-accounts/:accountType`]}>
          <CheckFeatureFlag isRoute permission='showServiceAccountsPage'>
            <ServiceAccounts />
          </CheckFeatureFlag>
        </Route>

        <Route exact path={[`/${view}/gateways`, `/${view}/gateways/:action`]}>
          <CheckFeatureFlag isRoute permission='showAccoutsPage'>
            <Gateways />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/gateway/:gatewayId`]}>
          <CheckFeatureFlag isRoute permission='showAccoutsPage'>
            <Gateways />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/slack/:integrationName`]}>
          <CheckFeatureFlag isRoute permission='showIntegrationsPage'>
            <RedirectSlackSuccess />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/jira/:integrationName`]}>
          <CheckFeatureFlag isRoute permission='showIntegrationsPage'>
            <RedirectJiraSuccess />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/servicenow/:integrationName`]}>
          <CheckFeatureFlag isRoute permission='showIntegrationsPage'>
            <RedirectServiceNowSuccess />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/msteams/:integrationName`]}>
          <CheckFeatureFlag isRoute permission='showIntegrationsPage'>
            <RedirectMSTeamSuccess />
          </CheckFeatureFlag>
        </Route>
        {/* <Route
          exact
          path={[
            `/${view}/integrations`,
            `/${view}/integrations/:integrationName/:kind`,
            `/${view}/integrations/:integrationName/:kind/:model`
          ]}
        >
          <CheckFeatureFlag isRoute permission='showIntegrationsPage'>
            <Integrations />
          </CheckFeatureFlag>
        </Route> */}

        <Route exact path={`/${view}/idp-details/:id/:subPage`}>
          <CheckFeatureFlag isRoute permission='showIdpDetailsPage'>
            <IdpDetails />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={[`/${view}/id-provider`, `/${view}/id-provider/:action`]}>
          <CheckFeatureFlag isRoute permission='showIdpsPage'>
            <IdProvider />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={`/${view}/devices/approved`}>
          <CheckFeatureFlag isRoute permission='showDevicesPage'>
            <DeviceTabs tab='APPROVED' />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={`/${view}/devices/unapproved`}>
          <CheckFeatureFlag isRoute permission='showDevicesPage'>
            <DeviceTabs tab='UNAPPROVED' />
          </CheckFeatureFlag>
        </Route>
        <Route exact path={`/${view}/device/:id`}>
          <CheckFeatureFlag isRoute permission='showDeviceDetailsPage'>
            <DeviceDetails />
          </CheckFeatureFlag>
        </Route>

        <Route exact path={[`/${view}/logs`]}>
          <CheckFeatureFlag isRoute permission='showLogsPage'>
            <ActivityLogist />
          </CheckFeatureFlag>
        </Route>

        <Route exact path={`/${view}/old-settings`}>
          <CheckFeatureFlag isRoute permission='showSettingsPage'>
            <SettingsPage />
          </CheckFeatureFlag>
        </Route>

        <Route exact path={`/${view}/team-details/:teamName`}>
          <TeamDetails />
        </Route>

        <Route
          exact
          path={[`/${view}/integrations`, `/${view}/integrations/*`]}
          component={IntegrationsRoutes}
        />

        <Route
          exact
          path={[`/${view}/slack-login`, `/${view}/slack-login`]}
          component={SlackLogin}
        />
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Suspense>
  )
}

export default AdminviewRoutes
