import { cn } from 'Utils/Helpers'
import { Label, Typography } from 'procyon-ui'
import React from 'react'

function LabelContent({ title, content, showEmptyLabel = false, className = '' }) {
  return (
    <div className={cn('flex items-center border-[#D8DDE4] border-b py-2 min-h-[58px]', className)}>
      <Typography
        className='!text-[#A6ABB6] w-[200px] !break-all !font-semibold mr-2'
        variant='body-semiBold'
      >
        {title}
      </Typography>
      {!content && showEmptyLabel && <Label text={<em className='text-[#8d94a1]'>Empty</em>} variant='gray' />}
      {typeof content === 'string' ? (
        <Typography className='!break-all' variant='body-regular'>
          {content}
        </Typography>
      ) : (
        <div className='w-[auto]'>{content}</div>
      )}
    </div>
  )
}

export { LabelContent }
