import { FullScreenContentModal } from 'V2Components'
import { DataStoreTypeApps } from 'features/applications/utils/constants'
import { IntegrationCard } from 'procyon-ui'
import React, { useState } from 'react'
import { AddCloudStore } from '../AddCloudStore'

const AllCloudStores = () => {
  const [selectedType, setSelectedType] = useState('')
  const [showAddApplication, setShowAddApplication] = useState(false)

  const handleInfoModalClick = (type) => {
    setSelectedType(type)
  }

  const handleIntegrationModalClick = (type) => {
    setShowAddApplication(true)
    setSelectedType(type)
  }

  const getAppIcon = (type) => {
    switch (type) {
      case 'Snowflake':
        return 'SNOWFLAKE'
    }
  }

  return (
    <>
      <div className='grid gap-6 my-10 grid-cols-3'>
        {DataStoreTypeApps.map((item) => (
          <IntegrationCard
            caption={item.caption}
            iconType={getAppIcon(item.type)}
            onClickAuthorizeButton={function noRefCheck() {}}
            onClickInfoButton={() => handleInfoModalClick(item.type.toLowerCase())}
            onClickIntegrateButton={() => handleIntegrationModalClick(item.type.toUpperCase())}
            onClickManageIntegrationButton={function noRefCheck() {}}
            onClickRemoveButton={function noRefCheck() {}}
            showInfoButton={false}
            showIntegrateButton
            subtitle={item.category}
            title={item.type}
            width='100%'
            className='mr-10'
          />
        ))}

        {showAddApplication && (
          <FullScreenContentModal>
            <AddCloudStore
              type={selectedType}
              onComplete={() => setShowAddApplication(false)}
              onClose={() => setShowAddApplication(false)}
            />
          </FullScreenContentModal>
        )}
      </div>
    </>
  )
}

export { AllCloudStores }
