import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { openURLExternally } from 'Utils/PureHelperFuctions'
import { FullScreenContentModal } from 'V2Components'
import { useApprovalPolling } from 'features/approvals'
import {
  CreateRequestFlow,
  IAMResourceDetails,
  NamespacePrompt,
  ResourceKindWithAccessLink,
  ResourcesList
} from 'features/iamResources'
import { isPolicyIssuedToUser } from 'features/policy'
import {
  AccessCartProvider,
  ResourceDetailsProvider,
  ResourceDetailsView,
  TagsAndLabelsView,
  createRef,
  useAccessCartProvider,
  useResourceDetails
} from 'features/resources'
import { getAppRoleSignInURL } from 'features/targets'
import _ from 'lodash'
import { Button, DropDownButton } from 'procyon-ui'
import React, { useEffect, useMemo, useState } from 'react'
const CART_KEY = 'userResources'

function UserIAMResourceDetails() {
  const [namespacePrompt, setNamespacePrompt] = useState(false)
  const [selectedRsrc, setSelectedRsrc] = useState(null)
  const { rsrc, account } = useResourceDetails()
  const { user } = useUser()
  const [activeCloudType, setActiveCloudType] = useState('')
  const { isResourceInCart } = useAccessCartProvider()

  const [views, setViews] = useState({
    showAccessRequestSubmission: false,
    showCreateIAMRole: false,
    showIAMRolesSelection: false,
    showUserGroupSelection: false
  })

  const { slices, getObjectRef } = useMultiSlice([
    'policyList',
    'userList',
    'groupList',
    'serviceAccounts',
    'appRolesList',
    'awsResources'
  ])

  const getAttachedPolicys = () => {
    const pList = []
    if (!rsrc) return []
    slices.policyList.forEach((p) => {
      const { AssumeRole } = p.Spec.ActionMap
      if (!AssumeRole || !isPolicyIssuedToUser(p, user)) return
      if (AssumeRole.PolicyRule[0].Services?.ObjectRef?.length) {
        // This policy belongs to IAM Resources
        if (
          _.find(AssumeRole.PolicyRule[0].Services?.ObjectRef, {
            RefKind: rsrc.ObjectMeta.Kind,
            RefID: rsrc.ObjectMeta.ID
          })
        ) {
          pList.push(p)
        }
      }
    })
    return pList
  }
  const Actions = () => {
    const isRsrcInCart = isResourceInCart(createRef(rsrc))

    const handleAppRoleSignIn = (approle) => {
      const signInUrl = getAppRoleSignInURL(approle)
      openURLExternally(signInUrl)
    }

    const getSignInSubMenu = () => {
      return attachedPolicys.map((e) => ({
        action() {
          const approle = getObjectRef(e.Spec.ActionMap.AssumeRole.PolicyRule[0]?.ObjectRef)
          handleAppRoleSignIn(approle)
        },
        title: e.GivenName
      }))
    }

    const isNamespace = rsrc?.ObjectMeta?.Kind === 'KubeNamespace'

    return (
      <>
        {!ResourceKindWithAccessLink[rsrc.ObjectMeta.Kind] &&
          !isNamespace &&
          attachedPolicys.length > 0 && (
            <DropDownButton
              menuItems={[
                {
                  action: null,
                  title: 'Sign In',
                  submenu: getSignInSubMenu()
                }
              ]}
              variant='grayBlue'
            />
          )}
        {(ResourceKindWithAccessLink[rsrc.ObjectMeta.Kind] || isNamespace) &&
          attachedPolicys.length > 0 && (
            <Button
              size='md'
              onClick={() => {
                if (isNamespace) {
                  setNamespacePrompt(rsrc.Spec.Type)
                  return
                }
                openURLExternally(rsrc.Status.AccessLink)
              }}
              key='signin'
              variant='grayBlue'
            >
              Sign In
            </Button>
          )}
        <Button
          onClick={() => {
            setSelectedRsrc({ ...createRef(rsrc), type: account.Spec?.Type })
            setViews((s) => ({ ...s, showIAMRolesSelection: true }))
          }}
          size='md'
          key='request'
          variant='grayBlue'
        >
          {isRsrcInCart ? 'Edit Selected Roles' : 'Request'}
        </Button>
      </>
    )
  }

  /**
   * get ecs subtype resources
   * @returns any[]
   */
  const ECSSubTypesRsrc = () => {
    const filterECSSubType = slices?.awsResources?.filter(
      (item) => item.Spec.Parent.RefID === rsrc.ObjectMeta.ID
    )
    return filterECSSubType
  }

  if (account && !activeCloudType) setActiveCloudType(account.Spec.Type)
  useApprovalPolling()
  const attachedPolicys = getAttachedPolicys()
  return (
    <div>
      <ResourceDetailsView catalogPage='resources' HeaderActions={<Actions />}>
        <IAMResourceDetails
          tabs={[
            ...(rsrc.Spec.Type === 'AWS_ECS'
              ? [
                  {
                    label: 'Resources',
                    tabContent: <ResourcesList rsrc={rsrc} ECSSubTypesRsrc={ECSSubTypesRsrc()} />
                  }
                ]
              : []),
            {
              label: 'Tags and Labels',
              tabContent: <TagsAndLabelsView rsrc={rsrc} />
            }
          ]}
        />
      </ResourceDetailsView>
      <CreateRequestFlow
        setSelectedRsrc={setSelectedRsrc}
        selectedRsrc={selectedRsrc}
        serviceAccountType={activeCloudType}
        setViews={setViews}
        views={views}
      />
      {namespacePrompt && (
        <FullScreenContentModal>
          <NamespacePrompt cloudType={namespacePrompt} onClose={() => setNamespacePrompt(false)} />
        </FullScreenContentModal>
      )}
    </div>
  )
}

const WrappedIAMUserDetails = () => {
  const { slices, selectDispatch } = useMultiSlice([
    'awsResources',
    'azureResources',
    'gcpResources',
    'kubeNamespaces'
  ])
  const rsrcList = useMemo(
    () => [
      ...slices.awsResources,
      ...slices.azureResources,
      ...slices.gcpResources,
      ...slices.kubeNamespaces
    ],
    [slices]
  )

  useEffect(() => {
    selectDispatch([
      'awsResources',
      'gcpResources',
      'azureResources',
      'policyList',
      'userList',
      'groupList',
      'serviceAccounts',
      'kubeNamespaces'
    ])
  }, [])

  return (
    <AccessCartProvider cartKey={CART_KEY}>
      <ResourceDetailsProvider rsrcList={rsrcList} rsrcKindKey='kind' rsrcNameKey='resourceName'>
        <UserIAMResourceDetails />
      </ResourceDetailsProvider>
    </AccessCartProvider>
  )
}

export { WrappedIAMUserDetails as UserIAMResourceDetails }
