import React from 'react'
import { Route } from 'react-router'
import { CloudStore, CloudStoreDetails } from '../views'

const DataStoreRoutes = () => {
  return (
    <>
      {/* ========================= Admin Routes =================================*/}
      <Route exact path={['/admin/datacloud']}>
        <CloudStore />
      </Route>
      <Route exact path={['/admin/datacloud/:appName', '/admin/datacloud/:appName/:tabName']}>
        <CloudStoreDetails />
      </Route>

      {/* ========================= User Routes =================================*/}
      <Route exact path={['/user/datacloud']}>
        <CloudStore />
      </Route>
      <Route exact path={['/user/datacloud/:appName', '/user/datacloud/:appName/:tabName']}>
        <CloudStoreDetails />
      </Route>
    </>
  )
}

export { DataStoreRoutes }
