import { msTeamsChannels } from 'infra/redux/reducers'

export const ENDPOINTS = [
  'accesspolicys',
  'accounts',
  'accountcheck',
  'adDomainControllers',
  'appgroups',
  'applications',
  'approles',
  'approvalreqs',
  'approvers',
  'attachments',
  'authzactioncheck',
  'awsresources',
  'azureresources',
  'bookmarks',
  'carts',
  'cloudactions',
  'connectors',
  'credentials',
  'credentialtypes',
  'databases',
  'devices',
  'elasticlog',
  'gcpresources',
  'github-account',
  'githubresources',
  'googleidproviders',
  'groups',
  'iamactions',
  'idpsecretrefresh',
  'idproviders',
  'jiraintegrations',
  'jiraprojects',
  'kubenamespaces',
  'kafkas',
  'medusanodes',
  'msTeamsIntegrations',
  'msTeams',
  'msTeamsChannels',
  'notificationsubscriptions',
  'objaccesscheck',
  'objactions',
  'onboardtokens',
  'orgs',
  'pacpolicys',
  'paapolicys',
  'proxies',
  'proxytokens',
  'projects',
  'regions',
  'scimservers',
  'servergroups',
  'servers',
  'serviceaccounts',
  'sessionmgmt',
  'slackchannels',
  'slackintegrations',
  'slackbotsignups',
  'sshca',
  'servicenowintegrations',
  'servicenowtables',
  'tagpolicys',
  'targetgroups',
  'targets',
  'tenants',
  'tenantapikeys',
  'tenantprofiles',
  'tgws',
  'userpreferences',
  'users',
  'vpcs',
  'workloadidentitys',
  'workloadtokens',
  'rdpservers',
  'kubeclusters',
  'salesforce-users',
  'salesforce-account',
  'crm-entities',
  'snowflakeresources'
]
//prettier-ignore
export const MAP_KIND_TO_ENDPOINT = {
  Account                   : 'accounts',
  Application               : 'applications',
  AppRole                   : 'approles',
  ApprovalReq               : 'approvalreqs',
  Approver                  : 'approvers',
  Attachment                : 'attachments',
  AwsResource               : 'awsresources',
  AzureResource             : 'azureresources',
  Bookmark                  : 'bookmarks',
  Cart                      : 'carts',
  CloudAction               : 'cloudactions',
  Database                  : 'databases',
  Device                    : 'devices',
  ElasticLog                : 'elasticlog',
  GcpResource               : 'gcpresources',
  GithubAccount             : 'github-account',
  GithubResource            : 'githubresources',
  GoogleIdProvider          : 'googleidproviders',
  Group                     : 'groups',
  IamAction                 : 'iamactions',
  IdProvider                : 'idproviders',
  JiraIntegration           : 'jiraintegrations',
  JiraProject               : 'jiraprojects',
  KubeNamespace             : 'kubenamespaces',
  Kafka                     : 'kafkas',
  KubeCluster               : 'kubeclusters',
  MedusaNode                : 'medusanodes',
  MsTeamsIntegrations       :'msTeamsIntegrations',
  MsTeams                   :'msTeams',
  msTeamsChannels           :'msTeamsChannels',
  NotificationSubscription  : 'notificationsubscriptions',
  ObjAccessCheck            : 'objaccesscheck',
  ObjAction                 : 'objactions',
  OnboardToken              : 'onboardtokens',
  Org                       : 'orgs',
  PacPolicy                 : 'pacpolicys',
  PaaPolicy                 : 'paapolicys',
  Project                   : 'projects',
  RDPServer                 : 'rdpservers',
  Server                    : 'servers',
  ServiceAccount            : 'serviceaccounts',
  SlackChannel              : 'slackchannels',
  SlackIntegration          : 'slackintegrations',
  SlackBotSignup            : 'slackbotsignups',
  ServicenowIntegration     : 'servicenowintegrations',
  ServicenowTable           : 'servicenowtables',
  TagPolicy                 : 'tagpolicys',
  TargetGroup               : 'targetgroups',
  Target                    : 'targets',
  Tenant                    : 'tenants',
  TenantApikey              : 'tenantapikeys',
  TenantProfile             : 'tenantprofiles',
  Tgw                       : 'tgws',
  UserPreference            : 'userpreferences',
  User                      : 'users',
  Vpc                       : 'vpcs',
  WorkloadIdentity          : 'workloadidentitys',
  WorkloadToken             : 'workloadtokens',
  SalesforceUsersList       : 'salesforce-users',
  SalesForceAccountList     : 'salesforce-account',
  SalesForcePermissionList  : 'crm-entities',
  SnowFlakeResources        : 'snowflakeresources',
}

export const KindToEndpointMap = MAP_KIND_TO_ENDPOINT
//prettier-ignore
export const MAP_ENDPOINT_TO_KIND = {
  accounts                   : 'Account',
  applications               : 'Application',
  approles                   : 'AppRole',
  approvalreqs               : 'ApprovalReq',
  approvers                  : 'Approver',
  attachments                : 'Attachment',
  awsresources               : 'AwsResource',
  azureresources             : 'AzureResource',
  bookmarks                  : 'Bookmark',
  carts                      : 'Cart',
  cloudactions               : 'CloudAction',
  databases                  : 'Database',
  devices                    : 'Device',
  elasticlog                 : 'ElasticLog',
  gcpresources               : 'GcpResource',
  'github-account'           : 'GithubAccount',
  githubresources            : 'GithubResource',
  googleidproviders          : 'GoogleIdProvider',
  groups                     : 'Group',
  iamactions                 : 'IamAction',
  idproviders                : 'IdProvider',
  jiraintegrations           : 'JiraIntegration',
  jiraprojects               : 'JiraProject',
  kubenamespaces             : 'KubeNamespace',
  kafkas                     : 'Kafka',
  kubeclusters               : 'KubeCluster',
  medusanodes                : 'MedusaNode',
  notificationsubscriptions  : 'NotificationSubscription',
  objaccesscheck             : 'ObjAccessCheck',
  objactions                 : 'ObjAction',
  onboardtokens              : 'OnboardToken',
  orgs                       : 'Org',
  pacpolicys                 : 'PacPolicy',
  paapolicys                 : 'PaaPolicy',
  projects                   : 'Project',
  rdpservers                 : 'RDPServer',
  servers                    : 'Server',
  serviceaccounts            : 'ServiceAccount',
  slackchannels              : 'SlackChannel',
  slackintegrations          : 'SlackIntegration',
  slackbotsignups            : 'SlackBotSignup',
  servicenowintegrations     : 'ServicenowIntegration',
  servicenowtables           : 'ServicenowTable',
  tagpolicys                 : 'TagPolicy',
  targetgroups               : 'TargetGroup',
  targets                    : 'Target',
  tenants                    : 'Tenant',
  tenantapikeys              : 'TenantApikey',
  tenantprofiles             : 'TenantProfile',
  tgws                       : 'Tgw',
  userpreferences            : 'UserPreference',
  users                      : 'User',
  vpcs                       : 'Vpc',
  workloadidentitys          : 'WorkloadIdentity',
  workloadtokens             : 'WorkloadToken',
  'salesforce-users'         : 'SalesForceUsersList',
  'salesforce-account'       : 'SalesForceAccountList',
  'crm-entities'             : 'SalesForcePermissionList',
  snowflakeresources         : 'SnowFlakeResources',
  msTeamsIntegrations        : 'MsTeamsIntegrations', 
  msTeams                    : 'MsTeams',
  msTeamsChannels            : 'MsTeamsChannels' 
}

/**
 * Endpoints which only exists on default namespace
 */
export const DEFAULT_NAMESPACE_ONLY_ENDPOINT_MAP = {
  bookmarks: true,
  carts: true,
  orgs: true,
  users: true,
  devices: true,
  userpreferences: true,
  iamactions: true,
  tenants: true,
  tenantapikeys: true,
  tenantprofiles: true,
  medusanodes: true,
  proxies: true,
  onboardtokens: true,
  googleidproviders: true,
  idproviders: true,
  idpsecretrefresh: true
}

/**
 * For these endpoints, when getAll() is called, the namespace query param will be removed.
 */
export const FETCH_ALL_WHEN_ADMIN_ENDPOINTS = {
  accounts: true
}

/**
 * ENDPOINTS which are public as opposed to private endpoints like other endpoints
 * PUBLIC endpoints uses: 'pubapi' prefix
 * PRIVATE endpoints uses: 'api'
 */
export const PUBLIC_ENDPOINTS = ['slackbotsignups']
