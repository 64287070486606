import { AWSDisplayTypeToIconTypeMap } from 'Components/CustomIcons/AWSResources'
import { AzureDisplayTypeToIconTypeMap } from 'Components/CustomIcons/AzureResources'
import { GCPDisplayTypeToIconTypeMap } from 'Components/CustomIcons/GCPResources'
import { _ } from 'Core'
import useMemoOnce from 'Core/Hooks/useMemoOnce'
import { getServiceAccountDisplayName } from 'Utils/PureHelperFuctions'
import { SALESFORCE_TYPE_ICON_NAME, UIApplicationTypeIconMap, getApplicationType } from 'features/applications'
import { createRsrcKey, getResourceName } from 'features/resources'
import { isDynamicAppRole, isPrivateServer } from 'features/targets'
import { Diagram } from 'procyon-ui'
import React from 'react'

/**
 *
 * @typedef {{ label:string, id:string, onClick:(() => void) }} NodeAction
 *
 * @param {{
 * policy                   : any,
 * resources                : any[],
 * customResources          : any[]
 * resourceToRolesMapObject : Object<string, any[]>
 * entities                 : any[],
 * actionsObj               ?: Object<any, NodeAction[]>           // Actions for every nodes on graph
 * }} param0
 * @returns
 */
function AccessGraph({
  policy,
  resources,
  resourceToRolesMapObject,
  entities,
  actionsObj = {},
  customResources = []
}) {
  // get principla name if pods deployment level policy is created in kubenamespace
  const getAllPrincipalKeys = (data) => {
    let principalKeys = []
    data?.Spec?.ActionMap?.KubeAccess?.PolicyRule?.forEach((rule) => {
      // Check if Principal exists and add it to principalKeys array
      if (rule.Principal) {
        principalKeys.push(rule.Principal)
      }
    })

    if (typeof principalKeys === 'string') {
      principalKeys = principalKeys?.split(',')
    } else {
      let joinPrincipalKeys = principalKeys.join(',')
      principalKeys = joinPrincipalKeys?.split(',')
    }
    return principalKeys
  }

  // Get all principal keys
  const principal = getAllPrincipalKeys(policy)

  const policyType = policy && policy.Type // IAMAction || Target

  const getRsrcActions = (rsrc) => {
    const key = createRsrcKey(rsrc)
    return actionsObj[key] ?? []
  }

  const getResourcesNodes = () => {
    const nodes = []
    customResources.forEach((custRsrc) => {
      nodes.push(custRsrc)
    })

    resources.forEach((rsrc) => {
      const common = {
        id: createRsrcKey(rsrc),
        value: createRsrcKey(rsrc),
        actions: getRsrcActions(rsrc)
      }
      switch (rsrc.ObjectMeta.Kind) {
        case 'Application':
          return nodes.push({
            description: getApplicationType(rsrc),
            label: rsrc.ObjectMeta.Name,
            type: UIApplicationTypeIconMap[getApplicationType(rsrc)],
            ...common
          })
        case 'AwsResource':
          return nodes.push({
            description: rsrc.Spec.Type.replaceAll('_', ' '),
            label: getResourceName(rsrc),
            type: AWSDisplayTypeToIconTypeMap[rsrc.Spec.Type] || 'AWS_APPLICATION',
            ...common
          })
        case 'AzureResource':
          return nodes.push({
            description: rsrc.Spec.DisplayType,
            label: getResourceName(rsrc),
            type: AzureDisplayTypeToIconTypeMap[rsrc.Spec.DisplayType] || 'AZURE_APPLICATION',
            ...common
          })
        case 'GcpResource':
          return nodes.push({
            description: rsrc.Spec.DisplayName,
            label: rsrc.Spec.DisplayName,
            type: GCPDisplayTypeToIconTypeMap[rsrc.Spec.DisplayType] || 'GCP',
            ...common
          })
        case 'Server':
          return nodes.push({
            description: getResourceName(rsrc),
            label: getResourceName(rsrc),
            type: isPrivateServer(rsrc) ? 'SERVER_PRIVATE' : 'SERVER',
            ...common
          })
        case 'ServiceAccount':
          return nodes.push({
            description: getServiceAccountDisplayName(rsrc),
            label: getServiceAccountDisplayName(rsrc),
            type: 'SERVICEACCOUNT',
            ...common
          })
        case 'AppRole':
          return nodes.push({
            description: getResourceName(rsrc),
            label: getResourceName(rsrc),
            type: isDynamicAppRole(rsrc) ? 'APPROLE_DYNAMIC' : 'APPROLE',
            ...common
          })
        case 'Database':
          return nodes.push({
            description: getResourceName(rsrc),
            label: getResourceName(rsrc),
            type: 'DATABASE',
            ...common
          })
        case 'KubeNamespace':
          return nodes.push({
            description: getResourceName(rsrc),
            label: getResourceName(rsrc),
            type: `KUBE_NAMESPACES`,
            ...common
          })
        case 'GithubResource':
          return nodes.push({
            description: rsrc.Spec.Type.replaceAll('_', ' '),
            label: getResourceName(rsrc),
            type: 'GITHUB_REPOSITORIES_MONO',
            ...common
          })
        case 'GithubAccount':
          return nodes.push({
            // description: rsrc.Spec.Type.replaceAll('_', ' '),
            label: getResourceName(rsrc),
            type: 'GITHUB_REPOSITORIES_MONO',
            ...common
          })
        case 'RDPServer':
          return nodes.push({
            // description: rsrc.Spec.Type.replaceAll('_', ' '),
            label: getResourceName(rsrc),
            type: 'RDP_SERVER',
            ...common
          })
        case 'Kafka':
          return nodes.push({
            label: getResourceName(rsrc),
            type: 'APACHE_KAFKA',
            ...common
          })
        case 'KubeCluster':
          return nodes.push({
            label: getResourceName(rsrc),
            description: rsrc.Spec.IAMRes,
            type: 'KUBE_CLUSTERS',
            ...common
          })
        case 'CRMEntity':
          return nodes.push({
            label: getResourceName(rsrc),
            description: rsrc.Type,
            type: SALESFORCE_TYPE_ICON_NAME[rsrc.Type],
            ...common
          })
        case 'SnowflakeRole': {
          return nodes.push({
            label: getResourceName(rsrc),
            Description: rsrc.Spec.Description,
            type: UIApplicationTypeIconMap['SNOWFLAKE'],
            ...common
          })
        }
      }
    })
    return [...nodes]
  }

  const getAppRoleNodes = () => {
    const nodes = []
    const refObject = resourceToRolesMapObject

    Object.keys(refObject).forEach((e) => {
      const roles = refObject[e]
      roles.forEach((role) => {
        // Prevent creation of duplicate roles's node
        if (!_.find(nodes, { id: createRsrcKey(role) })) {
          nodes.push({
            id: createRsrcKey(role),
            label: getResourceName(role),
            description: role.Spec.Description?.substring(0, 40) || '',
            type: 'ROLE',
            value: createRsrcKey(role),
            actions: getRsrcActions(role)
          })
        }
      })
    })

    return nodes
  }

  const getUserNodes = () => {
    const list = []
    const map = {
      User: 'USER',
      Group: 'USERGROUPS',
      ServiceAccount: 'SERVICEACCOUNT',
      WorkloadIdentity: 'WORKLOAD',
      Kafka: 'APACHE_KAFKA',
      RDPServer: 'RDP_SERVER'
    }
    entities.forEach((e) => {
      list.push({
        description:
          e.ObjectMeta.Kind === 'ServiceAccount'
            ? getServiceAccountDisplayName(e)
            : e.ObjectMeta.Name,
        id: createRsrcKey(e),
        label: getResourceName(e),
        type: map[e.ObjectMeta.Kind],
        value: createRsrcKey(e),
        actions: getRsrcActions(e)
      })
    })
    return list
  }

  const getPolicyNodes = () => {
    const nodes = []
    if (policy) {
      nodes.push({
        description: policy.ObjectMeta.Name,
        id: createRsrcKey(policy),
        label: policy.GivenName,
        type: 'POLICY',
        value: createRsrcKey(policy),
        actions: getRsrcActions(policy)
      })
    } else {
      // Placeholder policy
      nodes.push({
        description: '',
        id: 'p-policy',
        label: 'Policy',
        type: 'POLICY',
        value: 'p-policy'
      })
    }
    return nodes
  }

  const getArrows = () => {
    const arrows = []
    const userNodes = getUserNodes()
    const policyNode = getPolicyNodes()[0]
    const iamActionsNodes = getAppRoleNodes()
    const rsrcNodes = getResourcesNodes()

    if (policyNode) {
      // Arrows from User nodes to POLICY node
      userNodes.forEach((e) => {
        arrows.push({
          startID: e.id,
          endID: policyNode.id
        })
      })
    }

    // If the approval request policy was for IAM Resources, create arrows from policy to iam actions/roles
    if (policyType === 'IAMAction') {
      if (policyNode) {
        // Arrow from policy node to iam actions node
        iamActionsNodes.forEach((node) => {
          arrows.push({
            startID: policyNode.id,
            endID: node.id
          })
        })
      }
    }

    // If the approval request  policy was for targets or app, create arrow from policy to rsrcs directly
    if (
      policyType === 'Target' ||
      policyType === 'App' ||
      policyType === 'GithubResources' ||
      policyType === 'Salesforce' ||
      policyType === 'Snowflake'
    ) {
      if (policyNode) {
        // Arrow from policy node to rsrcs node
        rsrcNodes.forEach((node) => {
          arrows.push({
            startID: policyNode.id,
            endID: node.id
          })
        })
      }
    }

    // IAM Actions arrows
    if (policyType === 'IAMAction') {
      // Arrow  from iam actions nodes to resource nodes
      rsrcNodes.forEach((e) => {
        // e.id is the rsrc key generated using getKey()
        const roles = resourceToRolesMapObject[e.id]
        if (roles) {
          roles.forEach((role) => {
            // Create arrow only if the iam Action node exists
            if (_.find(iamActionsNodes, { id: createRsrcKey(role) })) {
              arrows.push({
                startID: createRsrcKey(role), // AppRole id
                endID: e.id // resource id
              })
            }
          })
        }
      })
    }

    return arrows
  }

  const getNodes = () => {
    const nodes = [[...getUserNodes()], [...getPolicyNodes()]]
    // Only Resources/IAM Resources have approles to it
    if (policyType === 'IAMAction') nodes.push(getAppRoleNodes())
    nodes.push(getResourcesNodes())
    return nodes
  }

  const style = useMemoOnce({
    height: '100vh',
    minHeight: '600px',
    overflow: 'auto',
    maxWidth: '100%'
  })

  return (
    <div>
      <Diagram containerStyle={style} arrows={getArrows()} data={getNodes()} />
    </div>
  )
}

export { AccessGraph }
