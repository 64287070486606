import { TabGroup } from 'procyon-ui'
import React from 'react'
import { ApplicationsList } from '../ApplicationsList'
import { AllApplications } from '../AllApplications'
import useAppView from 'Core/Hooks/useAppView'
import { createRsrcKey, useResourceDetails } from 'features/resources'

const Applications = () => {
    const { rsrc } = useResourceDetails()
    const { viewMode } = useAppView()
    const tabs = [
        {
            label: 'Active Applications',
            tabContent: <ApplicationsList />
        }
    ];

    if (viewMode == 'admin') {
        tabs.push({
            label: 'Add Application',
            tabContent: <AllApplications />
        });
    }

    return (
        <>
            <TabGroup
                activeIndex={0}
                key={createRsrcKey(rsrc)}
                onChangeTab={function noRefCheck() { }}
                tabs={tabs}
            />
        </>
    )
}

export { Applications }
