import { getObjectRefFlat } from 'Core/Hooks/useObjectRelations'
import _ from 'lodash'
import { TargetIcon } from 'procyon-ui'
import React from 'react'
import { AppHTTPEntryPathKey, ReadOnlyAppTypes, UIApplicationTypeIconMap } from './constants'
import { getCurrentOrg } from 'Utils/Helpers'
import { useSelector } from 'react-redux'
/**
 * Returns URL which can be used for signin into the application
 * @param {object} app
 * @returns
 */
export const getApplicationSignInURL = (app) => {
  const user = JSON.parse(localStorage.getItem('user'))
  const isAgentlessMode = user.awsurl !== 'signin.aws.procyon.ai'
  if (app.Spec.AppType === 'github') {
    return _.get(app, 'Spec.SamlServiceProvider.ServiceProviderACSURL').replace(/saml$/, 'sso')
  } else if (app.Spec.AppType === 'salesforce') {
    return _.get(app, 'Spec.SalesforceAppConfig.InstanceURL')
  } else {
    // Get HTTP Entry Path
    const entryPath = _.get(app, AppHTTPEntryPathKey, '/')
    const appSignInUrl =
      _.get(app, 'Spec.SamlServiceProvider.ServiceProviderACSURL') ||
      `https://${_.get(app, 'Spec.HttpAppConfig.FrontEndName.Elems[0]', '')}${entryPath}`

    const agentLessSignInUrl = appSignInUrl
      .replace('.default', '-app-default')
      .replace('procyon.ai', user.rdpurl)

    return isAgentlessMode ? agentLessSignInUrl : appSignInUrl
  }
}

export const getApplicationIcon = (app) => {
  const type = getApplicationType(app)
  //@ts-ignore
  const Icon = ({ ...rest }) => <TargetIcon type={UIApplicationTypeIconMap[type]} {...rest} />
  return Icon
}

/**
 * Returns the type of application
 * @param {*} app
 * @returns {'AWS'|'AZURE'|'GCP'|'DROPBOX'|'HTTP'|'GITHUB'|'SALESFORCE'|'SNOWFLAKE'} Application Type
 */
export const getApplicationType = (app) => {
  const account = getObjectRefFlat(app?.Spec?.Account)
  const serviceType = account ? account.Spec.Type : app.Spec.AppType.toUpperCase()
  return serviceType
}

/**
 * Get the name from frontend url
 * @param {string} fnURL The frontend url in format: <some name>.applications.procyon.ai
 * @returns the <some name> from `fnURL`
 */
export const getFrontendNameFromURL = (fnURL = '') => {
  const withoutAppDomain = fnURL
    .replace(`.${getCurrentOrg()}.applications.procyon.ai`, '')
    .replace('https://', '')
    .replace(/:\d+$/, '')

  return withoutAppDomain
}

/**
 * Returns `true` if this app is readonly - cannot be edited or deleted
 */
export const isReadOnlyApp = (app) => {
  const type = getApplicationType(app)
  return !!ReadOnlyAppTypes[type]
}

export const getApplicationHostName = (application) => {
  const FrontendName = _.get(application, 'Spec.HttpAppConfig.FrontEndName.Elems', [])[0]
  const SAMLACSURL = _.get(application, 'Spec.SamlServiceProvider.ServiceProviderACSURL')
  return FrontendName || SAMLACSURL
}
