import { getAWSResourceIcon } from 'Components/CustomIcons/AWSResources'
import { getAZUREResourceIcon } from 'Components/CustomIcons/AzureResources'
import { getGCPResourceIcon } from 'Components/CustomIcons/GCPResources'
import { addSpaces } from 'Utils/PureHelperFuctions'
import { getAwsResourceTags, getIAMResourceDisplayType } from 'features/iamResources'
import {
  createRef,
  createRsrcKey,
  getResourceName,
  getResourcePolicyTags,
  getRsrcIcon
} from 'features/resources'
import { getServerName } from 'features/targets'

export const rsrcsAdapters = {
  aws({ rsrc, account }) {
    const Icon = getAWSResourceIcon(rsrc)
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.Type.replaceAll('_', ' '),
      resourceName: getResourceName(rsrc),
      type: 'AWS',
      Icon,
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  kubeNamespaces({ rsrc, account }) {
    const Icon = getRsrcIcon(rsrc)

    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: rsrc.Spec.DisplayName,
      type: rsrc.Spec.Type,
      Icon,
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  azure({ rsrc, account }) {
    const Icon = getAZUREResourceIcon(rsrc)
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'AZURE',
      Icon,
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  gcp({ rsrc, account }) {
    const Icon = getGCPResourceIcon(rsrc)
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'GCP',
      Icon,
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  appRole({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'AppRole',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  server({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getServerName(rsrc),
      type: 'Server',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  database({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'Database',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  rdpServer({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'Database',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  kafka({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: rsrc.Spec.DisplayType,
      resourceName: getResourceName(rsrc),
      type: 'Kafka',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  },
  kubeCluster({ rsrc, account }) {
    return {
      accountName: getResourceName(account),
      region: rsrc.Spec.Region,
      resourceType: addSpaces(rsrc.ObjectMeta.Kind),
      resourceName: getResourceName(rsrc),
      type: 'KubeCluster',
      RefID: rsrc.ObjectMeta.ID,
      RefKind: rsrc.ObjectMeta.Kind,
      key: createRsrcKey(rsrc),
      AccountRefID: account?.ObjectMeta.ID,
      AccountType: account?.Spec.Type,
      ref: createRef(rsrc)
    }
  }
}

export const resourceAdapterMap = {
  AwsResource: rsrcsAdapters.aws,
  KubeNamespace: rsrcsAdapters.kubeNamespaces,
  GcpResource: rsrcsAdapters.gcp,
  AzureResource: rsrcsAdapters.azure,
  Database: rsrcsAdapters.database,
  RDPServer: rsrcsAdapters.rdpServer,
  AppRole: rsrcsAdapters.appRole,
  Server: rsrcsAdapters.server,
  Kafka: rsrcsAdapters.kafka,
  KubeCluster: rsrcsAdapters.kubeCluster
}
// =================================== // SEARCH FILTERS UTILS/ ===================================//

const DELIMITER = '!'

const getRrscSearchData = (rsrc, account) => {
  const { DisplayType, Region } = rsrc.Spec
  const dName = getIAMResourceDisplayType(rsrc)
  return `${getResourceName(rsrc)}${getResourceName(account)}${dName || ''}${DisplayType}${Region}`
}

const ptagFilterFn = ({ tagsMap, queryKey }) => {
  const split = queryKey.split(DELIMITER)

  // Check if the tagName is provided as in: tag:Engine
  if (split.length > 1) {
    const tagName = split[0].trim()
    return tagsMap[tagName]?.includes(split[1].trim())
  }
  const tagsValues = Object.keys(tagsMap)
  return tagsValues.join('').toLowerCase().includes(queryKey.toLowerCase())
}

const tagFilterFn = (rsrcTags, queryKey) => {
  const split = queryKey.split(DELIMITER)
  const tagName = split[0]?.trim()

  // Since tag name is not present, so return true for only rsrc which have tag in object
  if (!tagName) return Object.keys(rsrcTags).length > 0

  const tagValue = split.slice(1).join(DELIMITER).trim()
  const rsrcTagValue = rsrcTags[tagName] ? JSON.stringify(rsrcTags[tagName]) : ''
  // Check if the tagName and tagValue is provided as in: tag:Engine then
  // check for match
  if (tagName && tagValue) return rsrcTagValue?.includes(tagValue)

  // only tag name is provided then,

  return !!rsrcTags[tagName]
}

/**
 * Map of resource kind with respective query resolver function.
 *
 * Format:
 *
 * ```
 *  [x: Kind]: {
 *     [x: Query Tag Resolver Function] : (rsrc, queryKey) => boolean (true if match, else false)
 *  }
 *
 * ```
 * @type {import('Core/Hooks/useSearchQuery').QueryMapType}
 *
 */
export const ResourceQueryFilterMap = {
  AwsResource: {
    tag(rsrc, queryKey) {
      const rsrcTags = getAwsResourceTags(rsrc)
      return tagFilterFn(rsrcTags, queryKey)
    },
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)
      return ptagFilterFn({ tagsMap: policyTags, queryKey })
    },
    type(rsrc, queryKey) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return displayType.toLowerCase().includes(queryKey)
    }
  },
  GcpResource: {
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)
      return ptagFilterFn({ tagsMap: policyTags, queryKey })
    },
    type(rsrc, queryKey) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return displayType.toLowerCase().includes(queryKey)
    }
  },
  AzureResource: {
    tag(rsrc, queryKey) {
      const rsrcTags = getAwsResourceTags(rsrc)
      return tagFilterFn(rsrcTags, queryKey)
    },
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)
      return ptagFilterFn({ tagsMap: policyTags, queryKey })
    },
    type(rsrc, queryKey) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return displayType.toLowerCase().includes(queryKey)
    }
  }
}

const tagsSuggesstionsFn = ({ tagsObj, queryKey, tagKey = 'ptag' }) => {
  const split = queryKey.split(DELIMITER)
  if (split.length > 1) {
    const tagName = split[1]
    if (tagsObj[tagName])
      return [
        {
          title: `${tagName}${DELIMITER}${tagsObj[tagName]}`,
          template: `[${tagKey}${DELIMITER}${tagName}${DELIMITER}${tagsObj[tagName]}] `
        }
      ]
  }

  const items = []
  for (const key in tagsObj) {
    // If the rsrc tag has no value or value is empty return
    if (tagsObj[key].trim().length) {
      items.push({
        title: `${key}`,
        template: `[${tagKey}${DELIMITER}${key}${DELIMITER}]`
      })
    }
  }
  return items
}

/**
 * The suggesstions map for the tags which is supported.
 * @type {import('Core/Hooks/useSearchQuery').SuggestionMapType}
 */
export const ResourceTagSuggesstionsMap = {
  AwsResource: {
    tag(rsrc, queryKey) {
      const rsrcTags = getAwsResourceTags(rsrc)
      return tagsSuggesstionsFn({
        tagsObj: rsrcTags,
        queryKey,
        tagKey: 'tag'
      })
    },
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)
      return tagsSuggesstionsFn({
        tagsObj: policyTags,
        queryKey
      })
    },
    type(rsrc) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return [
        {
          title: `${displayType}`,
          template: `[type${DELIMITER}${displayType.toLowerCase()}]`
        }
      ]
    }
  },
  GcpResource: {
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)

      return tagsSuggesstionsFn({
        tagsObj: policyTags,
        queryKey
      })
    },
    type(rsrc) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return [
        {
          title: `${displayType}`,
          template: `[type${DELIMITER}${displayType.toLowerCase()}]`
        }
      ]
    }
  },
  AzureResource: {
    tag(rsrc, queryKey) {
      const rsrcTags = getAwsResourceTags(rsrc)
      return tagsSuggesstionsFn({
        tagsObj: rsrcTags,
        queryKey,
        tagKey: 'tag'
      })
    },
    ptag(rsrc, queryKey) {
      const policyTags = getResourcePolicyTags(rsrc)

      return tagsSuggesstionsFn({
        tagsObj: policyTags,
        queryKey
      })
    },
    type(rsrc) {
      /**
       * @type {string}
       */
      //@ts-ignore
      const displayType = getIAMResourceDisplayType(rsrc).replaceAll(' ', '')
      return [
        {
          title: `${displayType}`,
          template: `[type${DELIMITER}${displayType.toLowerCase()}]`
        }
      ]
    }
  }
}

export const rsrcSearchDataMatch = (rsrc, queryKey, data = { account: null }) =>
  getRrscSearchData(rsrc, data.account).toLowerCase().includes(queryKey.toLowerCase())

// =================================== // ===================================// ===================================//

export const DEFAULT_SEARCH_SUGGESTIONS_MAP = {
  AwsResource: [
    {
      title: 'tag: Search By Cloud Tags',
      template: `[tag${DELIMITER}$]`,
      caption: 'Eg: tag!engine!postgres'
    },
    {
      title: 'ptag: Search by Procyon Tags',
      template: `[ptag${DELIMITER}$]`,
      caption: `Eg: ptag!appgroup!abc`
    },
    {
      title: `type: Search by Type`,
      template: `[type${DELIMITER}$]`,
      caption: `Eg: type!ec2`
    }
  ],
  GcpResource: [
    {
      title: 'ptag: Search by Procyon Tags',
      template: `[ptag${DELIMITER}$]`,
      caption: 'Eg: ptag!appgroup!abc'
    },
    {
      title: 'type: Search by Type',
      template: `[type${DELIMITER}$]`,
      caption: 'Eg: type!instance'
    }
  ],
  AzureResource: [
    {
      title: 'tag: Search By Cloud Tags',
      template: `[tag${DELIMITER}$]`,
      caption: 'Eg: tag!engine!postgres'
    },
    {
      title: 'ptag: Search by Procyon Tags',
      template: `[ptag${DELIMITER}$]`,
      caption: 'Eg: ptag!appgroup!abc'
    },
    {
      title: 'type: Search by Type',
      template: `[type${DELIMITER}$]`,
      caption: 'Eg: type!keyvault'
    }
  ]
}
