import { faCopy, faInfo } from '@fortawesome/free-solid-svg-icons'
import { faGear, faTrash } from '@fortawesome/pro-solid-svg-icons'
import useAppView from 'Core/Hooks/useAppView'
import useMultiSlice from 'Core/Hooks/useMultiSlice'
import { useUser } from 'Core/Hooks/useUser'
import { enqueueNotification, getAccountIcon } from 'Utils/Helpers'
import { FullScreenAlertModal, LabelContent, LoadingFeedback } from 'V2Components'
import { ErrorLabelContent, getResourceName, getRsrcIcon } from 'features/resources'
import { updateRDPServer, useRDPAccess } from 'features/targets'
import { reduxApiClient } from 'infra'
import { updateSliceData } from 'infra/redux/sliceHandlers'
import _ from 'lodash'
import { Button, IconButton, Label, TextInput, Tooltip, Typography } from 'procyon-ui'
import React, { useEffect, useState } from 'react'
import { RDPADDCSetupModal } from '../../../../../RDPADDCSetupModal'
import { MeshClusterLabelContent } from '../MeshClusterLabelContent'

const RDPServer = ({ rsrc, account }) => {
  const { appView } = useAppView()
  const { user } = useUser()
  const { getUserRDPAccessLinks } = useRDPAccess()
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedRowForDelete, setSelectedRowForDelete] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [showADDCSetupModal, setShowADDCSetupModal] = useState(false)
  const { getObjectRef } = useMultiSlice(['adDomainControllers'])
  const AccountIcon = getAccountIcon(_.get(account, 'Spec.Type', ''.toLowerCase()))
  const [savingDisable, setSavingDisable] = useState(false)
  const isAdDomainSetupExist = rsrc.Spec.ADDomainController.RefID === '0' ? false : true
  const addc = getObjectRef(rsrc.Spec.ADDomainController)
  const cloudVM = getObjectRef(rsrc.Spec.CloudVM)
  const CloudVMIcon = getRsrcIcon(cloudVM)

  const getCurrentUserCredentails = (data) => {
    const userMap = data?.Spec?.UserMap?.Map || {}
    const credentialsMap = data?.Spec?.Credentials?.Map || {}
    const matchedUser = Object.entries(userMap).filter(
      ([key, value]) => value.RefID === user.ObjectMeta.ID
    )
    const matchedKeys = new Set(matchedUser.map((item) => item[0]))
    const associatedCreds = Object.keys(credentialsMap).filter((key) => userMap.hasOwnProperty(key))
    const nonAssociatedCreds = Object.keys(credentialsMap).filter(
      (key) => !userMap.hasOwnProperty(key)
    )
    const matchedCredentials = associatedCreds.filter((key) => matchedKeys.has(key))
    const combinedArray = [...matchedCredentials, ...nonAssociatedCreds]
    const filteredCreds = combinedArray.reduce((acc, key) => {
      acc[key] = ''
      return acc
    }, {})

    if (filteredCreds) {
      return filteredCreds
    } else {
      return {}
    }
  }

  let credentialsData = getCurrentUserCredentails(rsrc)
  useEffect(() => {
    if (rsrc) {
      setIsLoading(false)
    }

    reduxApiClient('rdpservers').getByDataParams({ 'ObjectMeta.Name': rsrc.ObjectMeta.Name })
    const userPolicy = getUserRDPAccessLinks(user, rsrc)
    let updatedInitialRow
    if (appView === 'user' && userPolicy) {
      updatedInitialRow = initialRows?.filter((item) => userPolicy[item.Username])
    } else {
      updatedInitialRow = initialRows
    }
    setDBrow(updatedInitialRow)
  }, [rsrc])

  // Create an array of initial rows
  let initialRows = Object.keys(credentialsData).map((username) => ({
    Username: username,
    Password: credentialsData[username],
    mandatory: false,
    error: false,
    disableInput: true
  }))

  // If there are no keys in credentialsData, add a default empty row
  if (Object.keys(credentialsData).length === 0 && appView === 'admin') {
    initialRows.push({
      Username: '', // Default empty username
      Password: '', // Default empty password
      mandatory: true,
      error: false,
      disableInput: false
    })
  }

  const [dbRow, setDBrow] = useState(initialRows)
  // in userview show only principla which has been granted

  const handleSaveAllRows = async () => {
    const specialCharacters = /^[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
    let setSuccess = true
    const updatedRows = dbRow.map((row) => {
      if (specialCharacters.test(row.Username)) {
        enqueueNotification('Only Special Cahracters are not allowed', 'error')
        delete dbRow[row.Username]
        setSuccess = false
        return
      } else {
        setSuccess = true
      }

      if (row.mandatory && !row.Password.trim() && row.Username !== '') {
        return {
          ...row,
          error: true
        }
      } else {
        return {
          ...row,
          error: false
        }
      }
    })

    const hasEmptyPassword = updatedRows?.some((row) => row?.error)
    if (hasEmptyPassword) {
      setDBrow(updatedRows)
      enqueueNotification('password cannot be empty', 'error')
    } else {
      const allRowData = dbRow.map((row) => {
        return {
          Username: row.Username,
          Password: row.Password === undefined ? '' : row.Password
        }
      })

      // check if user alredy exist
      const modifiedData = allRowData.map(({ Username, Password }) => {
        if (credentialsData.hasOwnProperty(Username) && credentialsData[Username] !== Password) {
          // Password has been updated for an existing user
          return { Username, Password, updated: true }
        } else {
          // Either a new user or the password remains unchanged
          return { Username, Password, updated: false }
        }
      })

      const credentialsArray = modifiedData.filter((item) => item.Username !== '')
      const credentialsObject = credentialsArray.reduce((acc, { Username, Password }) => {
        if (!acc.hasOwnProperty(Username)) {
          acc[Username] = Password
        } else if (Password !== '') {
          acc[Username] = Password
        }
        return acc
      }, {})

      // default placholder entery
      const defaultCredentailObj = {
        'placeholder-652c5b8a-2978-46f5-a9f0-6f20001ff230': ''
      }

      try {
        const dataObj = structuredClone(rsrc)
        if (credentialsArray.length > 0) {
          dataObj.Spec.Credentials.Map = { ...credentialsObject, ...defaultCredentailObj }
        } else {
          dataObj.Spec.Credentials.Map = {}
        }

        if (setSuccess) {
          const updtRDP = await updateRDPServer(dataObj)
          updateSliceData(updtRDP)
          const updatedDBRow = Object.keys(updtRDP?.Spec?.Credentials?.Map).map((username) => ({
            Username: username, // Use the username if available, otherwise an empty string
            Password: credentialsData[username], // Use the password if available, otherwise an empty string
            mandatory: false,
            error: false,
            disableInput: true
          }))
          setDBrow(updatedDBRow)
          enqueueNotification('User Added Successfully!', 'info')
        } else {
          console.log('special charater error ===')
        }
      } catch (error) {
      } finally {
      }
    }
  }

  const handleAddDBRow = () => {
    const newRow = {
      id: Math.floor(Math.random() * 10000),
      Username: '',
      Password: '',
      mandatory: true,
      error: false,
      disableInput: false
    }
    setDBrow((prevRows) => [...prevRows, newRow])
  }

  const handleDbRowInputChange = (fieldName, rowIndex, value) => {
    if (rowIndex >= 0 && rowIndex < dbRow.length) {
      const updatedRowData = [...dbRow]
      const updatedUsername = value.trim()
      updatedRowData[rowIndex][fieldName] = value

      if (fieldName === 'Username' && value !== '') {
        const lowerCaseUsername = updatedUsername.toLowerCase()
        const existingUserIndex = updatedRowData.findIndex(
          (row, index) => index !== rowIndex && row.Username.toLowerCase() === lowerCaseUsername
        )

        if (existingUserIndex !== -1) {
          setSavingDisable(true)
          enqueueNotification(`${value} user already exists`, 'error')
        } else {
          setSavingDisable(false)
        }
      }

      if (fieldName === 'Password' && value !== '') {
        updatedRowData[rowIndex]['error'] = false
      }

      setDBrow(updatedRowData)
    }
  }

  const handleDeletRow = (rowIndex) => {
    setShowDeleteModal(true)
    setSelectedRowForDelete(rowIndex)
  }

  const handleDeletDBRow = async (rowIndex) => {
    // Ensure that rowIndex is within a valid range
    setShowDeleteModal(false)
    if (rowIndex >= 0 && rowIndex < dbRow.length) {
      const updatedDBRow = dbRow.filter((row, index) => index !== rowIndex)
      setDBrow(updatedDBRow)
      // Update the rowData state to keep it in sync
      const updatedRowData = dbRow.filter((row, index) => index !== rowIndex)
      const credentialsObject = updatedRowData.reduce((acc, item) => {
        acc[item.Username] = item.Password
        return acc
      }, {})

      try {
        const dataObj = structuredClone(rsrc)
        dataObj.Spec.Credentials.Map = credentialsObject
        const updtRDP = await updateRDPServer(dataObj)
        updateSliceData(updtRDP)
        setDBrow(updatedRowData)
        enqueueNotification('Deleted User Successfully!', 'info')
      } catch (error) {
        console.log('delete user error')
      }
    }
  }

  const handleCopyCmd = async (item) => {
    const { Tenant, Namespace, Name } = rsrc.ObjectMeta
    const payloadObj = {
      ObjectMeta: {
        Tenant: Tenant,
        NameSpace: Namespace,
        Name: Name
      },
      UserName: item
    }
    try {
      const response = await reduxApiClient('rdppasswords').create(payloadObj)
      console.log(response.Password)
      navigator.clipboard.writeText(response?.Password)
      enqueueNotification('Password Copied SuccessFully !', 'info')
    } catch (error) {
      console.log(error)
    }
  }

  const getDNSName = (url) => {
    if (user.org !== 'default') {
      return url.replace('.rdp.procyon.ai', `-rdp-${user.org}.applications.${user.controller}`)
    } else {
      return url.replace('.rdp.procyon.ai', `-rdp.applications.${user.controller}`)
    }
  }

  const Icon = getRsrcIcon(addc)

  return (
    <>
      <ErrorLabelContent rsrc={rsrc} />
      <LabelContent title='Name' content={getResourceName(rsrc)} />
      <LabelContent
        title='Account'
        content={AccountIcon && <Label icon={<AccountIcon />} text={getResourceName(account)} />}
      />
      <LabelContent
        title='Cloud VM'
        content={CloudVMIcon && <Label text={getResourceName(rsrc)} icon={<CloudVMIcon />} />}
      />
      <LabelContent title={'IP Address'} content={rsrc.Spec.Hostname} />
      <LabelContent title={'FQDN'} content={rsrc.Status.FQDN} />
      {appView === 'admin' && (
        <LabelContent
          title='AD Domain Controller'
          content={
            <div className='flex justify-between items-center gap-4'>
              {addc && <Label icon={<Icon />} text={getResourceName(addc)} />}
              {!addc && (
                <Typography variant='body-regular'>No AD Domain Controller setup.</Typography>
              )}
              <Button icon={faGear} onClick={() => setShowADDCSetupModal(true)} variant='primary'>
                Setup
              </Button>
            </div>
          }
        />
      )}
      {showADDCSetupModal && (
        <RDPADDCSetupModal
          onCancel={() => setShowADDCSetupModal(false)}
          onSuccess={() => setShowADDCSetupModal(false)}
          rdpServer={rsrc}
        />
      )}
      <LabelContent
        title={'Domain Name'}
        content={
          <Typography variant='body-regular'>
            {getDNSName(rsrc?.Spec?.DNSNames?.Elems[0])}
          </Typography>
        }
      />

      {appView === 'admin' && <MeshClusterLabelContent rsrc={rsrc} />}

      {isAdDomainSetupExist && (
        <Typography className='mt-4 mb-4 !text-gray-500' variant='body-regular'>
          Use <strong>user@domain</strong> for domain control.
        </Typography>
      )}
      {/*
       * AWS add user or username with password
       */}
      {isAdDomainSetupExist &&
        dbRow.length > 0 &&
        dbRow.map((e, i) => {
          return (
            <>
              {!dbRow[i]?.Username.includes('placeholder') && (
                <div
                  className='flex gap-12 border-b-1 mt-2 mb-2 pb-2'
                  style={{ borderBottom: '1px solid #D8DDE4' }}
                  key={i}
                >
                  <div className='flex gap-5 items-center justify-between'>
                    <label
                      className='mr-8'
                      style={{ fontWeight: 600, color: '#A6ABB6', width: '150px' }}
                    >
                      Username{appView === 'admin' ? '/ Password' : ''}
                    </label>

                    <TextInput
                      disabled={dbRow[i]?.disableInput}
                      value={dbRow[i]?.Username}
                      style={{ width: '200px' }}
                      onChange={(e) => handleDbRowInputChange('Username', i, e.target.value)}
                      placeholder='Username'
                    />

                    {!isAdDomainSetupExist && appView === 'admin' && (
                      <TextInput
                        aria-required
                        style={{ border: dbRow[i].error ? '1px solid red' : '' }}
                        type='password'
                        value={dbRow[i]?.Password}
                        onChange={(e) => handleDbRowInputChange('Password', i, e.target.value)}
                        placeholder='Password *'
                      />
                    )}

                    <div className='flex'>
                      {!isAdDomainSetupExist && appView === 'admin' && (
                        <IconButton
                          onClick={() => handleDeletRow(i)}
                          variant='grayRed'
                          icon={faTrash}
                        />
                      )}
                      <div className='flex gap-1'>
                        <IconButton
                          onClick={() => handleCopyCmd(dbRow[i]?.Username)}
                          variant='gray'
                          icon={faCopy}
                        />
                        <Tooltip arrow placement='top-start' title='Copy Principal Password'>
                          <IconButton
                            onMouseOver={() => {}}
                            variant='clear'
                            icon={faInfo}
                            style={{
                              width: '5px',
                              height: '15px'
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )
        })}

      {showDeleteModal && (
        <FullScreenAlertModal
          actionButtonText='Delete'
          actionButtonVariant='danger'
          alertMessage={`Are you sure you want to delete?`}
          loadingMessage='Deleting User'
          onActionClick={() => {
            handleDeletDBRow(selectedRowForDelete)
          }}
          onCancel={() => setShowDeleteModal(false)}
          showModal
        />
      )}

      <LoadingFeedback
        message={'Updating Domain Controller'}
        loading={isLoading}
        caption='Please wait...'
      />
    </>
  )
}

export { RDPServer }
