//* ====================================/ GCP Specific Utils /====================================//

import { isValidAccountCredentials } from './utils'

export const GCP_PROJECT_DISPLAY_TYPE = 'Project'
export const GCP_FOLDER_DISPLAY_TYPE = 'Folder'

export const GCP_CLOUD_ACCOUNT_ORG_PERMISSIONS = [
  'Browser',
  'Cloud SQL Admin',
  'Cloud SQL Client',
  'Organisation Role Administrator',
  'Security Admin',
  'Viewer',
  'Service Account Key Admin',
  'Service Account Admin',
  'Service Account Token Creator',
  'Kubernetes Engine Admin',
  'Deployment Manager Editor',
  'Cloud AlloyDB Admin',
  'BigQuery Data Owner'
]

export const GCP_CLOUD_ACCOUNT_FOLDER_PERMISSIONS = [
  'Browser',
  'Cloud SQL Admin',
  'Cloud SQL Client',
  'Security Admin',
  'Viewer',
  'Service Account Key Admin',
  'Service Account Admin',
  'Service Account Token Creator',
  'Kubernetes Engine Admin',
  'Deployment Manager Editor',
  'Cloud AlloyDB Admin',
  'BigQuery Data Owner'
]

export const GCP_CLOUD_ACCOUNT_PROJECT_PERMISSIONS = [
  'Browser',
  'Cloud SQL Admin',
  'Cloud SQL Client',
  'Role Administrator',
  'Security Admin',
  'Viewer',
  'Service Account Key Admin',
  'Service Account Admin',
  'Service Account Token Creator',
  'Kubernetes Engine Admin',
  'Deployment Manager Editor',
  'Cloud AlloyDB Admin',
  'BigQuery Data Owner'
]

export const checkGCPAccountCredentials = async (jsonCredentials) => {
  const payload = {
    ObjectMeta: {
      Name: 'gcp_check'
    },
    Spec: {
      GcpSpec: {
        GcpJsonCreds: jsonCredentials,
        CloudIDType: 'intermediate'
      },
      Discovery: true,
      AccountType: 'GCP'
    }
  }
  return await isValidAccountCredentials(payload)
}

export const GCP_API_REQUIRED_PERMS = [
  'Compute Engine API',
  'Cloud Monitoring API',
  'Cloud Logging API',
  'Identity and Access Management (IAM) API',
  'Kubernetes Engine API',
  'Cloud DNS API',
  'Cloud SQL Admin API',
  'Cloud Resource Manager API',
  'AlloyDB API',
  'BigQuery API',
  'Cloud Pub/Sub API',
  'Cloud Autoscaling API',
  'Cloud Functions API',
  'Cloud Bigtable Admin API',
  'IAM Service Account Credentials API'
]
